import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators,FormArray  } from '@angular/forms';
import { ApiService } from '../api.service';
import { NgbPanelChangeEvent, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  @ViewChild('acc', {static : true}) accordion: NgbAccordion;
  // BASIc DETAILS
  productCode="1002-Marine Cargo-Inland";
  policyType="Container";


  // CUSTOMER DETAILS
  instituition:any={
    instituitionName:null,
    emailAddress:null,
    areaPostOffice:null,
    buildingAddress:null,
    customerName:null,
    mobileNumber:null,
    gstinUin:null,
    pan:null,
    cityState:null,
    pincode:null  
  }
  person:any={
    firstName:null,
    lastName:null,
    dob:null,
    buildingAddress:null,
    pan:null,
    mobileNumber:null,
    gstinUin:null,
    areaPostOffice:null,
    emailAddress:null,
    cityState:null,
    gender:null,
    pincode:null
  }

  // RISK DETAILS
  riskCategory;
  packingType;
  cargoDescription;

  // TRANSIT DETAILS
  typeOfTransit="Inland";
  modeOftransit;
  coverageOfWar="+SRCC";
  coverage;
  coverageStartDate;
  country="INDIA";
  transitFrom;
  transitTo;
  lrNumber;
  lrDate;
  transporterDetails;
  transporterName;
  containerMovement="2";

  // INVOICE DETAILS
  invoicePhoto;
  invoiceCurrency="INR";
  rateOfExchange;
  agent="Bajaj Allianz General Insurance Company";
  invoiceNumber;
  invoiceAmount:number=null;
  basisOfValuation="INVOICE";
  sumInsured:number=0;
  invoiceDate;
  freight:number=null;
  extra:number=0;
  duty:number=0;
  insuranceCharges:number=0;
  invoiceGst;

  currentDate = new Date().toISOString().slice(0,10);
  

  basicDetails: FormGroup;
  riskDetails: FormGroup;
  instituitionDetails: FormGroup;
  personDetails: FormGroup;
  transitDetails: FormGroup;
  invoiceDetails: FormGroup;
  

  userType=2;
  token;
  commodity;
  userId;
  transactionId;
  _id;
  invoiceUrl;

  basicCompleted=false;
  instiCompleted=false;
  personCompleted=false;
  customerCompleted=false;
  riskCompleted=false;
  transitCompleted=false;
  invoiceCompleted=false;

  userRes;
  transitRes;
  invoiceRes;
  uploadRes;

  userLoading=false;
  transitLoading=false;
  invoiceLoading=false;

  container=true;
  containerCustomerCompleted=false;
  containerRiskCompleted=false;
  containerTransitCompleted=false;
  containerDmvCompleted=false;
  containerCustomerDetails: FormGroup;
  containerRiskDetails: FormGroup;
  containerTransitDetails: FormGroup;
  containerDmvDetails: FormGroup;
  containersForm: FormGroup

  containerDetails={
    companyName:null,
    buildingAddress:null,
    gstinUin:null,
    emailAddress:null,
    contactPersonName:null,
    pan:null,
    mobileNumber:null
  }
  city;
  state;
  shippingLine;
  bookingNo=null;
  markUp;

  finalPremium;
  marineRate='8%';
  containerBov="DMV"
  excessAmount;
  excessPercentage;
 containerArray=[
    {
      size:null,
      value:null
  }
  ]

  containerSizeArray=[]
  areaArray=[]
  extratrue=false;
  constructor(public service:ApiService,
    public router: Router,
    public http:HttpClient) { }

  onExtraChange(e){
    if(e.target.value>10){
      this.extratrue=true;
    }else{
      this.extratrue=false;
    }
  }  
  onInvoice(){
    this.container = false;
    console.log(this.container);
    
  }
  onMovementChange(e){
    if(e.target.value==1){
      this.marineRate="0.04%"
      console.log(this.marineRate);
      
    }else{
      this.marineRate="0.08%"
      console.log(this.marineRate);

    }
  }
  onContainer(){
    this.container = true;
    console.log(this.container);
  }
  onPincodeChange(e){
    console.log(e.target.value);
    var pin=e.target.value
    if(pin.length==6){
      this.http.get("https://api.postalpincode.in/pincode/"+pin).subscribe(data=>{
        console.log(data);
        this.areaArray=[]
        this.city=data[0].PostOffice[0].District;
        this.state=data[0].PostOffice[0].State;
        for(let i=0;i<data[0].PostOffice.length;i++){
          this.areaArray.push(data[0].PostOffice[i].Name);
        }
        this.instituition.cityState=this.city+','+this.state;
        this.person.cityState=this.city+','+this.state;
        console.log(this.areaArray);
        
        console.log(this.person.cityState);
        
      })
    }
  }
  getContainerArr() {
	  return (this.containerRiskDetails.get('containerNo') as FormArray).controls;
	}
  get containerNo(): FormArray {
    return this.containerRiskDetails.get('containerNo') as FormArray;
  } 
  addNewRow(){
    if(( < FormArray > this.containerRiskDetails.get('containerNo')).length<=6){

      ( < FormArray > this.containerRiskDetails.get('containerNo')).push(new FormControl(null));
      ( < FormArray > this.containerRiskDetails.get('containerSize')).push(new FormControl(null, [Validators.required]));
      ( < FormArray > this.containerRiskDetails.get('containerValue')).push(new FormControl(null, [Validators.required]));
      this.containerArray.push({
        size:null,
        value:null
      });
    }
  }
  deleterow(i){
    if(( < FormArray > this.containerRiskDetails.get('containerNo')).length>1){
      ( < FormArray > this.containerRiskDetails.get('containerNo')).removeAt(i);
      ( < FormArray > this.containerRiskDetails.get('containerSize')).removeAt(i);
      ( < FormArray > this.containerRiskDetails.get('containerValue')).removeAt(i);
      this.containerArray.pop();
    }

  }

  ngOnInit(){
    console.log("date",this.currentDate);
    
    this.token=window.localStorage.getItem("token");
    if(this.token==('' || null || undefined)){
      alert("Login to continue");
      this.router.navigate(['login']);
    }

    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.getCommodity(headers).subscribe(data=>{
      this.commodity=data;
      console.log(this.commodity.data);
      
      

    },err=>{
      console.log(err);
      alert(err.error);
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })

    

    this.basicDetails= new FormGroup({
      policyType:new FormControl(this.policyType,Validators.required),
      productCode:new FormControl(null,Validators.required)
    })

    this.instituitionDetails= new FormGroup({
      instituitionName:new FormControl(null,Validators.required),
      emailAddress:new FormControl(null),
      areaPostOffice:new FormControl(null,Validators.required),
      buildingAddress:new FormControl(null,Validators.required),
      customerName:new FormControl(null,Validators.required),
      mobileNumber:new FormControl(null,Validators.required),
      gstinUin:new FormControl(null),
      pan:new FormControl(null),
      cityState:new FormControl(null,Validators.required),
      pincode:new FormControl(null,Validators.required)
    })

    this.personDetails= new FormGroup({
      firstName:new FormControl(null,Validators.required),
      lastName:new FormControl(null,Validators.required),
      gender:new FormControl(null,Validators.required),
      dob:new FormControl(null,Validators.required),
      emailAddress:new FormControl(null),
      mobileNumber:new FormControl(null,Validators.required),
      gstinUin:new FormControl(null),
      pan:new FormControl(null),
      cityState:new FormControl(null,Validators.required),
      buildingAddress:new FormControl(null,Validators.required),
      areaPostOffice:new FormControl(null,Validators.required),
      pincode:new FormControl(null,Validators.required)
    })

    this.containerCustomerDetails=new FormGroup({
      companyName:new FormControl(null,Validators.required),
      buildingAddress:new FormControl(null,Validators.required),
      gstinUin:new FormControl(null),
      emailAddress:new FormControl(null),
      contactPersonName:new FormControl(null,Validators.required),
      pan:new FormControl(null),
      mobileNumber:new FormControl(null,Validators.required),
    })

    this.containerRiskDetails=new FormGroup({
      riskCategory:new FormControl(null,Validators.required),
      containerNo:new FormArray([
        new FormControl(null)
      ]),
      containerSize:new FormArray([
        new FormControl('',Validators.required)
      ]),
      containerValue:new FormArray([
        new FormControl('',Validators.required)
      ])
    });

    this.containerTransitDetails=new FormGroup({
      typeOfTransit:new FormControl(null,Validators.required),
      modeOftransit:new FormControl('Road',Validators.required),
      coverage:new FormControl(null,Validators.required),
      coverageStartDate:new FormControl(null,Validators.required),
      country:new FormControl(null,Validators.required),
      transitFrom:new FormControl(null,Validators.required),
      transitTo:new FormControl(null,Validators.required),
      shippingLine:new FormControl(null,Validators.required),
      markUp:new FormControl(null,Validators.required),
      bookingNo:new FormControl(null),
      containerMovement:new FormControl(null,Validators.required)
    })

    this.containerDmvDetails=new FormGroup({
      basisOfValuation:new FormControl(null,Validators.required),
      marineRate:new FormControl(null,Validators.required),
      sumInsured:new FormControl(null,Validators.required),
    })


    this.riskDetails=new FormGroup({
      riskCategory:new FormControl(null,Validators.required),
      packingType:new FormControl("PCK01",Validators.required),
      cargoDescription:new FormControl(null,Validators.required),
    })

    this.transitDetails=new FormGroup({
      typeOfTransit:new FormControl(null,Validators.required),
      modeOftransit:new FormControl('Road',Validators.required),
      coverageOfWar:new FormControl(null,Validators.required),
      coverage:new FormControl(null,Validators.required),
      coverageStartDate:new FormControl(null,Validators.required),
      country:new FormControl(null,Validators.required),
      transitFrom:new FormControl(null,Validators.required),
      transitTo:new FormControl(null,Validators.required),
      lrNumber:new FormControl(null),
      lrDate:new FormControl(null),
      transporterName:new FormControl(null,Validators.required),
      transporterDetails:new FormControl(null,Validators.required),
      
    })

    this.invoiceDetails=new FormGroup({
      invoiceNumber:new FormControl(null,Validators.required),
      invoiceDate:new FormControl(null,Validators.required),
      invoiceCurrency:new FormControl(null,Validators.required),
      invoiceAmount:new FormControl(0,Validators.required),
      freight:new FormControl(0,Validators.required),
      rateOfExchange:new FormControl(0),
      basisOfValuation:new FormControl(null,Validators.required),
      agent:new FormControl(null),
      sumInsured:new FormControl(null),
      extra:new FormControl(0,Validators.required),
      duty:new FormControl(0),
      insuranceCharges:new FormControl(0),
      invoiceGst:new FormControl(null),
      excessAmount:new FormControl(null),
      excessPercentage:new FormControl(null),
    })
  }

  showStep(step,status,status2){
    console.log("clcikerrrrr    "+step);
    if(status=="VALID" || status2=="VALID"){
      this.accordion.toggle('static-'+step);
    }
    console.log(status);
    console.log(status2);

    
    // this.currentStep="static-"+step;
  }

  ifInstituition(){
    this.userType=2;
    console.log(this.userType);
    
  }
  ifPerson(){
    this.userType=1;
    console.log(this.userType);
  }
  onContainerRiskCategory(e){
    console.log(e.target.value);
    
    if(e.target.value=='COM10'){
      this.containerSizeArray=[
        '20 Feet',
        '40 Feet',
        '45 Feet',
      ]
    }else if(e.target.value=='COM24'){
      this.containerSizeArray=[
        '20 Feet',
      ]
    }else if(e.target.value=='COM11'){
      this.containerSizeArray=[
        '20 Feet',
        '40 Feet'
      ]
    }
    
  }

  onBasicSubmit(){
    console.log(this.basicDetails);
    
    if(this.basicDetails.status=="VALID"){
      this.basicCompleted=true;
      this.accordion.toggle('static-2');
    }else{
      alert("Enter all the details");
    }
  }

  onUserSubmit(){
    if(this.userType==2){
      

      console.log(this.instituitionDetails.status);
      console.log("entered user 2");
      if(this.instituitionDetails.status=="VALID"){
        this.userLoading=true;
        const formData={};
      formData["userType"] = this.userType;
      formData["name"] = this.instituitionDetails.controls.instituitionName.value;
      formData["representativeName"] = this.instituitionDetails.controls.customerName.value;
      formData["mobileNo"] = this.instituitionDetails.controls.mobileNumber.value;
      formData["areaPostOffice"] = this.instituitionDetails.controls.areaPostOffice.value;
      formData["pincode"] = this.instituitionDetails.controls.pincode.value;
      formData["state"] = this.instituitionDetails.controls.cityState.value;
      formData["address"] = this.instituitionDetails.controls.buildingAddress.value;
      if(this.instituition.gstinUin!=null){
        formData["gstinUin"] = this.instituitionDetails.controls.gstinUin.value;
      }
      if(this.instituition.pan!=null){
        formData["panNo"] = this.instituitionDetails.controls.pan.value;
      }
      if(this.instituition.emailAddress!=null){
        formData["email"] = this.instituitionDetails.controls.emailAddress.value;
      }
      console.log(this.token);
      
      console.log(formData);
      const headers = { 'Authorization':'Bearer '+this.token}
      this.service.sendUserDetails(formData,headers).subscribe(data=>{
        console.log(data);
        this.userRes=data;
        this._id=this.userRes.data._id;
        console.log(this._id);
        this.customerCompleted=true;
        this.accordion.toggle('static-3');
        this.userLoading=false;
      },err=>{
        alert(err.error);
        this.userLoading=false;
        if(err.status==(401 || 417)){
          this.router.navigate(['login']);
        }
      })
      }else{
        alert("Enter all the required details");
        console.log(this.instituitionDetails);
        
        console.log(this.instituitionDetails.errors);
        
      }
    }else{
      console.log("entered user 1");
      console.log(this.personDetails);
      
      
      if(this.personDetails.status=="VALID"){
        this.userLoading=true;
        console.log(this.personDetails.status);
        const formData={};
      formData["userType"] = this.userType;
      formData["areaPostOffice"] = this.personDetails.controls.areaPostOffice.value;
      formData["dob"] = this.personDetails.controls.dob.value;
      formData["mobileNo"] = this.personDetails.controls.mobileNumber.value;
      formData["pincode"] = this.personDetails.controls.pincode.value;
      formData["state"] = this.personDetails.controls.cityState.value;
      formData["gender"] = this.personDetails.controls.gender.value;
      formData["address"] = this.personDetails.controls.buildingAddress.value;
      formData["lastName"] = this.personDetails.controls.lastName.value;
      formData["name"] = this.personDetails.controls.firstName.value;
      if(this.person.gstinUin!=null){
        formData["gstinUin"] = this.personDetails.controls.gstinUin.value;
      }
      if(this.person.pan!=null){
        formData["panNo"] = this.personDetails.controls.pan.value;
      }
      if(this.person.emailAddress!=null){
        formData["email"] = this.personDetails.controls.emailAddress.value;
      }
      console.log(this.token);
      
      console.log(formData);
      const headers = { 'Authorization':'Bearer '+this.token}
      this.service.sendUserDetails(formData,headers).subscribe(data=>{
        console.log(data);
        this.userRes=data;
        this._id=this.userRes.data._id;
        console.log(this._id);
        this.customerCompleted=true;
        this.accordion.toggle('static-3');
        this.userLoading=false;
      },err=>{
        alert(err.error);
        this.userLoading=false;
        if(err.status==(401 || 417)){
          this.router.navigate(['login']);
        }
      })
      }else{
        alert("Enter all the required details")
      }
    }
  }
  onContainerCustomerSubmit(){
        if(this.containerCustomerDetails.status=="VALID"){
          this.userLoading=true;
          const formData={};
        formData["userType"] = 2;
        formData["name"] = this.containerCustomerDetails.controls.companyName.value;
        formData["mobileNo"] = this.containerCustomerDetails.controls.mobileNumber.value;
        formData["address"] = this.containerCustomerDetails.controls.buildingAddress.value;
        formData["representativeName"] = this.containerCustomerDetails.controls.contactPersonName.value;
        if(this.containerDetails.emailAddress!=null){
          formData["email"] = this.containerCustomerDetails.controls.emailAddress.value;
        }
        if(this.containerDetails.gstinUin!=null){
          formData["gstinUin"] = this.containerCustomerDetails.controls.gstinUin.value;
        }

        // formData["areaPostOffice"] = this.instituitionDetails.controls.areaPostOffice.value;
        // formData["pincode"] = this.instituitionDetails.controls.pincode.value;
        // formData["state"] = this.instituitionDetails.controls.cityState.value;
        console.log(this.token);
        
        console.log(formData);
        const headers = { 'Authorization':'Bearer '+this.token}
        this.service.sendUserDetails(formData,headers).subscribe(data=>{
          console.log(data);
          this.userRes=data;
          this._id=this.userRes.data._id;
          console.log(this._id);
          this.containerCustomerCompleted=true;
          this.accordion.toggle('static-3');
          this.userLoading=false;
        },err=>{
          alert(err.error);
          this.userLoading=false;
          if(err.status==(401 || 417)){
            this.router.navigate(['login']);
          }
        })
        }else{
          alert("Enter all the required details")
          console.log(this.instituitionDetails);
          
          console.log(this.instituitionDetails.errors);
          
        }
  }

  onRiskSubmit(){
    console.log(this.riskDetails);
    
    if(this.riskDetails.status=="VALID"){
      this.riskCompleted=true;
      this.accordion.toggle('static-4');
    }else{
      alert("Enter all the details");
    }
  }

  onContainerRiskSubmit(){
    var cnosame=false;
    if(this.containerRiskDetails.status=="VALID"){
      for(let i=0;i<( < FormArray > this.containerRiskDetails.get('containerNo')).length;i++ ){
        console.log(( < FormArray > this.containerRiskDetails.get('containerNo')).value[i]);
        
        if(( < FormArray > this.containerRiskDetails.get('containerNo')).value[i]!="" && ( < FormArray > this.containerRiskDetails.get('containerNo')).value[i]!=null){
          console.log(( < FormArray > this.containerRiskDetails.get('containerNo')).value[i]);
          
          this.containerArray[i]["number"]=( < FormArray > this.containerRiskDetails.get('containerNo')).value[i]
        }
        this.containerArray[i].size=( < FormArray > this.containerRiskDetails.get('containerSize')).value[i]
        this.containerArray[i].value=( < FormArray > this.containerRiskDetails.get('containerValue')).value[i]

        this.sumInsured+=parseInt(( < FormArray > this.containerRiskDetails.get('containerValue')).value[i]);
        
      }
      console.log(this.containerArray);
      var abort=false;
      for(let i=0;i<this.containerArray.length && abort==false;i++){
        for(let k=0;k<this.containerArray.length;k++){
            if(this.containerArray[i]["number"]==this.containerArray[k]["number"] && i!=k && this.containerArray[i]["number"]!=(null || "" || undefined) && this.containerArray[k]["number"]!=(null || "" || undefined)){
              console.log(this.containerArray[i]["number"],"i");
              console.log(this.containerArray[k]["number"],"k");
              
              alert("Container No cannot be same.");
              abort=true;
              cnosame=true;
              break;
            }
        }
      }

      console.log(this.sumInsured);
      
      if(cnosame==false){
        this.containerRiskCompleted=true;
        this.accordion.toggle('static-4');
      }
    }else{
      alert("Enter all the required details")
    }
  }

  onTransitSubmit(){
    if(this.transitDetails.status=="VALID"){
    this.transitLoading=true;  
    const formData={
      transportationDetails:{}
    };
    formData["userId"] = this._id;
    formData["productCode"] = "1002-Marine Cargo-Inland";
    formData["policyType"] = this.basicDetails.controls.policyType.value;
    formData["riskCategory"] = this.riskDetails.controls.riskCategory.value;
    formData["packing"] = this.riskDetails.controls.packingType.value;
    formData["cargoDescription"] = this.riskDetails.controls.cargoDescription.value;
    formData["typeOfTransit"] = "INLAND";
    formData["modeOfTransit"] = "ROAD";
    formData["coverageOfWarorStrikes"] = this.transitDetails.controls.coverageOfWar.value;
    formData["coverage"] = this.transitDetails.controls.coverage.value;
    formData["coverageStartDate"] = this.transitDetails.controls.coverageStartDate.value;
    formData["fromCountry"] = "India";
    formData["toCountry"] = "India";
    formData["coverageFrom"] = this.transitDetails.controls.transitFrom.value;
    formData["coverageEnds"] = this.transitDetails.controls.transitTo.value;
    formData["bov"] = "INVOICE";
    if(this.lrNumber!=null){
      formData.transportationDetails["lrNo"] = this.transitDetails.controls.lrNumber.value;
    }
    if(this.lrDate!=null){
      formData.transportationDetails["lrDate"] = this.transitDetails.controls.lrDate.value;
    }
    formData.transportationDetails["transporterDetails"] = this.transitDetails.controls.transporterDetails.value;
    formData.transportationDetails["transporterName"] = this.transitDetails.controls.transporterName.value;
    console.log(this.token);
    
    console.log(formData);
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.sendTransitDetails(formData,headers).subscribe(data=>{
      this.transitRes=data;
      console.log(data);
      this.transactionId=this.transitRes.data.transactionId;
      this.policyType=this.transitRes.data.policyType;
      this.transitCompleted=true;
      this.accordion.toggle('static-5');
      this.transitLoading=false;
      this.excessAmount=this.transitRes.commDetails.excessAmt;
      this.excessPercentage=this.transitRes.commDetails.excessPerc;
    },err=>{
      alert(err.error.message);
      this.transitLoading=false;
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
  }else{
    console.log(this.transitDetails);
    
  }
  }
  
  onContainerTransitSubmit(){
    if(this.containerTransitDetails.status=="VALID"){

    this.transitLoading=true;  
    const formData={
    };
    formData["productCode"] = "1002-Marine Cargo-Inland";
    formData["bov"] = "DMV";
    formData["packing"] ='PCK01'
    formData["policyType"] = this.basicDetails.controls.policyType.value;
    formData["riskCategory"] = this.containerRiskDetails.controls.riskCategory.value;
    formData["typeOfTransit"] = "INLAND";
    formData["modeOfTransit"] = "ROAD";
    formData["coverage"] = this.containerTransitDetails.controls.coverage.value;
    formData["coverageFrom"] = this.containerTransitDetails.controls.transitFrom.value;
    formData["coverageEnds"] = this.containerTransitDetails.controls.transitTo.value;
    formData["container"] = this.containerArray;
    formData["coverageStartDate"] = this.containerTransitDetails.controls.coverageStartDate.value;
    formData["shippingLine"] = this.containerTransitDetails.controls.shippingLine.value;
    formData["transportationType"] = this.containerTransitDetails.controls.containerMovement.value;
    if(this.bookingNo!=null){
      formData["bookingNo"] = this.containerTransitDetails.controls.bookingNo.value;
    }
    formData["markup"] = this.containerTransitDetails.controls.markUp.value;
    formData["userId"] = this._id;
    // formData["packing"] = this.riskDetails.controls.packingType.value;
    // formData["cargoDescription"] = this.riskDetails.controls.cargoDescription.value;
    // formData["coverageOfWarorStrikes"] = this.transitDetails.controls.coverageOfWar.value;
    // formData["coverageEndDate"] = this.transitDetails.controls.quoteValidityDate.value;
    // formData["fromCountry"] = "India";
    // formData["toCountry"] = "India";
    // formData.transportationDetails["lrNo"] = this.transitDetails.controls.lrNumber.value;
    // formData.transportationDetails["lrDate"] = this.transitDetails.controls.lrDate.value;
    // formData.transportationDetails["transporterDetails"] = this.transitDetails.controls.transporterDetails.value;
    // formData.transportationDetails["transporterName"] = this.transitDetails.controls.transporterName.value;
    console.log(this.token);
    
    console.log(formData);
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.sendTransitDetails(formData,headers).subscribe(data=>{
      this.transitRes=data;
      this.transactionId=this.transitRes.data.transactionId;
      this.policyType=this.transitRes.data.policyType;
      this.containerTransitCompleted=true;
      this.accordion.toggle('static-5');
      this.transitLoading=false;
      console.log(data);
    },err=>{
      alert(err.error);
      this.transitLoading=false;
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
  }
  }

  onInvoiceSubmit(){
    console.log(this.invoiceDetails.status);
    console.log(this.invoiceDetails);
    if(this.invoiceDetails.status=="VALID"){
      this.invoiceLoading=true;
      const formData={};
    formData["transactionId"] = this.transactionId;
    formData["amount"] = this.invoiceDetails.controls.invoiceAmount.value;
    formData["currency"] = "INR";
    formData["rateOfExchange"] = this.invoiceDetails.controls.rateOfExchange.value;
    formData["invoiceNo"] = this.invoiceDetails.controls.invoiceNumber.value;
    formData["extraPerc"] = this.invoiceDetails.controls.extra.value;
    formData["date"] = this.invoiceDetails.controls.invoiceDate.value;
    formData["freight"] = this.invoiceDetails.controls.freight.value;
    formData["duty"] = this.invoiceDetails.controls.duty.value;
    formData["insuranceCharges"] = this.invoiceDetails.controls.insuranceCharges.value;
    formData["invoiceUpload"] = this.invoiceUrl;
    formData["invoiceGst"]=this.invoiceGst;



    console.log(this.token);
    
    console.log(formData);
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.sendInvoiceDetails(formData,headers).subscribe(data=>{
      console.log(data);
      this.router.navigate(["/preview",this.policyType,this.transactionId]);
      this.invoiceLoading=false;
    },err=>{
      alert(err.error);
      this.invoiceLoading=false;
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
    }else{
      if(this.invoiceUrl==undefined){
        alert("Upload invoice copy")
      }else{
        alert("Enter all the required details")
      }
    }
  }
  onDmvSubmit(){
    if(this.containerDmvDetails.status=="VALID"){
      console.log(this.policyType,this.transactionId);
      
      this.router.navigate(["/preview",this.policyType,this.transactionId]);
    }else{
      alert("Enter all the required details")
    }
  }
  onInvoiceUpload(event){
    const formData=new FormData;
    formData.append('invoice',event.target.files[0]);
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.uploadInvoice(formData,headers).subscribe(data=>{
      console.log(data);
      this.uploadRes=data;
      this.invoiceUrl=this.uploadRes.data.Location;
      console.log(this.uploadRes.Location);
      
      console.log(this.invoiceUrl);
      
    },err=>{
      alert(err.error);
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
    
  }

  calcSumInsured(){
    console.log(this.invoiceAmount);
    
    if(this.invoiceAmount!=null){
      this.sumInsured=this.invoiceAmount+(this.invoiceAmount*this.extra/100)+this.freight+this.insuranceCharges+this.duty;
    }
    console.log("invoice",this.invoiceAmount);
    console.log("extra",this.extra);
    console.log("freight",this.freight);
    console.log("inchar",this.insuranceCharges);
    console.log("duty",this.duty);
    
    console.log(this.sumInsured);
    
  }
  onlogout(){
    console.log(this.token);
    
    if(this.token){
      const headers = { 'Authorization':'Bearer '+ this.token}
      
      this.service.logout(headers).subscribe(data=>{
        console.log(data);
        window.localStorage.removeItem("token");
        
        this.router.navigate(['login'])
        
      },err=>{
        alert(err.error.error);
      })

    }else{
      alert("Something went wrong...")
    }
    
  }
  
}
