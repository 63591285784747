import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-show-policies',
  templateUrl: './show-policies.component.html',
  styleUrls: ['./show-policies.component.css']
})
export class ShowPoliciesComponent implements OnInit {
  token;
  res;
  policyNo='OG-22-1501-1005-00000016';
  name;
  commodity;
  from;
  to;
  sumInsured;
  id;
  policyType;
  ReverseArray;

  constructor(public router:Router,
    public service:ApiService,
    public route:ActivatedRoute,
    private location: LocationStrategy) {
      history.pushState(null, null, window.location.href);  
      this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      });  
     }

  ngOnInit(): void {
    this.token=window.localStorage.getItem('token');

    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.getPolicies(headers).subscribe(data=>{
      this.res=data;
      this.ReverseArray = [];
    var length = this.res.length;
    console.log(length,"length");
    
    for(let i = this.res.data.length-1; i>=0 ; i--){
      console.log("forran");
      
        this.ReverseArray.push(this.res.data[i]);
    }
    console.log(this.ReverseArray);
    
      // this.id=this.res.data._id;
      // this.policyType=this.res.data.transit.policyType
      console.log(this.res.data);
      
    },err=>{
      console.log(err);
      alert(err.error);
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
  }
  onPolicyClick(id){
    this.router.navigate(['policy-generated',id]);
  }

  onlogout(){
    if(this.token){
      const headers = { 'Authorization':'Bearer '+ this.token}
      
      this.service.logout(headers).subscribe(data=>{
        console.log(data);
        window.localStorage.removeItem("token");
        
        this.router.navigate(['login'])
        
      },err=>{
        alert(err.error.error);
      })

    }else{
      alert("Something went wrong...")
    }
    
  }

}
