import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url=environment.apiUrl
  constructor(public http: HttpClient) { }

  onLogin(data){
    return this.http.post(this.url+"admin/login",data);
  }

  sendUserDetails(data,headers){
    return this.http.post(this.url+"user",data,{ headers });
  }

  sendTransitDetails(data,headers){
    return this.http.post(this.url+"transit",data,{ headers });
  }

  sendInvoiceDetails(data,headers){
    return this.http.post(this.url+"invoice",data,{ headers });
  }
  
  getCommodity(headers){
    return this.http.get(this.url+"commodity",{ headers });
  }

  uploadInvoice(data, headers){
    return this.http.post(this.url+"invoice/upload",data,{ headers })
  }

  getUserDetails(txn,headers){
    return this.http.get(this.url+'transit?transactionId='+txn,{ headers })
  }

  getCommidityClause(txn,headers){
    return this.http.get(this.url+'clause?transactionId='+txn,{ headers })
  }

  getPdfDetails(txn,headers){
    return this.http.get(this.url+'policyGeneration/calculate?type=1&transactionId='+txn, { headers })
  }
  getContainerPdfDetails(txn,headers){
    return this.http.get(this.url+'policyGeneration/calculate?type=2&transactionId='+txn, { headers })
  }

  postPolicy(data,headers){
    return this.http.post(this.url+'policyGeneration/upload',data,{ headers })
  }
  
  getPolicies(headers){
    return this.http.get(this.url+'policyGeneration',{ headers})
  }
  
  logout(headers){
    return this.http.post(this.url+'user/logout',{},{ headers })
  }

}
