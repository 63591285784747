<div style="background: linear-gradient(90deg, #277ADF 0%, #0C47C5 129.03%, #0C47C5 129.03%);height:40vh;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
    <div style="text-align: right;padding-top: 10px;padding-right: 10px;">
        <button (click)="onlogout()" style="color: white;" class="btn">Logout <img  src="assets/icon/logout.svg" /></button>
    </div>
    <br><br><br><h1 style="color: white;text-align: center;">POLICY ISSUANCE PORTAL</h1>
    <div class="container">
    <div class="row" style="width: fit-content;margin-left: auto;">
        <div class="col">
            <button routerLink="/form" style="width: 150px;border-color: white;background-color: white;color: #277ADF;" class='btn'>Issue New Policy</button>
        </div>
        <div class="col">
            <a href="https://www.bajajallianz.com/commercial-insurance/commercial-insurance-claim-process.html?utm_source=GoogleBrandBagic&param1=BA_General_Insurance_Mobile_RLSA&param2=Bajaj_Allianz_General_Insurance_+_Exact&param3=bajaj%20allianz%20general%20insurance&utm_content=SEM&gclid=Cj0KCQiAyoeCBhCTARIsAOfpKxg3iuzP4lui4g4gkXMv7Mz8d8EK1Ju2fDZ7LDXuCWlPG9EJhXDPfbYaAjl3EALw_wcB&gclsrc=aw.ds" target="blank"><button style="border-color: white;background-color: white;color: #277ADF;" class='btn'>Register a claim</button></a>
        </div>
    </div>
    </div>
</div>
<div style="position: relative;bottom: 30px;background-color: rgb(236, 236, 236);border-radius: 10px;padding-left: 30px;padding-right: 30px;padding-top: 25px;padding-bottom: 15px;" class="container">
    <h5><b>POLICIES ISSUED</b></h5>
    <div class="card" style="padding: 10px;margin-bottom: 10px;cursor: pointer;" *ngFor="let policy of ReverseArray">
        <div (click)="onPolicyClick(policy._id)" class="row">
            <div class="col">
                <label><b>Policy No</b></label><br>
                <p>{{ policyNo }}</p>
            </div>
            <div class="col">
                <label><b>Name</b></label><br>
                <p>{{ policy.user.name }}</p>
            </div>
            <div class="col">
                <label><b>Commodity</b></label><br>
                <p>{{ policy.transit.riskCategory }}</p>
            </div>
            <div class="col">
                <label><b>From</b></label><br>
                <p>{{ policy.transit.coverageFrom }}</p>
            </div>
            <div class="col">
                <label><b>To</b></label><br>
                <p>{{ policy.transit.coverageEnds }}</p>
            </div>
            <div class="col">
                <label><b>Sum Insured</b></label><br>
                <p>{{ policy.sumInsured }}</p>
            </div>
            <div class="col">
                <label><b>Certificate Number</b></label><br>
                <p>{{ policy.certificateNumber }}</p>
            </div>
        </div>
    </div>
</div>




