import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginDetails: FormGroup;
  token;
  res;
  username;
  password;
  loginLoading=false;
  constructor(public http: HttpClient,
    private route: ActivatedRoute,
    public service: ApiService,
    public router: Router) { 

  }
  // data={
  //   "loginType":1,
  //   "username":"administrator_001",
  //   "password":"password"
  // }
  

  ngOnInit(): void {
    this.token=window.localStorage.getItem('token')
    console.log(this.token);
    
    this.loginDetails=new FormGroup({
      userName: new FormControl(null,Validators.required),
      password: new FormControl(null,Validators.required),
    })
  }

  onLogin(){
    if(this.loginDetails.status=='VALID'){
      this.loginLoading=true;
      const data={
        "loginType":1,
        "username":this.username,
        "password":this.password
      }
      this.service.onLogin(data).subscribe(data=>{
        this.res=data
        this.token=this.res.result[0].token
        window.localStorage.setItem("token", this.token);
        console.log(this.res.result[0].token);
        this.router.navigate(['/show-policies']);
        this.loginLoading=false;
        
      },err=>{
        alert(err.error.message);
        this.loginLoading=false;
      })

    }else{
      alert("Enter both username and password");
    }
  }


}
