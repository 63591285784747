import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { EditComponent } from './edit/edit.component';
import { FormComponent } from './form/form.component';
import { LoginComponent } from './login/login.component';
import { PolicyGeneratedComponent } from './policy-generated/policy-generated.component';
import { PreviewComponent } from './preview/preview.component';
import { ShowPoliciesComponent } from './show-policies/show-policies.component';

const routes: Routes = [
  {path:"", redirectTo:"login",pathMatch:"full"},
  {path:"login", component:LoginComponent},
  {path:"form", component:FormComponent},
  {path:"preview/:type/:id", component:PreviewComponent},
  {path:"policy-generated/:txnid", component:PolicyGeneratedComponent},
  {path:"edit/:txnid/:userid", component:EditComponent},
  {path:"show-policies", component:ShowPoliciesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
