<div style="background: linear-gradient(90deg, #277ADF 0%, #0C47C5 129.03%, #0C47C5 129.03%);height:30vh;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
    <div style="text-align: right;padding-top: 10px;padding-right: 10px;">
        <button (click)="onlogout()" style="color: white;" class="btn">Logout <img  src="assets/icon/logout.svg" /></button>
    </div>
    <br><br><br><h1 style="color: white;text-align: center;">POLICY ISSUANCE PORTAL</h1>
</div>
<div *ngIf="policyType=='Inland'" style="position: relative;bottom: 35px;background-color: rgb(250, 250, 250);border-radius: 1rem;padding-left: 30px;padding-right: 30px;" class="container">
    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">Basic Details</h5>
    <div class="row mt-3">
        <div class="col">
            <label for="">Product Code</label>
            <p><b>{{ productCode }}</b></p>
        </div>
        <div class="col">
            <label for="">Policy Type</label>
            <p><b>{{ policyType }}</b></p>
        </div>
    </div>

    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">Customer Details</h5>
    <div class="row mt-3">
        <div class="col">
            <div *ngIf="userType==2">
                <label for="">Institution Name</label>
                <p><b>{{ instituitionName }}</b></p>
                
                <label for="">Customer Name</label>
                <p><b>{{ customerName }}</b></p>
            </div>
            <div *ngIf="userType==1">
                <label for="">First Name</label>
                <p><b>{{ firstName }}</b></p>

                <label for="">Last Name</label>
                <p><b>{{ lastName }}</b></p>

                <label for="">Date of Birth</label>
                <p><b>{{ dob }}</b></p>

                <label for="">Gender</label>
                <p><b>{{ gender }}</b></p>
            </div>
            
            <label for="">Area Post Office</label>
            <p><b>{{ areaPostOffice }}</b></p>

            <label for="">City and State</label>
            <p><b>{{ cityState }}</b></p>
        </div>
        
        <div class="col">
            <label for="">Email address</label>
            <p><b>{{ emailAddress }}</b></p>

            <label for="">Mobile Number</label>
            <p><b>{{ mobileNumber }}</b></p>

        </div>

        <div class="col">
            <label for="">GSTIN/UIN</label>
            <p><b>{{ gstinUin }}</b></p>

            <label for="">PAN</label>
            <p><b>{{ pan }}</b></p>
        </div>
    </div>

    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(187, 187, 187);padding-top: 20px;padding-bottom: 10px;">Risk Details</h5>
    <div class="row mt-3">
        <div class="col">
            <label for="">Risk Category</label>
            <p><b>{{ riskCategory }}</b></p>
        </div>
        <div class="col">
            <label for="">Packing Type</label>
            <p><b>{{ packingType }}</b></p>
        </div>
        <div class="col">
            <label for="">Cargo Description</label>
            <p><b>{{ cargoDescription }}</b></p>
        </div>
    </div>

    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">Transit Details</h5>
    <div class="row">
        <div class="col">
            <label for="">Type of Transit</label>
            <p><b>{{ typeOfTransit }}</b></p>
            <label for="">Coverage</label>
            <p><b>{{ coverage }}</b></p>
            <label for="">Country</label>
            <p><b>{{ country }}</b></p>
            <div *ngIf="lrNumber!=null && lrNumber!=undefined && lrNumber!='N/A'">
                <label for="">LR Number</label>
                <p><b>{{ lrNumber }}</b></p>
            </div>
        </div>

        <div class="col">
            <label for="">Mode of Transit</label>
            <p><b>{{ modeOftransit }}</b></p>
            <label for="">Coverage Start Date</label>
            <p><b>{{ coverageStartDate }}</b></p>
            <label for="">Transit From</label>
            <p><b>{{ transitFrom }}</b></p>
            <div *ngIf="lrDate!=null && lrDate!=undefined && lrDate!='N/A'">
                <label for="">LR Date</label>
                <p><b>{{ lrDate }}</b></p>
            </div>
        </div>

        <div class="col">
            <label for="">Coverage of war or strikes risk</label>
            <p><b>{{ coverageOfWar }}</b></p>
            <label for="">Transit To</label>
            <p><b>{{ transitTo }}</b></p>
            <label for="">Transporter name</label>
            <p><b>{{ transporterName }}</b></p>
        </div>
    </div>

    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">Invoice Details</h5>
    <div class="row">
        <div class="col">
            <label for="">Invoice Photo</label><br>
            <img style="height: 70;width: 50px;" [src]="invoiceUrl" /><br>

            <label for="">Invoice Currency</label>
            <p><b>{{ invoiceCurrency }}</b></p>

            <label for="">Extra Percentage</label>
            <p><b>{{ extra }}</b></p>

            <label for="">Agent</label>
            <p><b>{{ agent }}</b></p>
        </div>

        <div class="col">
            <label for="">Invoice Number</label>
            <p><b>{{ invoiceNumber }}</b></p>

            <label for="">Invoice Amount</label>
            <p><b>{{ invoiceAmount }}</b></p>

            <label for="">Basis of Valuation</label>
            <p><b>{{ basisOfValuation }}</b></p>

            <label for="">Duty</label>
            <p><b>{{ duty }}</b></p>

            
        </div>

        <div class="col">
            <label for="">Invoice Date</label>
            <p><b>{{ invoiceDate }}</b></p>

            <label for="">Freight</label>
            <p><b>{{ freight }}</b></p>

            <!-- <label for="">GST Invoice No.</label>
            <p><b>{{ invoiceGst }}</b></p> -->

            <label for="">Insurance Charges</label>
            <p><b>{{ insuranceCharges }}</b></p>

            <label for="">Sum Insured</label>
            <p><b>{{ sumInsured }}</b></p>

        </div>
    </div>
    <div class="row" style="width: 27%;margin: auto;margin-top: 30px;padding-bottom: 30px;">
        <div class="col">
            <button (click)="onEdit()" type="button" style="color: #1B63D4;border: 2px solid #1B63D4;" class="btn btn-outline-primary">Edit <img style="width: 38%;" src="assets/icon/ticksquare.svg" /></button>
        </div>
        <div class="col">
            <button (click)="onIssuePolicy()" type="button"  style="background-color: #1B63D4;border: 2px solid #1B63D4;" class="btn btn-primary">Issue Policy</button>
        </div>
    </div>

    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">Cover Clauses</h5>
    <div class="row">
        <div style="color: black;" class="col">
            <div class="row">
                <div class="col-1">
                    <b>No</b>
                </div>
                <div class="col-2">
                    <b>Clause Category</b>
                </div>
                <div class="col">
                    <b>Clause Description</b>
                </div>
            </div>
            <div *ngFor="let clause of coverClause; let i =index" class="row">
                <div class="col-1">
                    {{ i+1 }}
                </div>
                <div class="col-2">
                    {{ clause?.clauseType }}
                </div>
                <div class="col">
                    {{ clause?.clauseDesc }}
                </div>
            </div>
        </div>
    </div>
    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">Other Clauses</h5>
    <div class="row">
        <div style="color: black;" class="col">
            <div class="row">
                <div style="color: black;" class="col">
                    <div class="row">
                        <div class="col-1">
                            <b>No</b>
                        </div>
                        <div class="col-2">
                            <b>Clause Category</b>
                        </div>
                        <div class="col">
                            <b>Clause Description</b>
                        </div>
                    </div>
                    <div *ngFor="let clause of otherClause; let i =index" class="row">
                        <div class="col-1">
                            {{ i+1 }}
                        </div>
                        <div class="col-2">
                            {{ clause?.clauseType }}
                        </div>
                        <div class="col">
                            {{ clause?.clauseDesc }}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<div *ngIf="policyType=='Container'" style="position: relative;bottom: 35px;background-color: rgb(250, 250, 250);border-radius: 1rem;padding-left: 30px;padding-right: 30px;" class="container">
    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">Basic Details</h5>
    <div class="row mt-3">
        <div class="col">
            <label for="">Product Code</label>
            <p><b>{{ productCode }}</b></p>
        </div>
        <div class="col">
            <label for="">Policy Type</label>
            <p><b>{{ policyType }}</b></p>
        </div>
    </div>

    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">Customer Details</h5>
    <div class="row mt-3">
        <div class="col">
            <div *ngIf="userType==2">
                <label for="">Company Name</label>
                <p><b>{{ instituitionName }}</b></p>
                
                <label for="">Customer Name</label>
                <p><b>{{ customerName }}</b></p>

                <label for="">Building Address</label>
                <p><b>{{ buildingAddress }}</b></p>
            </div>
        </div>
        
        <div class="col">
            <label for="">Email address</label>
            <p><b>{{ emailAddress }}</b></p>

            <label for="">Mobile Number</label>
            <p><b>{{ mobileNumber }}</b></p>

        </div>

        <div class="col">
            <label for="">GSTIN/UIN</label>
            <p><b>{{ gstinUin }}</b></p>

        </div>
    </div>

    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(187, 187, 187);padding-top: 20px;padding-bottom: 10px;">Risk Details</h5>
    <div class="row mt-3">
        <div class="col">
            <label for="">Risk Category</label>
            <p><b>{{ riskCategory }}</b></p>
        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
    </div>
   

        <div class="table-responsive">
            <table style="border: none;" class="table">
                <thead class="thead" style="background: #1F6BDB;color: white;border: none;">
                    <tr style="border: none;">
                <th style="border: none;"  scope="col">Sr.No</th>
                <th style="border: none;" scope="col">Container No</th>
                <th style="border: none;" scope="col">Container Size</th>
                <th style="border: none;" scope="col">Value of Container(in INR)</th>
            </tr>
        </thead>
        <tbody style="border: none;">
            <tr *ngFor="let value of containers; let i=index" style="border: none;">
                <th style="border: none;" scope="row">{{ i+1 }}</th>
                <td style="border: none;">
                    {{ value.number?value.number:"N/A"  }}
                </td>
                <td style="border: none;">
                    {{ value.size }}
                </td>
                <td style="border: none;">
                    {{ value.value }}
                </td>
            </tr>
        </tbody>
        </table>
        </div>
        


    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">Transit Details</h5>
    <div class="row">
        <div class="col">
            <label for="">Type of Transit</label>
            <p><b>{{ typeOfTransit }}</b></p>
            <label for="">Coverage</label>
            <p><b>{{ coverage }}</b></p>
            <label for="">Markup</label>
            <p><b>{{ markUp }}</b></p>
            <label for="">Container Movement</label>
            <p><b>{{ containerMovement }}</b></p>
        </div>

        <div class="col">
            <label for="">Mode of Transit</label>
            <p><b>{{ modeOftransit }}</b></p>
            <label for="">Coverage Start Date</label>
            <p><b>{{ coverageStartDate }}</b></p>
            <label for="">Booking no</label>
            <p><b>{{ bookingNo }}</b></p>
            
        </div>

        <div class="col">
            <label for="">Transit From</label>
            <p><b>{{ transitFrom }}</b></p>

            <label for="">Transit To</label>
            <p><b>{{ transitTo }}</b></p>
            <label for="">Shipping Line</label>
            <p><b>{{ shippingLine }}</b></p>
        </div>
    </div>

    <h5 style="color: #1A62D3;border-bottom: 1px solid rgb(204, 204, 204);padding-top: 20px;padding-bottom: 10px;">DMV Details</h5>
    <div class="row">
        <div class="col">
            <label for="">Sum Insured</label>
            <p><b>{{ sumInsured }}</b></p>
        </div>
        <div class="col">
            <label for="">Marine Rate</label>
            <p><b>{{ premiumRate }}</b></p>
        </div>
    </div>
    <div class="row" style="margin: auto;margin-top: 30px;padding-bottom: 30px;">
        <div class="col" style="text-align: right;">
            <button (click)="onEdit()" type="button" style="color: #1B63D4;border: 2px solid #1B63D4;" class="btn btn-outline-primary">Edit <img style="width: 38%;" src="assets/icon/ticksquare.svg" /></button>
        </div>
        <div class="col">
            <button (click)="onIssuePolicy()" type="button"  style="background-color: #1B63D4;border: 2px solid #1B63D4;" class="btn btn-primary">Issue Policy</button>
        </div>
    </div>

    <h5 style="color: #1A62D3;;padding-top: 20px;padding-bottom: 10px;">Cover Clauses</h5>

    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>No</th>
                    <td><b>Clause Category</b></td>
                    <td><b>Clause Description</b></td>
                </tr>
            </thead>
            <tbody *ngIf="riskCategory=='COM24' || riskCategory=='COM11'">
                <tr>
                    <td>1</td>
                    <td>Cover Clause</td>
                    <td>Important Notice Clause</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Cover Clause</td>
                    <td>Inland Transit (Rail or Road) Clause-B (Basic Cover) 2010</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Cover Clause</td>
                    <td>Institute Cyber Attacks Exclusion Clause 10.11.2003 Clause No. ILU CL380</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Cover Clause</td>
                    <td>Institute Radioactive Contamination, Chemical, Biological, Bio- chemical and Electromagnetic Weapons Exclusion Clause 10.11.2003 Clause No. ILU CL370</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Cover Clause</td>
                    <td>Private Carriers Warranty/ Limitation of Liability Clause</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Cover Clause</td>
                    <td>Sanction Limitation and Exclusion Clause 11.08.2010 Clause No. JC2010/014</td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>Cover Clause</td>
                    <td>Strikes Riots and Civil Commotions Clause (Inland Transit not in conjuction with Ocean Going Voyage)</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>Cover Clause</td>
                    <td>Termination of Transit Clause (Terrorism) 23.05.2012 Clause No. JC2001/056</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>Standard Conditions and Warranties</td>
                    <td>Exclusion for direct and indirect loss as a result of infectious diseases or contagious disease. Including but not limited to diseases arising out of coronaviruses</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>Standard Conditions and Warranties</td>
                    <td>Institute Replacement Clause-Proportional Valuation 01.12.2008 Clause No. ILU CL373- Applicable for Secondhand and/or Used Goods Only</td>
                </tr>
            </tbody>
            <tbody *ngIf="riskCategory=='COM10'">
                <tr>
                    <td>1</td>
                    <td>Cover Clause</td>
                    <td>Important Notice Clause</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Cover Clause</td>
                    <td>Inland Transit (Rail or Road) Clause-B (Basic Cover) 2010</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Cover Clause</td>
                    <td>Institute Cyber Attacks Exclusion Clause 10.11.2003 Clause No. ILU CL380</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Cover Clause</td>
                    <td>Institute Radioactive Contamination, Chemical, Biological, Bio- chemical and Electromagnetic Weapons Exclusion Clause 10.11.2003 Clause No. ILU CL370</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Cover Clause</td>
                    <td>Private Carriers Warranty/ Limitation of Liability Clause.</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Cover Clause</td>
                    <td>Sanction Limitation and Exclusion Clause 11.08.2010 Clause No. JC2010/014.</td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>Cover Clause</td>
                    <td>Strikes Riots and Civil Commotions Clause (Inland Transit not in conjuction with Ocean Going Voyage)</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>Cover Clause</td>
                    <td>Termination of Transit Clause (Terrorism) 23.05.2012 Clause No. JC2001/056</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>Standard Conditions and Warranties</td>
                    <td>Exclusion for direct and indirect loss as a result of infectious diseases or contagious disease. Including but not limited to diseases arising out of coronaviruses</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>Standard Conditions and Warranties</td>
                    <td>Container Claims Clause</td>
                </tr>
                <tr>
                    <td>11</td>
                    <td>Standard Conditions and Warranties</td>
                    <td>Institute Replacement Clause-Proportional Valuation 01.12.2008 Clause No. ILU CL373- Applicable for Secondhand and/or Used Goods Only</td>
                </tr>
            </tbody>
        </table>
    </div>
    

    <!-- <div class="row">
        <div style="color: black;" class="col">
            <ol *ngIf="riskCategory=='COM24' || riskCategory=='COM11'">
                <li>Important Notice Clause</li>
                <li>Inland Transit (Rail or Road) Clause-B (Basic Cover) 2010</li>
                <li>Institute Cyber Attacks Exclusion Clause 10.11.2003 Clause No. ILU CL380</li>
                <li>Institute Radioactive Contamination, Chemical, Biological, Bio- chemical and Electromagnetic Weapons Exclusion Clause 10.11.2003 Clause No. ILU CL370</li>    
                <li>Private Carriers Warranty/ Limitation of Liability Clause: The insurer¿s liability shall be limited up to 75% of the assessed loss: (1) Where the consignment Note is issued limiting the liability to the carriers by special contract duly signed by the consignor, consignee or their duly authorized representative or agent or, (2) Where the consignment note is issued by a Private carrier (other than the vehicle belonging to the owner of the goods) or Freight Broker or, (3) Where the goods are being transported in insured¿s own vehicle or, (4) Wherever the recovery rights are not protected by the consignee. This warranty would not apply where loss/damage occurred whilst the goods were not in the custody of the carriers.</li>
                <li>Sanction Limitation and Exclusion Clause 11.08.2010 Clause No. JC2010/014: No insurer shall be deemed to provide cover and no insurer shall be liable to pay any claim or provide any benefit hereunder to the extent that the provision of such cover, payment of such claim or provision of such benefit would expose that insurer to any sanction, prohibition or restriction under United Nations resolutions or the trade or economic sanctions, laws or regulations of the European Union, United Kingdom or United States of America. JC2010/014. 11 August 2010.</li>
                <li>Strikes Riots and Civil Commotions Clause (Inland Transit not in conjuction with Ocean Going Voyage)</li>
                <li>Termination of Transit Clause (Terrorism) 23.05.2012 Clause No. JC2001/056</li>
                <li>Exclusion for direct and indirect loss as a result of infectious diseases or contagious disease. Including but not limited to diseases arising out of coronaviruses</li>
                <li>Institute Replacement Clause-Proportional Valuation 01.12.2008 Clause No. ILU CL373- Applicable for Secondhand and/or Used Goods Only</li>
            </ol>
            <ol *ngIf="riskCategory=='COM10'">
                <li>Important Notice Clause</li>
                <li>Inland Transit (Rail or Road) Clause-B (Basic Cover) 2010</li>
                <li>Institute Cyber Attacks Exclusion Clause 10.11.2003 Clause No. ILU CL380</li>
                <li>Institute Radioactive Contamination, Chemical, Biological, Bio- chemical and Electromagnetic Weapons Exclusion Clause 10.11.2003 Clause No. ILU CL370</li>    
                <li>Private Carriers Warranty/ Limitation of Liability Clause: The insurer¿s liability shall be limited up to 75% of the assessed loss: (1) Where the consignment Note is issued limiting the liability to the carriers by special contract duly signed by the consignor, consignee or their duly authorized representative or agent or, (2) Where the consignment note is issued by a Private carrier (other than the vehicle belonging to the owner of the goods) or Freight Broker or, (3) Where the goods are being transported in insured¿s own vehicle or, (4) Wherever the recovery rights are not protected by the consignee. This warranty would not apply where loss/damage occurred whilst the goods were not in the custody of the carriers.</li>
                <li>Sanction Limitation and Exclusion Clause 11.08.2010 Clause No. JC2010/014: No insurer shall be deemed to provide cover and no insurer shall be liable to pay any claim or provide any benefit hereunder to the extent that the provision of such cover, payment of such claim or provision of such benefit would expose that insurer to any sanction, prohibition or restriction under United Nations resolutions or the trade or economic sanctions, laws or regulations of the European Union, United Kingdom or United States of America. JC2010/014. 11 August 2010.</li>
                <li>Strikes Riots and Civil Commotions Clause (Inland Transit not in conjuction with Ocean Going Voyage)</li>
                <li>Termination of Transit Clause (Terrorism) 23.05.2012 Clause No. JC2001/056</li>
                <li>Exclusion for direct and indirect loss as a result of infectious diseases or contagious disease. Including but not limited to diseases arising out of coronaviruses</li>
            </ol>
        </div>
    </div> -->
</div>



