import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute, ParamMap,NavigationStart } from '@angular/router';
import { fromEvent } from 'rxjs';
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {

  constructor(public service: ApiService,
    public router: Router,
    public route: ActivatedRoute) {
      router.events.forEach((event) => {
        if(event instanceof NavigationStart) {
          if (event.navigationTrigger === 'popstate') {
            /* Do something here */
                this.router.navigate(['/edit',this.txnId,this.userId]);

          }
        }
      });
     }
  
    
  txnId;  

  // BASIc DETAILS
  productCode="N/A";
  policyType="N/A";


  // CUSTOMER DETAILS
  instituitionName="N/A"
    emailAddress="N/A"
    areaPostOffice="N/A"
    buildingAddress="N/A"
    customerName="N/A"
    mobileNumber="N/A"
    gstinUin="N/A"
    pan="N/A"
    cityState="N/A"  

    firstName="N/A"
    lastName="N/A"
    dob="N/A"
    gender="N/A"

  // RISK DETAILS
  riskCategory="N/A";
  packingType="N/A";
  cargoDescription="N/A";

  // TRANSIT DETAILS
  typeOfTransit="N/A";
  modeOftransit="N/A";
  coverageOfWar="N/A";
  coverage="N/A";
  coverageStartDate="N/A";
  country="INDIA";
  transitFrom="N/A";
  transitTo="N/A";
  lrNumber="N/A";
  lrDate="N/A";
  transporterDetails="N/A";
  transporterName="N/A";

  // INVOICE DETAILS
  invoicePhoto;
  invoiceCurrency="INR";
  rateOfExchange="N/A";
  agent="N/A";
  invoiceNumber="N/A";
  invoiceAmount=0;
  basisOfValuation="N/A";
  sumInsured=0;
  invoiceDate="N/A";
  freight=0;
  extra=0;
  invoiceGst="N/A";

  invoiceUrl;
  token;
  response;
  userId;
  clauseRes;
  coverClause;
  otherClause;
  duty=0;
  insuranceCharges=0;

  userType=2;
  ptype;
  containers;
  shippingLine;
  bookingNo='';
  markUp;
  excessAmount;
  excessPercentage;
  containerMovement="N/A"
  premiumRate;
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.txnId=params.id
      this.ptype=params.type
      console.log(this.txnId);
  });
  this.token=window.localStorage.getItem("token");
  this.getDetails();
  console.log(this.lrDate);
  // fromEvent(window, 'popstate')
  // .subscribe((e) => {
  //   console.log(e, 'back button');
  //   this.router.navigate(['/edit',this.txnId,this.userId]);
  // });
  }
  getDetails(){
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.getUserDetails(this.txnId,headers).subscribe(data=>{
      this.response=data;
      console.log(data);
      this.productCode=this.response.data[0].productCode;
      this.policyType=this.response.data[0].policyType;
      if(this.response.data[0].user.userType==2){
        this.instituitionName=this.response.data[0].user.name;
        this.customerName=this.response.data[0].user.representativeName;
      }
      if(this.response.data[0].user.userType==1){
        this.firstName=this.response.data[0].user.name
        this.gender=this.response.data[0].user.gender
        this.lastName=this.response.data[0].user.lastName
        this.dob=this.response.data[0].user.dob
      }
      if(this.policyType=='Inland'){
        if(this.response.data[0].user.gstinUin){
          this.gstinUin=this.response.data[0].user.gstinUin;
        }
      if(this.response.data[0].user.email){
        this.emailAddress=this.response.data[0].user.email;
      }
      this.mobileNumber=this.response.data[0].user.mobileNo;
      if(this.response.data[0].user.panNo){
        this.pan=this.response.data[0].user.panNo;
      }
      this.areaPostOffice=this.response.data[0].user.areaPostOffice;
      this.cityState=this.response.data[0].user.state;
      this.riskCategory=this.response.data[0].riskCategory;
      this.packingType=this.response.data[0].packing;
      this.cargoDescription=this.response.data[0].cargoDescription;
      this.typeOfTransit=this.response.data[0].typeOfTransit;
      this.modeOftransit=this.response.data[0].modeOfTransit;
      this.coverageOfWar=this.response.data[0].coverageOfWarorStrikes;
      this.coverage=this.response.data[0].coverage;
      this.coverageStartDate=this.response.data[0].coverageStartDate;
      this.country=this.response.data[0].fromCountry;
      this.transitFrom=this.response.data[0].coverageFrom;
      this.transitTo=this.response.data[0].coverageEnds;
      this.lrNumber=this.response.data[0].transportationDetails.lrNo;
      this.lrDate=this.response.data[0].transportationDetails.lrDate;
      this.transporterName=this.response.data[0].transportationDetails.transporterName;
      this.invoiceNumber=this.response.data[0].invoice.invoiceNo;
      this.invoiceDate=this.response.data[0].invoice.date;
      this.invoiceCurrency=this.response.data[0].invoice.currency;
      this.invoiceAmount=this.response.data[0].invoice.amount;
      this.extra=this.response.data[0].invoice.extraPerc;
      this.freight=this.response.data[0].invoice.freight;
      this.rateOfExchange=this.response.data[0].invoice.rateOfExchange;
      this.basisOfValuation=this.response.data[0].bov;
      this.agent="Bajaj Allianz General Insurance Company";
      this.invoiceUrl=this.response.data[0].invoice.invoiceUpload;
      this.userId=this.response.data[0]._id;
      this.invoiceGst=this.response.data[0].invoice.invoiceGst;
      this.duty=this.response.data[0].invoice.duty;
      this.insuranceCharges=this.response.data[0].invoice.insuranceCharges;
      this.userType=this.response.data[0].userType;
      if(this.invoiceAmount!=null){
        this.sumInsured=this.invoiceAmount+(this.invoiceAmount*this.extra/100)+this.freight+this.insuranceCharges+this.duty;
      }
      this.getClauses();
    }else{
      if(this.response.data[0].user.gstinUin){
        this.gstinUin=this.response.data[0].user.gstinUin;
      }
      if(this.response.data[0].user.email){
        this.emailAddress=this.response.data[0].user.email;
      }
      this.mobileNumber=this.response.data[0].user.mobileNo;
      this.riskCategory=this.response.data[0].riskCategory;
      this.typeOfTransit=this.response.data[0].typeOfTransit;
      this.modeOftransit=this.response.data[0].modeOfTransit;
      this.coverage=this.response.data[0].coverage;
      this.coverageStartDate=this.response.data[0].coverageStartDate;
      this.transitFrom=this.response.data[0].coverageFrom;
      this.transitTo=this.response.data[0].coverageEnds;
      this.containers=this.response.data[0].container;
      this.buildingAddress=this.response.data[0].user.address
      this.markUp=this.response.data[0].markup;
      this.bookingNo=this.response.data[0].bookingNo;
      this.shippingLine=this.response.data[0].shippingLine;
      if(this.response.data[0].transportationType=='1'){
        this.containerMovement="Single";
        this.premiumRate="0.04%"
      }else{
        this.containerMovement="To/From";
        this.premiumRate="0.08%"
      }

      for(let i=0;i<this.containers.length;i++){
        console.log(this.containers);
        console.log(this.sumInsured);
        
        if(this.response.data[0].transportationType=='2'){
        this.sumInsured+=parseInt(this.containers[i].value);
        }else{
          this.sumInsured+=parseInt(this.containers[i].value);
        }
        console.log(parseInt(this.containers[i].value));
        
        
      }
      this.userId=this.response.data[0]._id;
      console.log(this.sumInsured);
    }

      
    },err=>{
      console.log(err);
      alert(err.error);
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
  }
  onEdit(){
    this.router.navigate(['/edit',this.txnId,this.userId]);
  }

  getClauses(){
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.getCommidityClause(this.txnId,headers).subscribe(async (data:any)=>{
      this.clauseRes=await data;
      this.coverClause=this.clauseRes?.data.coverClauseDetails;
      this.otherClause=this.clauseRes?.data.otherClauseDetails;
      this.excessAmount=this.clauseRes?.data.commodity.excessAmt;
      this.excessPercentage=this.clauseRes?.data.commodity.excessPerc;
      console.log(data);
      console.log(this.coverClause);
      console.log(this.otherClause);
      
      
    },err=>{
      console.log(err);
      alert(err.error);
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
      
    })
  }
  onIssuePolicy(){
    if(this.policyType=="Container"){
      this.getContainerDetails();
    }else{
      this.getinlandDetails();
    }
  }
  onlogout(){
    if(this.token){
      const headers = { 'Authorization':'Bearer '+ this.token}
      
      this.service.logout(headers).subscribe(data=>{
        console.log(data);
        window.localStorage.removeItem("token");
        
        this.router.navigate(['login'])
        
      },err=>{
        alert(err.error.error);
      })

    }else{
      alert("Something went wrong...")
    }
    
  }
  getinlandDetails(){
    const headers = { 'Authorization':'Bearer '+this.token}
    var response;
    console.log(this.token);
    console.log(this.txnId);
    
    
    this.service.getPdfDetails(this.txnId,headers).subscribe(data=>{
      console.log(data);
      response=data;
      this.router.navigate(['/policy-generated',this.txnId]);
         
    },err=>{
      console.log(err);
      alert(err);
    })
  }

  getContainerDetails(){
    const headers = { 'Authorization':'Bearer '+this.token}
    var response;
    console.log(this.token);
    console.log(this.txnId);
    
    
    this.service.getContainerPdfDetails(this.txnId,headers).subscribe(data=>{
      console.log(data);
      response=data;
      this.router.navigate(['/policy-generated',this.txnId]);
    },err=>{
      alert(err.error);
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
  }
}
