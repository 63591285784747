<div>
    <div style="height: 100vh;margin: 0;" class="row">
        <div class="col-md-5  col-xs-12" >
            <div style="display: flex;align-items: center;height: 100%;">
            <div style="padding: 20px;width: 100%;">
                <img class="ucilogo" style="margin-bottom: 10px;" src="assets/icon/ucilogo.svg" />
                <h2>Hello There.</h2>
                <p style="color: #6F6F6F;">Insurance made simple</p>
                
                <form [formGroup]="loginDetails" (ngSubmit)="onLogin()">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>                                
                          </span>
                        </div>
                        <input [(ngModel)]="username" formControlName="userName" type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                      </div>

                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                </svg>                                
                          </span>
                        </div>
                        <input type="password" [(ngModel)]="password" formControlName="password"  class="form-control" placeholder="Password"  aria-describedby="basic-addon1">
                      </div>

                      <button  type="submit" class="btn btn-primary btn-lg btn-block"><span *ngIf="!loginLoading">Proceed

                        <svg style="margin-top: -5px;" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                        </svg>
                      </span>
                        <div style="height: 25px;width: 25px;" *ngIf="loginLoading" class="spinner-border text-light" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                </form>
                <div style="text-align: center;">
                  <span style="color: gray;">Powered by</span><a href="http://policyinsure.com/" target="blank"><img  style="width: 10%;margin-left: 5px;cursor: pointer;" src="assets/icon/logo.svg" alt="Logo"/></a>
                </div>
            </div>
        </div>
        </div>

        <div style="background-color: #EDF7FF;" class="col-md-7 rightcont">
            <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
                <img style="width: 50%;" src="assets/icon/ucilogo.svg">
            </div>
        </div>

    </div>
</div>