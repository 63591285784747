import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
// import { jsPDF } from "jspdf";
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-policy-generated',
  templateUrl: './policy-generated.component.html',
  styleUrls: ['./policy-generated.component.css']
})
export class PolicyGeneratedComponent implements OnInit {
  @ViewChild('htmlData') htmlData:ElementRef;

  txnId;
  userId;
  token;
  formdata =new FormData;

  grossPremium="AS AGREED";
  netPremium="AS AGREED";
  sumInsured=0;

  policyNumber="OG-22-1501-1005-00000016";
  periodOfIssuance="N/A";
  insuredNameandaddress="N/A";
  gstUin="N/A";
  companyGst="N/A";
  pan="N/A";
  coInsurance="N/A";
  fromPlace="N/A";
  toPlace="N/A";
  product="N/A";
  issueDate="N/A";
  stateCodeName="N/A";
  gstInvoiceNo="N/A";
  bov="N/A";
  policyRate="N/A";
  duty="As agreed";
  coverClause;
  otherClause;
  invoiceGst;


  fileWidth;
  fileHeight;
  position;
  FILEURI1;
  FILEURI2;
  receiptUri;
  receiptUri2;
  insuredAddress='N/A'
  customerId='N/A'
  endorsement='N/A'
  policyStatus="Issued"
  modeOfTransit='N/A'
  containerMovement='N/A'
  transitType='N/A'
  coverage='N/A'
  containers;
  riskCategory;
  policyType;
  hide;
  buildingAddress='N/A';
  insuredName='N/A'
  certificateNo='N/A'
  cargoDetails='N/A';
  excessAmount='N/A';
  excessPercentage='N/A';
  excessClause='N/A';
  pinCode='N/A';
  address="N/A";
  name="N/A";
  netPremium2;
  grossPremium2;
  invoiceNo;
  cgst;
  sgst;
  rid;
  riskCategoryType;
  constructor(public service: ApiService,
    public route:ActivatedRoute,public router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.txnId=params.txnid;
  });
  this.token=window.localStorage.getItem("token");


  this.getFullDetails();

  }
  generateReceipt(){
    let Page1 = document.getElementById('receipt');
    let Page2 = document.getElementById('receipt2');
    html2canvas(Page1).then(canvas => {
      canvas.getContext('2d'); 
      this.receiptUri = canvas.toDataURL('image/png');
    });
    html2canvas(Page2).then(canvas => {
      canvas.getContext('2d'); 
      this.receiptUri2 = canvas.toDataURL('image/png');
      this.downloadReceipt();
    });
  }
  downloadReceipt(){
    let PDF = new jsPDF('p','mm',[210,250],true);
        PDF.setProperties({
          title: "insurancepolicy"
          });
        PDF.addImage(this.receiptUri, 'PNG', 0, 0, 210, 250);
        PDF.addPage();
        PDF.addImage(this.receiptUri2, 'PNG', 0, 0, 210, 250);
        PDF.save('receipt.pdf');
  }

  createPdf(){
    console.log(this.policyType);
    
    if(this.policyType=='Inland'){
      console.log("innllll");

      let Page1 = document.getElementById('pdfpg1');
      let Page2 = document.getElementById('pdfpg2');
      html2canvas(Page1).then(canvas => {
        canvas.getContext('2d'); 
        this.fileWidth = 208;
        this.fileHeight =canvas.height * this.fileWidth / canvas.width;
        this.FILEURI1 = canvas.toDataURL('image/jpg');
        this.position = 0;
      });
      html2canvas(Page2).then( canvas => {
        canvas.getContext('2d');
      this.fileWidth = 208;
      this.fileHeight =canvas.height * this.fileWidth / canvas.width;
      this.FILEURI2 = canvas.toDataURL('image/jpg');
      this.position = 0;
      this.sampleVariable();
    });
  }else{
    console.log("conttt");
    
    let Page1 = document.getElementById('containerpg1');
      let Page2 = document.getElementById('containerpg2');
      html2canvas(Page1).then(canvas => {
        canvas.getContext('2d'); 
        this.fileWidth = 208;
        this.fileHeight =canvas.height * this.fileWidth / canvas.width;
        this.FILEURI1 = canvas.toDataURL('image/jpg');
        this.position = 0;
      });
      html2canvas(Page2).then( canvas => {
        canvas.getContext('2d');
      this.fileWidth = 208;
      this.fileHeight =canvas.height * this.fileWidth / canvas.width;
      this.FILEURI2 = canvas.toDataURL('image/jpg');
      this.position = 0;
      this.sampleVariable();
    });
  }
  }
  sampleVariable(){
    let PDF = new jsPDF('p','mm',[200,250],true);
        PDF.setProperties({
          title: "insurancepolicy"
          });
        PDF.addImage(this.FILEURI1, 'PNG', 0, 0, 190, 250);
        PDF.addPage();
        PDF.addImage(this.FILEURI2, 'PNG', 0, 0, 200, 250);
        PDF.save('InsurancePolicy.pdf');
        var pdfoutput=PDF.output('dataurlstring',{
          filename: 'policy'
        });
        console.log(pdfoutput);

        this.formdata.append('policy',pdfoutput);
        this.formdata.append('transactionId',this.txnId)
        const headers = { 'Authorization':'Bearer '+this.token}
        this.service.postPolicy(this.formdata,headers).subscribe(data=>{
          console.log(data);
          
        },err=>{
          alert(err.error);
        });
      }
  getDetails(){
    const headers = { 'Authorization':'Bearer '+this.token}
    var response;
    console.log(this.token);
    console.log(this.txnId);
    
    
    this.service.getPdfDetails(this.txnId,headers).subscribe(data=>{
      console.log(data);
      response=data;
      this.sumInsured=response.data.sumInsured;
      this.certificateNo=response.data.certificateNumber; 
      // this.policyNumber=response.data.policyNumber;
         
    },err=>{
      console.log(err);
      alert(err);
    })
  }

  getContainerDetails(){
    const headers = { 'Authorization':'Bearer '+this.token}
    var response;
    console.log(this.token);
    console.log(this.txnId);
    
    
    this.service.getContainerPdfDetails(this.txnId,headers).subscribe(data=>{
      console.log(data);
      response=data;
      if(this.containerMovement=="1"){
        this.sumInsured=response.data.sumInsured;
      }else{
        this.sumInsured=response.data.sumInsured;
      }
      this.certificateNo=response.data.certificateNumber;   
      this.netPremium2=response.data.netPremium;
      this.grossPremium2=response.data.grossPremium;
      this.invoiceNo=response.data.INVno;
      this.cgst=response.data.CGST;
      this.sgst=response.data.SGST;
      this.riskCategory=response.data.commodityDetails.commodityCode;
      this.riskCategoryType=response.data.commodityDetails.commodityDesc;
    },err=>{
      alert(err.error);
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
  }

  getFullDetails(){
    const headers = { 'Authorization':'Bearer '+this.token}
    var response;
    this.service.getUserDetails(this.txnId,headers).subscribe(data=>{
      console.log(data);
      response=data;
      this.policyType=response.data[0].policyType;
      this.insuredNameandaddress=response.data[0].user.address;
      if(response.data[0].user.gstinUin){
        this.gstUin=response.data[0].user.gstinUin;
      }
      this.pan=response.data[0].user.panNo;
      this.fromPlace=response.data[0].coverageFrom;
      this.rid=response.data[0].coverageStartDate;
      this.toPlace=response.data[0].coverageEnds;
      this.product=response.data[0].productCode;
      this.bov=response.data[0].bov;
      this.cargoDetails=response.data[0].cargoDescription;
      if(response.data[0].user.pinCode){
        this.pinCode=response.data[0].user.pinCode;
      }
      if(this.policyType=='Inland'){
        this.duty=response.data[0].invoice.duty;
        this.invoiceGst=response.data[0].invoice.invoiceGst;
        this.getClause();
        this.getDetails();     
      }
      if(this.policyType=='Container'){
        this.modeOfTransit=response.data[0].modeOfTransit;
        this.transitType=response.data[0].typeOfTransit;
        this.coverage=response.data[0].coverage;
        this.containers=response.data[0].container;
        this.buildingAddress=response.data[0].user.address;
        this.insuredName='Tenacious Technology on A/C '+response.data[0].shippingLine+'-'+ response.data[0].user.name;
        this.containerMovement=response.data[0].transportationType;
        this.getContainerDetails();
      }
      this.issueDate=response.data[0]?.policyGeneration?.date;
      this.address=response.data[0]?.user?.address;
      this.name=response.data[0]?.user?.name;
    },err=>{
      alert(err.error);
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
  }

  getClause(){
    const headers = { 'Authorization':'Bearer '+this.token}
    var response;

    this.service.getCommidityClause(this.txnId,headers).subscribe(data=>{
      console.log(data);
      response=data;
      this.coverClause=response.data.coverClauseDetails;
      this.otherClause=response.data.otherClauseDetails;
      this.excessAmount=response?.data.commodity.excessAmt;
      this.excessPercentage=response?.data.commodity.excessPerc;
      this.excessClause=response?.data.commodity.excessClausesDesc;
    },err=>{
      alert(err.error);
      if(err.status==(401 || 417)){
        this.router.navigate(['login']);
      }
    })
  }

  onlogout(){
    if(this.token){
      const headers = { 'Authorization':'Bearer '+ this.token}
      
      this.service.logout(headers).subscribe(data=>{
        console.log(data);
        window.localStorage.removeItem("token");
        
        this.router.navigate(['login'])
        
      },err=>{
        alert(err.error.error);
      })

    }else{
      alert("Something went wrong...")
    }
    
  }

}
