<div style="background: linear-gradient(90deg, #277ADF 0%, #0C47C5 129.03%, #0C47C5 129.03%);height:30vh;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
    <div style="text-align: right;padding-top: 10px;padding-right: 10px;">
        <button (click)="onlogout()" style="color: white;" class="btn">Logout <img  src="assets/icon/logout.svg" /></button>
    </div>
    <br><br><br><h1 style="color: white;text-align: center;">POLICY ISSUANCE PORTAL</h1>
</div>

<div style="position: relative;bottom: 35px;background-color: rgb(250, 250, 250);border-radius: 1rem;padding-left: 30px;padding-right: 30px;text-align: center;" class="container">
    <img style="width: 8%;margin-top: 20px;margin-bottom: 20px;" src="assets/icon/checked.svg" />
    <h5>Your policy has been<br> successfully generated.</h5>
    <button style="background-color: #1E68D6;margin-right: 10px;" routerLink='/show-policies' class="btn btn-primary mt-3 mb-5">Go to home</button>
    <button (click)="createPdf()"  style="background-color: #1E68D6;margin-right: 10px;" type="button" class="btn btn-primary mt-3 mb-5">Download Policy <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.66699 6.66675L8.00033 10.0001L11.3337 6.66675" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 10V2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
    <button (click)="generateReceipt()"  style="background-color: #1E68D6;" type="button" class="btn btn-primary mt-3 mb-5">Download Receipt <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.66699 6.66675L8.00033 10.0001L11.3337 6.66675" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 10V2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
</div>


<div *ngIf="policyType=='Inland'"  class="inland" style="border:1px solid black;">
<div class="print-wrap page1" id="pdfpg1" class="container">
    <div style="text-align: center;">
        <h4 style="font-weight: bold;">Bajaj Allianz General Insurance Company Ltd.</h4>
        <h6>Bajaj Allianz House, Airport Road, Yerwada, Pune - 411006(India)</h6>
        <h6>MARINE POLICY SCHEDULE</h6>
        <p style="text-align: justify;">Whereas the Assured has made a proposal to Bajaj Allianz General Insurance Company Limited ( hereinafter referred to as "the Company") which is hereby agreed
            to be the basis of this policy and has paid the premium specified in the schedule, the Company hereby agrees, to insure against loss, damage, liability or expenses
            subject to the limit of indemnity and the clauses, endorsements, exclusions, conditions and warranties in the Schedule and this policy </p>
    </div>
    <div style="padding: 5px;" class="div">
        <p style="font-weight: bold;"> 
            Policy issuing office and Correspondence address for communication 197/498, 5th floor , Isana Kattima building, Poonamallee High Road,<br>
            by policyholder for claim, service request, notice, summons, etc Arumbakkam,, , , Chennai-600106, Phone No: 044-43904400 
        </p>
        <br>
        <div class="row">
            <div class="col">
                <label style="font-weight: bold;">Policy Number:      {{ policyNumber }}</label><div></div>
                <label style="font-weight: bold;">Risk Inception Date:      From {{ rid }}</label><div></div>
                <label style="font-weight: bold;">Insured Name & Address:      {{ insuredNameandaddress }}</label><div></div>
                <label style="font-weight: bold;">GSTIN / UIN: {{   gstUin }}</label><div></div>
                <label style="font-weight: bold;">Company GST:{{ companyGst }}</label><div></div>
                <label style="font-weight: bold;">Company PAN:{{ pan }}</label><div></div>
                <label style="font-weight: bold;">Co insurance:{{ coInsurance }}</label><div></div>
                <label style="font-weight: bold;">From Place:{{ fromPlace }}</label><div></div>
                <label style="font-weight: bold;">To Place:{{ toPlace }}</label><div></div>
                <label style="font-weight: bold;">Address:{{ address }}</label><div></div>
            </div>
            <div class="col">
                <label style="font-weight: bold;">Product:{{ product }}</label><div></div>
                <label style="font-weight: bold;">Issue Date:{{ issueDate }}</label><div></div>
                <label style="font-weight: bold;">State Code / Name:{{ stateCodeName }}</label><div></div>
                <label style="font-weight: bold;">GST Invoice No.:{{ invoiceGst }}</label><div></div>
                <label style="font-weight: bold;">Certificate No:{{ certificateNo }}</label><div></div>

            </div>
        </div>

        <h5 style="font-weight: bold;">Consignment Details</h5>
        <table style="width: 100%;">
            <th style="border: 1px solid black;">Cargo Details</th>
            <th style="border: 1px solid black;">Packaging Details</th>
            <th style="border: 1px solid black;">SI(in FC)</th>
            <th style="border: 1px solid black;">Si(in Rs)</th>
            <tr style="border: 1px solid black;">
                <td style="border: 1px solid black;">
                    {{ cargoDetails }}
                </td>
                <td style="border: 1px solid black;">
                    As Per Invoice
                </td>
                <td style="border: 1px solid black;">
                    
                </td>
                <td style="border: 1px solid black;">
                    {{ sumInsured  }}
                </td>
            </tr>
        </table>
        
        <label style="font-weight: bold;" for="">Basis of Valuation:    {{ bov }}</label><div></div>
        <label style="font-weight: bold;" for="">Policy Rate:    {{ policyRate }}</label><div></div>
        <label style="font-weight: bold;" for="">Net Premium:    {{ netPremium }}</label><div></div>
        <label style="font-weight: bold;" for="">Stamp Duty:    {{ duty }}</label><div></div>
        <label style="font-weight: bold;" for="">Gross Premium:    {{ grossPremium }}</label><div></div>

        <label style="font-weight: bold;" for="">Clauses:-</label>
        <p>Subject to the undernoted clauses, endorsements, special conditions and warranties attached which form part of the Policy.
        </p>
        <p style="margin-bottom: 0;">Standard Clauses</p>
        <table style="margin-bottom: 10px;">
            <thead>
                <tr>
                    <th style="text-align: center;padding-right: 20px;">No</th>
                    <th >Clause Category</th>
                    <th >Clause Description</th>
                </tr>
            </thead>
            <tbody>
                <tr style="margin-bottom: 10px;" *ngFor="let clause of coverClause; let i=index">
                    <td style="text-align: center;padding-right: 20px;">{{i+1}}</td>
                    <td>{{ clause.clauseType }}</td>
                    <td>{{ clause.clauseDesc }}</td>
                </tr>
               <br>
                <tr *ngFor="let clause of otherClause; let i=index">
                    <td style="text-align: center;padding-right: 20px;">{{i+1}}</td>
                    <td>{{ clause.clauseType }}</td>
                    <td>{{ clause.clauseDesc }}</td>
                </tr>
            </tbody>
        </table>
        <label  for=""><b style="margin-right: 10px;">Excess Clause:</b>    {{ excessClause }}</label><div></div>
        <!-- <div *ngFor="let clause of coverClause; let i=index" cl>
            <div class="col-2">
                {{clause.clauseType}}
            </div>
            <div class="col">
                {{clause.clauseDesc}}
            </div>
        </div> -->
        <!-- <ol>
            <li *ngFor="let clause of coverClause; let i=index">{{ clause.clauseDesc }}</li>
        </ol>

        <ol>
            <li *ngFor="let clause of otherClause; let i=index">{{ clause.clauseDesc }}</li>
        </ol> -->
        <p>warranted that the cover under within mentioned policy to commensurate only with terms of sale and under no circumstances the coverage shall be extend beyond the
            terms of sale between the concerned parties.</p>
        

        <label style="font-weight: bold;" for="">Claims ayable at</label><div>Bajaj Allianz General Insurance Co Ltd. , 497/498, 5th floor, Isana Kattima building,,Poonamallee Hi</div>
        <div style="border: 1px solid black;">
            <div class="row">
                <div class="col">
                    <h4>Agency Code</h4>
                </div>
                <div class="col">
                    <h4>10088065</h4>
                </div>
                <div class="col">
                    <h4>Agency Name</h4>
                </div>
                <div class="col">
                    <h4>BRIXTON INSURANCE BROKER PRIVATE LIMITED </h4>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h4>Agency Contact</h4>
                </div>
                <div class="col">
                    <h4>9884128820</h4>
                </div>
                <div class="col">
                    <h4>Agency Email</h4>
                </div>
                <div class="col">
                    <h4>info@policyinsure.com</h4>
                </div>
            </div>

        </div>
    </div>
    <div>
        <p style="color: blue;">In case of any claim, please contact our 24 Hour Call centre at 1800-22-5858, 1800-102-5858,1800-209-5959(Toll Free) / 30305858 (chargeable, add area code</p>
        <p style="font-weight: bold;">Regd. Office Bajaj Allianz House, Airport Road, Yerwada, Pune - 411006 
            <br>A Company incorporated under Indian Companies Act, 1956 and licensed by Insurance Regulatory and Development Authority of India
            <br>[IRDA] vide Reg No.113.Corporate Identification Number U66010PN2000PLC015329
        </p>
    </div>
    
</div>
<hr>
<div class="print-wrap page2" id="pdfpg2" class="container" style="margin-bottom: 500px;">
    <div style="text-align: center;">
        <h4 style="font-weight: bold;">Bajaj Allianz General Insurance Company Ltd.</h4>
        <h6>Bajaj Allianz House, Airport Road, Yerwada, Pune - 411006(India)</h6>
        <h6>MARINE POLICY SCHEDULE</h6>
        <p style="text-align: justify;">Whereas the Assured has made a proposal to Bajaj Allianz General Insurance Company Limited ( hereinafter referred to as "the Company") which is hereby agreed
            to be the basis of this policy and has paid the premium specified in the schedule, the Company hereby agrees, to insure against loss, damage, liability or expenses
            subject to the limit of indemnity and the clauses, endorsements, exclusions, conditions and warranties in the Schedule and this policy </p>
    </div>
    <div style="padding: 5px;">
        <br>
        <br>
        <br>
        <br>
        <p>Schedule herein above, subject to the terms and conditions of the relative open policy</p>
        <p style="font-weight: bold;">For & on behalf of Bajaj Allianz Gen. Insurance Co. Ltd.</p>
        <div class="row">
            <div class="col">
                <img src="assets/images/signature.svg">
                <p style="font-weight: bold;">Authorized Signatory<br>
                   Printed , Signed and Executed<br>
                 at Pune</p>
            </div>
            <div class="col">
                <p style="font-weight: bold;">
                    Consolidated Stamp Duty as agreed, paid towards Insurance Stamps vide Challan<br>
No. MH013770432201920M Defaced No. 0000433117202021 Order<br>
No.CSD/22/2020/1432/2020 Order Dated 09.06.2020Defaced date dated 09-JUN20 timing 12:23:03 of General Stamp Office,Mumbai,India.
                </p>
            </div>
        </div>
        <p style="text-align: justify;font-weight: bold;text-decoration: underline;">Principal Location : 497/498, 5th floor, Isana Kattima building, Poonamallee High Road, Arumbakkam, Chennai - 600106 PH:044-<br>
            43904400 | Services Accounting Code : 997135 - Marine, aviation, and other transport insurance services. No reverse charge is payable<br>
            on these services.</p>
    </div>
</div>
</div>

<div *ngIf="policyType=='Container'" class="container" style="border:1px solid black;">
<div id="containerpg1">

    <div style="padding: 20px 40px;">
    <div style="text-align: center;">
        <img style="width: 14%;" src="assets/icon/bajajlogo.png" />
    </div>    
    <h5 style="text-align: center;font-weight: bold;">BAJAJ ALLIANZ GENERAL INSURANCE COMPANY LIMITED</h5>
    <p style="text-align: center;">(A Company incorporated under Indian Companies Act, 1956 and licensed by Insurance Regulatory and Development Authority of India [IRDAI] vide Regd. No.113)
       <br> Regd. Office: Bajaj Allianz House, Airport Road, Yerwada, Pune 411006 (India)
        </p>
    <hr>
    <h5 style="text-align: center;">MARINE CARGO INSURANCE POLICY SCHEDULE <span><p>UIN.IRDAN113RP0024V01200102</p></span></h5>
    <div class="row container">
        <p><span><b>Policy Issuing Office:</b></span>497/498, 5th floor, Isana Kattima building, Poonamallee High Road, Arumbakkam, Chennai - 600106</p>
        <p><span><b><span><b>Policy Number:</b><b>{{ policyNumber }}</b></span><br> Insured Name:</b></span>{{ insuredName }}</p>
    </div>
    <div class="row">
        <div class="col">
            <p><b>INSURED DETAILS</b></p>
            <div class="row">
                <div class="col">
                    <p><b>Customer ID</b> </p>
                </div>
                <div class="col">
                    {{ customerId }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><b>GSTIN / UIN</b> </p>
                </div>
                <div class="col">
                    {{ gstUin }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><b>STATE
                        CODE/NAME</b> </p>
                </div>
                <div class="col">
                    {{ stateCodeName }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><b>Company GST
                        No :</b> </p>
                </div>
                <div class="col">
                    N/A
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><b>Company PAN :</b> </p>
                </div>
                <div class="col">
                    {{ pan }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><b>Address :</b> </p>
                </div>
                <div class="col">
                    {{ address }}
                </div>
            </div>
        </div>
        <div class="col">
            <p><b>POLICY DETAILS</b></p>
            <div class="row">
                <div class="col">
                    <p><b>Policy Issued on</b> </p>
                </div>
                <div class="col">
                    {{ issueDate }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><b>Risk Inception Date</b> </p>
                </div>
                <div class="col">
                    From {{ rid }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><b>Endorsement</b> </p>
                </div>
                <div class="col">
                    {{ endorsement }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><b>Policy Status</b> </p>
                </div>
                <div class="col">
                    {{ policyStatus }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><b>Certificate No</b> </p>
                </div>
                <div class="col">
                    {{ certificateNo }}
                </div>
            </div>
        </div>
    </div>
    <p><b>Transit Details</b></p>
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    <b>Mode of Transit</b>
                </div>
                <div class="col">
                    {{ modeOfTransit }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b>From Country</b>
                </div>
                <div class="col">
                    INDIA
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b>Coverage From</b>
                </div>
                <div class="col">
                    {{ fromPlace }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b>LR No.</b>
                </div>
                <div class="col">
                    N/A
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row">
                <div class="col">
                    <b>Transit Type</b>
                </div>
                <div class="col">
                    {{ transitType }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b>To Country</b>
                </div>
                <div class="col">
                    INDIA
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b>Coverage Ends at</b>
                </div>
                <div class="col">
                    {{ toPlace }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b>Coverage</b>
                </div>
                <div class="col">
                    {{ coverage }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b>LR Date:</b>
                </div>
                <div class="col">
                    N/A
                </div>
            </div>
        </div>
    </div>
    <table class="table" style="margin-top: 20px;">
        <thead>
            <tr style="font-weight: bold;">
                <td>Container No </td>
                <td>Container Type</td>
                <td>Container Size</td>
                <td>Value of Container (in INR)</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let container of containers">
                <td>{{container.number?container.number:"N/A"}}</td>
                <td>{{riskCategoryType}}</td>
                <td>{{ container.size }}</td>
                <td>{{ container.value }}</td>
            </tr>
        </tbody>
    </table>

    <div class="row">
        <div class="col-8">
            <div class="row mb-1">
                <div class="col">
                    <b>Basis of Valuation</b>
                </div>
                <div class="col">
                    {{ bov }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col">
                    <b>Sum Insured (in INR)</b>
                </div>
                <div class="col">
                    {{ sumInsured }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col">
                    <b>Excess if any</b>
                </div>
                <div class="col">
                    The first .5% of whole consignment value subject to
                    minimum of INR 10000 each and every loss
                </div>
            </div>
        </div>
        <div class="col">

        </div>
    </div>
    <!-- <table class="table">
        <thead>
            <tr style="font-weight: bold;">
                <td>Basis of Valuation</td>
                <td>Sum Insured (in INR)</td>
                <td>Excess if any</td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ bov }}</td>
                <td>{{ sumInsured }}</td>
                <td>The first .5% of whole consignment value subject to
                    minimum of INR 10000 each and every loss</td>
            </tr>
        </tbody>
    </table> -->
    <p><b>Special Condition:</b>N/A</p>

    <p><b>PREMIUM DETAILS</b></p>
    <div class="row">
        <div class="col">

        </div>
        <div class="col">
            <div class="row">
                <div class="col">
                    <b>Net Premium</b>
                </div>
                <div class="col">
                    {{ netPremium }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b>Premium in Wordings</b>
                </div>
                <div class="col">
                    AS AGREED
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b>Gross Premium</b>
                </div>
                <div class="col">
                    {{ grossPremium }}
                </div>
            </div>
        </div>
    </div>	
    <p><b>Premium collection details: Premium, GST, Stamp Duty collected under Policy No. OG-22-1501-1005-00000016.
    </b></p>
    <p><b>Co-Insurance Details:</b>Own Share: 100%</p>

    <p><b>SCOPE OF COVER (As per Clauses, Terms & Conditions, Warranties & Exceptions of Open Cover/Policy issued.)</b></p>
    <table class="table">
        <thead>
            <tr>
                <th>No</th>
                <td><b>Clause Category</b></td>
                <td><b>Clause Description</b></td>
            </tr>
        </thead>
        <tbody *ngIf="riskCategory=='COM24' || riskCategory=='COM11'">
            <tr>
                <td>1</td>
                <td>Cover Clause</td>
                <td>Important Notice Clause</td>
            </tr>
            <tr>
                <td>2</td>
                <td>Cover Clause</td>
                <td>Inland Transit (Rail or Road) Clause-B (Basic Cover) 2010</td>
            </tr>
            <tr>
                <td>3</td>
                <td>Cover Clause</td>
                <td>Institute Cyber Attacks Exclusion Clause 10.11.2003 Clause No. ILU CL380</td>
            </tr>
            <tr>
                <td>4</td>
                <td>Cover Clause</td>
                <td>Institute Radioactive Contamination, Chemical, Biological, Bio- chemical and Electromagnetic Weapons Exclusion Clause 10.11.2003 Clause No. ILU CL370</td>
            </tr>
            <tr>
                <td>5</td>
                <td>Cover Clause</td>
                <td>Private Carriers Warranty/ Limitation of Liability Clause</td>
            </tr>
            <tr>
                <td>6</td>
                <td>Cover Clause</td>
                <td>Sanction Limitation and Exclusion Clause 11.08.2010 Clause No. JC2010/014</td>
            </tr>
            <tr>
                <td>7</td>
                <td>Cover Clause</td>
                <td>Strikes Riots and Civil Commotions Clause (Inland Transit not in conjuction with Ocean Going Voyage)</td>
            </tr>
            <tr>
                <td>8</td>
                <td>Cover Clause</td>
                <td>Termination of Transit Clause (Terrorism) 23.05.2012 Clause No. JC2001/056</td>
            </tr>
            <tr>
                <td>9</td>
                <td>Standard Conditions and Warranties</td>
                <td>Exclusion for direct and indirect loss as a result of infectious diseases or contagious disease. Including but not limited to diseases arising out of coronaviruses</td>
            </tr>
            <tr>
                <td>10</td>
                <td>Standard Conditions and Warranties</td>
                <td>Institute Replacement Clause-Proportional Valuation 01.12.2008 Clause No. ILU CL373- Applicable for Secondhand and/or Used Goods Only</td>
            </tr>
        </tbody>
        <tbody *ngIf="riskCategory=='COM10'">
            <tr>
                <td>1</td>
                <td>Cover Clause</td>
                <td>Important Notice Clause</td>
            </tr>
            <tr>
                <td>2</td>
                <td>Cover Clause</td>
                <td>Inland Transit (Rail or Road) Clause-B (Basic Cover) 2010</td>
            </tr>
            <tr>
                <td>3</td>
                <td>Cover Clause</td>
                <td>Institute Cyber Attacks Exclusion Clause 10.11.2003 Clause No. ILU CL380</td>
            </tr>
            <tr>
                <td>4</td>
                <td>Cover Clause</td>
                <td>Institute Radioactive Contamination, Chemical, Biological, Bio- chemical and Electromagnetic Weapons Exclusion Clause 10.11.2003 Clause No. ILU CL370</td>
            </tr>
            <tr>
                <td>5</td>
                <td>Cover Clause</td>
                <td>Private Carriers Warranty/ Limitation of Liability Clause.</td>
            </tr>
            <tr>
                <td>6</td>
                <td>Cover Clause</td>
                <td>Sanction Limitation and Exclusion Clause 11.08.2010 Clause No. JC2010/014.</td>
            </tr>
            <tr>
                <td>7</td>
                <td>Cover Clause</td>
                <td>Strikes Riots and Civil Commotions Clause (Inland Transit not in conjuction with Ocean Going Voyage)</td>
            </tr>
            <tr>
                <td>8</td>
                <td>Cover Clause</td>
                <td>Termination of Transit Clause (Terrorism) 23.05.2012 Clause No. JC2001/056</td>
            </tr>
            <tr>
                <td>9</td>
                <td>Standard Conditions and Warranties</td>
                <td>Exclusion for direct and indirect loss as a result of infectious diseases or contagious disease. Including but not limited to diseases arising out of coronaviruses</td>
            </tr>
            <tr>
                <td>10</td>
                <td>Standard Conditions and Warranties</td>
                <td>Container Claims Clause</td>
            </tr>
            <tr>
                <td>11</td>
                <td>Standard Conditions and Warranties</td>
                <td>Institute Replacement Clause-Proportional Valuation 01.12.2008 Clause No. ILU CL373- Applicable for Secondhand and/or Used Goods Only</td>
            </tr>
        </tbody>
    </table>
    <hr>
    <div class="row">
        <div class="col">
            <p>For help and more information:</p>
        </div>
        <div style="text-align: right;" class="col">
            <p>Page 1 of 2</p>
        </div>
    </div>
    <p ><b>Contact our 24 Hour Call Centre at 1800-209-5858, 1800-102-5858</b></p>
    <div class="row">
        <div class="col">
            Email: Bagichelp@bajajallianz.co.in , Website www.bajajallianz.com
        </div>
        <div style="text-align: right;" class="col">
            <b >Corporate Identification Number: U66010PN2000PLC015329</b>
        </div>
    </div>
    <p><b>Demystify Insurance</b>  http://support.bajajallianz.com   http://www.facebook.com/BajajAllianz   http://twitter.com/BajajAllianz   www.bit.do/bjazgi</p>
</div>
</div>
<div id="containerpg2" style="padding: 20px 40px;">
    <div style="margin-top: 30px;" id="page-2">
        <div style="text-align: center;">
            <img style="width: 14%;" src="assets/icon/bajajlogo.png" />
        </div>
        <h6 style="text-align: center;font-weight: bold;">BAJAJ ALLIANZ GENERAL INSURANCE COMPANY LIMITED</h6>
        <p style="text-align: center;">(A Company incorporated under Indian Companies Act, 1956 and licensed by Insurance Regulatory and Development Authority of India [IRDAI] vide Regd. No.113)
           <br> Regd. Office: Bajaj Allianz House, Airport Road, Yerwada, Pune 411006 (India)
            </p>
        <hr>
        <h6 style="text-align: center;">MARINE CARGO INSURANCE POLICY SCHEDULE <span><p>UIN.IRDAN113RP0024V01200102</p></span></h6>
        <div class="row container">
            <p><span><b>Policy Issuing Office:</b></span>497/498, 5th floor, Isana Kattima building, Poonamallee High Road, Arumbakkam, Chennai - 600106</p>
            <p><span><b><span><b>Policy Number:</b><b>{{ policyNumber }}</b></span><br> Insured Name:</b></span>{{ insuredName }}</p>
        </div>
    </div>
    <p><b>Survey/Claim Settling Agent:</b>Bajaj Allianz General Insurance Co. Ltd.,497/498, 5th floor, Isana Kattima building, Poonamallee High Road, Arumbakkam,
        Chennai - 600106
        </p>
    <p><b>Claim, if any payable at/by: </b>Chennai Branch</p>
    <p><b>Premium collection details</b>Receipt Number: 1501-01152911,1501-01152915 Date: 12-JAN-21,12-JAN-21 Premium Payer ID: 196351524 Float: AF
        ** If Premium paid through Cheque, the Policy is void ab-initio in case of dishonour of Cheque.</p>
    <div style="border: 1px solid black;" class="row">
        <div class="col-4">
            <h6><b>Agency Code & Name</b></h6>
        </div>
        <div class="col-6">
            <h6><b>10088065, BRIXTON INSURANCE BROKER PRIVATE
                LIMITED</b></h6>
        </div>
    </div>
    <br>
    <div style="border: 1px solid black;" class="row">
        <div class="col">
            <h6>Contact No.</h6>
        </div>
        <div class="col">
            <h6>09884128820,
                09884128820</h6>
        </div>
        <div class="col">
            <h6>E-Mail</h6>
        </div>
        <div class="col">
            <h6>INFO@POLICYINSURE.COM</h6>
        </div>
    </div>
    <p>For & on the behalf</p>
    <p><b>Bajaj Allianz General Insurance Company Ltd.</b></p>
    <img src="assets/images/signature.svg">
    <p style="font-size: 12px;">Authorized Signatory</p>
    <br>
    <br>
    <br>
    <br>
    <p><b>Consolidated Stamp Duty as agreed, paid towards Insurance Stamps vide Challan No. MH013770432201920M Defaced No. 0000433117202021 Order No.CSD/22/2020/1432/2020
        Order Dated 09.06.2020Defaced date dated 09-JUN-20 timing 12:23:03 of General Stamp Office,Mumbai,India.
        </b></p>
    <br>
    <p>Principal Location : 497/498, 5th floor, Isana Kattima building, Poonamallee High Road, Arumbakkam, Chennai - 600106 PH:044-43904400 | Services
        Accounting Code : 997135 - Marine, aviation, and other transport insurance services. No reverse charge is payable on these services.</p>
    <p style="text-align: right;">Schedule (1) | Printed on : 12-Jan-2021 04:37:37|10088065@general.bajajallianz.co.in|WEB|NA</p>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <hr>
    <div class="row">
        <div class="col">
            <p>For help and more information:</p>
        </div>
        <div style="text-align: right;" class="col">
            <p>Page 2 of 2</p>
        </div>
    </div>
    <p><b>Contact our 24 Hour Call Centre at 1800-209-5858, 1800-102-5858</b></p>
    <div class="row">
        <div class="col">
            Email: Bagichelp@bajajallianz.co.in , Website www.bajajallianz.com
        </div>
        <div style="text-align: right;" class="col">
            <b >Corporate Identification Number: U66010PN2000PLC015329</b>
        </div>
    </div>
    <p><b>Demystify Insurance</b>  http://support.bajajallianz.com   http://www.facebook.com/BajajAllianz   http://twitter.com/BajajAllianz   www.bit.do/bjazgi</p>
</div>
</div>





<div class="container" style="border: 1px solid black;">
    <div class="container" id="receipt" style="padding: 20px 40px;">
        <div style="margin-bottom: 20px;" class="row">
            <div class="col">
                <img style="width: 50%;" src="assets/images/receipt.png">
            </div>
            <div class="col" style="text-align: right;">
               <b>TENACIOUS TECHNOLOGY PRIVATE LIMITED</b> <br>
                NEW NO.101,<br>
                ST. XAVIER STREET SEVEN WELLS,<br>
                BROADWAY, CHENNAI – 600001<br>
                PAN: AAECG3660R<br>
                GSTIN: 33AAECG3660R2Z7
            </div>
        </div>
        <div style="border-bottom: 1px solid black;min-width: 100%;margin-bottom: 30px;"></div>
        <h2 style="color: #05376C;text-align: center;margin-bottom: 20px;">Tax Invoice cum Receipt</h2>
        <div class="row" style="margin-bottom: 20px;">
            <div class="col">
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>Bill To</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        {{ name }}
                    </div>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>Address</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        {{ address }}
                    </div>
                </div>
                <!-- <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>Pincode</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        {{ pinCode }}
                    </div>
                </div> -->
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>GSTIN No.</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        {{ gstUin }}
                    </div>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>Insured Name</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        {{ insuredName }}
                    </div>
                </div>
                <!-- <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>Seller/Consignor</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        N/A
                    </div>
                </div> -->
            </div>
            <div class="col">
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>Invoice No</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        {{ invoiceNo }}
                    </div>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>Invoice Date</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        {{ issueDate }}
                    </div>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>Certificate No</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        {{ certificateNo }}
                    </div>
                </div>
                <!-- <div class="row" style="margin-bottom: 10px;">
                    <div class="col-4">
                        <b>Reference No</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        N/A
                    </div>
                </div> -->
            </div>
        </div>
        <table class="table-bordered" style="width: 100%;">
            <thead>
                <tr style="text-align: center;">
                    <th>Description</th>
                    <th>SAC Code</th>
                    <th>GST %</th>
                    <th>Amount</th>
                    <th>SGST</th>
                    <th>CGST</th>
                    <th>IGST</th>
                    <th>Total Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Insurance Charges/VAS*</td>
                    <td style="text-align: center;">997169</td>
                    <td style="text-align: center;">18%</td>
                    <td style="text-align: center;">{{ netPremium2 }}</td>
                    <td style="text-align: center;">{{ sgst }}</td>
                    <td style="text-align: center;">{{ cgst }}</td>
                    <td style="text-align: center;">0.00</td>
                    <td style="text-align: center;">{{ grossPremium2 }}</td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td style="text-align: center;">{{ netPremium2 }}</td>
                    <td style="text-align: center;">{{ sgst }}</td>
                    <td style="text-align: center;">{{ cgst }}</td>
                    <td style="text-align: center;">0.00</td>
                    <td style="text-align: center;">{{ grossPremium2 }}</td>
                </tr>
                <tr>
                    <td colspan="7">Rounding</td>
                    <td style="text-align: center;">0.00</td>
                </tr>
                <tr>
                    <td colspan="7" style="text-align: center;"><b>Net Payable (INR)</b></td>
                    <td style="text-align: center;"><b>{{ grossPremium2 }}</b></td>
                </tr>
            </tbody>
        </table>
        <p style="font-size: 12px;">Technology and other charges,if applicable</p>
        <p><b>Amount in words:</b> </p>
        <div class="row" style="margin-bottom: 15%;">
            <div class="col">
                <b>Notes</b>
                <p>1) Terms and Condition as per quotation</p>
                <p>2) Subject to jurisdiction of the Court of Madras</p>
            </div>
            <div class="col">
                <b>Bank Detail</b>
                <div class="row">
                    <div class="col-4">
                        <b>Name</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        TENACIOUS TECHNOLOGY PVT LTD
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <b>Bank Name</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        SBI SANTHOME BRANCH
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <b>Account No.</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        36888923920
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <b>IFSC</b>
                    </div>
                    <div class="col-1">
                        :
                    </div>
                    <div class="col">
                        SBIN0005797
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 10%;">
            <div class="col">
    
            </div>
            <div class="col" style="padding-left: 240px;">
                FOR TENACIOUS TECHNOLOGY PRIVATE LIMITED <br>
                Digitally Signed <br>
                Date:{{ issueDate }} <br>
                Location: Chennai <br>
                Authorized Signatory
            </div>
        </div>
    </div>
</div>
<div class="container" style="border: 1px solid black;">
    <div class="container" id="receipt2" style="padding: 20px 40px;height: 170vh;">
        <div style="margin-bottom: 20px;" class="row">
            <div class="col">
                <img style="width: 50%;" src="assets/images/receipt.png">
            </div>
            <div class="col" style="text-align: right;">
               <b>TENACIOUS TECHNOLOGY PRIVATE LIMITED</b> <br>
                NEW NO.101,<br>
                ST. XAVIER STREET SEVEN WELLS,<br>
                BROADWAY, CHENNAI – 600001<br>
                PAN: AAECG3660R<br>
                GSTIN: 33AAECG3660R2Z7
            </div>
        </div>
        <div style="border-bottom: 1px solid black;min-width: 100%;margin-bottom: 30px;"></div>
        <h2 style="color: #05376C;text-align: center;margin-bottom: 20px;">Annexure</h2>
        <div *ngIf="policyType=='Container'">
            <p><b>Container Details</b></p>
            <table class="table-bordered" style="width: 100%;">
                <thead>
                    <tr style="font-weight: bold;">
                        <td>Container No./ BL No./ Booking No.</td>
                        <td style="text-align: center;">Container Size</td>
                        <td style="text-align: center;">Value of Container (in INR)</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let container of containers">
                        <td>{{container.number?container.number:"N/A"}}</td>
                        <td style="text-align: center;">{{ container.size }}</td>
                        <td style="text-align: center;">{{ container.value }}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><b>Sum Insured</b></td>
                        <td style="text-align: center;">{{ sumInsured }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="policyType=='Inland'">
            <p style="font-weight: bold;">Consignment Details</p>
            <table style="width: 100%;">
                <th style="border: 1px solid black;">Cargo Details</th>
                <th style="border: 1px solid black;">Packaging Details</th>
                <th style="border: 1px solid black;">SI(in FC)</th>
                <th style="border: 1px solid black;">Si(in Rs)</th>
                <tr style="border: 1px solid black;">
                    <td style="border: 1px solid black;">
                        {{ cargoDetails }}
                    </td>
                    <td style="border: 1px solid black;">
                        As Per Invoice
                    </td>
                    <td style="border: 1px solid black;">
                        
                    </td>
                    <td style="border: 1px solid black;">
                        {{ sumInsured  }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div style="margin-bottom: auto;display: none;">Dummy Div</div>
</div>