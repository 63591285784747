import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators,FormArray  } from '@angular/forms';
import { ApiService } from '../api.service';
import { NgbPanelChangeEvent, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

  @ViewChild('acc', {static : true}) accordion: NgbAccordion;

  currentStep="static-1"
  // BASIc DETAILS
  productCode="1002-Marine Cargo-Inland";
  policyType;


  // CUSTOMER DETAILS
  instituition:any={
    instituitionName:null,
    emailAddress:null,
    areaPostOffice:null,
    buildingAddress:null,
    customerName:null,
    mobileNumber:null,
    gstinUin:null,
    pan:null,
    cityState:null,
    pincode:null  
  }
  person:any={
    firstName:null,
    lastName:null,
    dob:null,
    buildingAddress:null,
    pan:null,
    mobileNumber:null,
    gstinUin:null,
    areaPostOffice:null,
    emailAddress:null,
    cityState:null,
    gender:null,
    pincode:null
  }

  cityState;

  // RISK DETAILS
  riskCategory;
  packingType;
  cargoDescription;

  // TRANSIT DETAILS
  typeOfTransit="Inland";
  modeOftransit;
  coverageOfWar="+SRCC";
  coverage="--Select coverage--";
  coverageStartDate;
  country="INDIA";
  transitFrom;
  transitTo;
  lrNumber;
  lrDate;
  transporterDetails;
  transporterName;
  containerMovement;

  // INVOICE DETAILS
  invoicePhoto;
  invoiceCurrency="INR";
  rateOfExchange=1;
  agent="Bajaj Allianz General Insurance Company";
  invoiceNumber;
  invoiceAmount:number=0;
  basisOfValuation="INVOICE";
  sumInsured:number=0;
  invoiceDate;
  freight:number=0;
  extra:number=0;
  duty:number=0;
  insuranceCharges:number=0;
  invoiceGst;

  basicDetails: FormGroup;
  riskDetails: FormGroup;
  instituitionDetails: FormGroup;
  personDetails: FormGroup;
  transitDetails: FormGroup;
  invoiceDetails: FormGroup;
  

  userType=2;
  token;
  commodity;
  userId;
  transactionId;
  _id;
  invoiceUrl;

  basicCompleted=false;
  instiCompleted=false;
  personCompleted=false;
  customerCompleted=false;
  riskCompleted=false;
  transitCompleted=false;
  invoiceCompleted=false;

  userRes;
  transitRes;
  invoiceRes;
  uploadRes;

  userLoading=false;
  transitLoading=false;
  invoiceLoading=false;

  container=false;
  containerCustomerCompleted=false;
  containerRiskCompleted=false;
  containerTransitCompleted=false;
  containerDmvCompleted=false;
  containerCustomerDetails: FormGroup;
  containerRiskDetails: FormGroup;
  containerTransitDetails: FormGroup;
  containerDmvDetails: FormGroup;
  containersForm: FormGroup

  containerDetails={
    companyName:null,
    buildingAddress:null,
    gstinUin:'N/A',
    emailAddress:'N/A',
    contactPersonName:null,
    pan:null,
    mobileNumber:null
  }
  shippingLine;
  markUp;
  bookingNo=null;
 
  finalPremium;
  marineRate="8%";
  containerBov="DMV"
  
 containerArray=[
    
  ]
  response;

  containerSizeArray=[]
  containers
  currentDate = new Date().toISOString().slice(0,10);

  city;
  state;
  areaArray=[];
  extratrue=false;
  excessAmount;
  excessPercentage;
  clauseRes;
  constructor(public service:ApiService,
    public router: Router,
    public route: ActivatedRoute,
    public http:HttpClient) { }
  
  showStep(step){
    console.log("clcikerrrrr    "+step);
    this.accordion.toggle('static-'+step);
    // this.currentStep="static-"+step;
  }
  onMovementChange(e){
    if(e.target.value==1){
      this.marineRate="0.04%"
      console.log(this.marineRate);
      
    }else{
      this.marineRate="0.08%"
      console.log(this.marineRate);

    }
  }
    onExtraChange(e){
      if(e.target.value>10){
        this.extratrue=true;
      }else{
        this.extratrue=false;
      }
    }
  onInvoice(){
    this.container = false;
    
  }
  onContainer(){
    this.container = true;
  }
  getContainerArr() {
	  return (this.containerRiskDetails.get('containerNo') as FormArray).controls;
	}
  onPincodeChange(e){
    console.log(e.target.value);
    var pin=e.target.value
    if(pin.length==6){
      this.http.get("https://api.postalpincode.in/pincode/"+pin).subscribe(data=>{
        console.log(data);
        this.areaArray=[]
        this.city=data[0].PostOffice[0].District;
        this.state=data[0].PostOffice[0].State;
        for(let i=0;i<data[0].PostOffice.length;i++){
          this.areaArray.push(data[0].PostOffice[i].Name);
        }
        this.instituition.cityState=this.city+','+this.state;
        this.person.cityState=this.city+','+this.state;
        console.log(this.person.cityState);
        
      })
    }
  }
  addContData(){
    for(let i=0;i<this.containers.length;i++){
      ( < FormArray > this.containerRiskDetails.get('containerNo')).push(new FormControl(this.containers[i].number));
      ( < FormArray > this.containerRiskDetails.get('containerSize')).push(new FormControl(this.containers[i].size, [Validators.required]));
      ( < FormArray > this.containerRiskDetails.get('containerValue')).push(new FormControl(this.containers[i].value, [Validators.required]));
      this.containerArray.push({
        number:this.containers[i].number,
        size:this.containers[i].size,
        value:this.containers[i].value
      });
    }
  }
  addNewRow(){
    if(( < FormArray > this.containerRiskDetails.get('containerNo')).length<=6){

      ( < FormArray > this.containerRiskDetails.get('containerNo')).push(new FormControl(null));
      ( < FormArray > this.containerRiskDetails.get('containerSize')).push(new FormControl(null, [Validators.required]));
      ( < FormArray > this.containerRiskDetails.get('containerValue')).push(new FormControl(null, [Validators.required]));
      this.containerArray.push({
        
        size:null,
        value:null
      });
    }
  }
  deleterow(i){
    if(( < FormArray > this.containerRiskDetails.get('containerNo')).length>1){
      ( < FormArray > this.containerRiskDetails.get('containerNo')).removeAt(i);
      ( < FormArray > this.containerRiskDetails.get('containerSize')).removeAt(i);
      ( < FormArray > this.containerRiskDetails.get('containerValue')).removeAt(i);
      this.containerArray.pop();
    }

  }
  getDetails(){
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.getUserDetails(this.transactionId,headers).subscribe(data=>{
      this.response=data;
      console.log(data);
      this.productCode=this.response.data[0].productCode;
      this.policyType=this.response.data[0].policyType;
      this._id=this.response.data[0].userId;
      if(this.response.data[0].user.userType==2){
        this.instituition.instituitionName=this.response.data[0].user.name;
        this.instituition.emailAddress=this.response.data[0].user.email;
        this.instituition.mobileNumber=this.response.data[0].user.mobileNo;
        this.instituition.buildingAddress=this.response.data[0].user.address;
        this.instituition.gstinUin=this.response.data[0].user.gstinUin;
        this.instituition.pan=this.response.data[0].user.panNo;
        this.instituition.customerName=this.response.data[0].user.representativeName;
        this.instituition.areaPostOffice=this.response.data[0].user.areaPostOffice;
        this.instituition.cityState=this.response.data[0].user.state;
        this.instituition.pincode=this.response.data[0].user.pincode;
        if(this.policyType!="Container"){

          this.http.get("https://api.postalpincode.in/pincode/"+this.instituition.pincode).subscribe(data=>{
            console.log(data);
            this.areaArray=[]
            this.city=data[0].PostOffice[0].District;
            this.state=data[0].PostOffice[0].State;
            for(let i=0;i<data[0].PostOffice.length;i++){
              this.areaArray.push(data[0].PostOffice[i].Name);
            }
            this.instituition.cityState=this.city+','+this.state;
            this.person.cityState=this.city+','+this.state;
            console.log(this.person.cityState);
            
          })
        }
        console.log(this.instituition.areaPostOffice);
        
      }
      if(this.response.data[0].user.userType==1){
        this.person.firstName=this.response.data[0].user.name
        this.person.gender=this.response.data[0].user.gender
        this.person.lastName=this.response.data[0].user.lastName
        this.person.dob=this.response.data[0].user.dob
        this.person.gstinUin=this.response.data[0].user.gstinUin;
        this.person.emailAddress=this.response.data[0].user.email;
        this.person.mobileNumber=this.response.data[0].user.mobileNo;
        this.person.pan=this.response.data[0].user.panNo;
        this.person.areaPostOffice=this.response.data[0].user.areaPostOffice;
        this.person.cityState=this.response.data[0].user.state;
        this.person.pincode=this.response.data[0].user.pincode;
        this.http.get("https://api.postalpincode.in/pincode/"+this.person.pincode).subscribe(data=>{
        console.log(data);
        this.areaArray=[]
        this.city=data[0].PostOffice[0].District;
        this.state=data[0].PostOffice[0].State;
        for(let i=0;i<data[0].PostOffice.length;i++){
          this.areaArray.push(data[0].PostOffice[i].Name);
        }
        this.instituition.cityState=this.city+','+this.state;
        this.person.cityState=this.city+','+this.state;
        console.log(this.person.cityState);
        
      })
        this.person.buildingAddress=this.response.data[0].user.address;
      }
      if(this.policyType=='Inland'){
      
      this.riskCategory=this.response.data[0].riskCategory;
      this.packingType=this.response.data[0].packing;
      this.cargoDescription=this.response.data[0].cargoDescription;
      this.typeOfTransit=this.response.data[0].typeOfTransit;
      this.modeOftransit=this.response.data[0].modeOfTransit;
      this.coverageOfWar=this.response.data[0].coverageOfWarorStrikes;
      this.coverage=this.response.data[0].coverage;
      this.coverageStartDate=this.response.data[0].coverageStartDate;
      this.country=this.response.data[0].fromCountry;
      this.transitFrom=this.response.data[0].coverageFrom;
      this.transitTo=this.response.data[0].coverageEnds;
      this.lrNumber=this.response.data[0].transportationDetails.lrNo;
      this.lrDate=this.response.data[0].transportationDetails.lrDate;
      this.transporterName=this.response.data[0].transportationDetails.transporterName;
      this.transporterDetails=this.response.data[0].transportationDetails.transporterDetails;
      this.invoiceNumber=this.response.data[0].invoice.invoiceNo;
      this.invoiceDate=this.response.data[0].invoice.date;
      this.invoiceCurrency=this.response.data[0].invoice.currency;
      this.invoiceAmount=this.response.data[0].invoice.amount;
      this.freight=this.response.data[0].invoice.freight;
      this.extra=this.response.data[0].invoice.extraPerc;
      this.rateOfExchange=this.response.data[0].invoice.rateOfExchange;
      this.basisOfValuation=this.response.data[0].bov;
      this.agent="Bajaj Allianz General Insurance Company";
      this.invoiceUrl=this.response.data[0].invoice.invoiceUpload;
      this.userId=this.response.data[0]._id;
      this.invoiceGst=this.response.data[0].invoice.invoiceGst;
      this.duty=this.response.data[0].invoice.duty;
      this.insuranceCharges=this.response.data[0].invoice.insuranceCharges;
      this.userType=this.response.data[0].user.userType;
      this.sumInsured=this.invoiceAmount+this.extra+this.freight;this.duty+this.insuranceCharges;
      this.onInvoice();
    }else{
      console.log("yessss containerrrr");
      
      this.containerDetails.contactPersonName=this.response.data[0].user.representativeName;
      this.riskCategory=this.response.data[0].riskCategory;
      this.typeOfTransit=this.response.data[0].typeOfTransit;
      this.modeOftransit=this.response.data[0].modeOfTransit;
      this.coverage=this.response.data[0].coverage;
      this.coverageStartDate=this.response.data[0].coverageStartDate;
      this.transitFrom=this.response.data[0].coverageFrom;
      this.transitTo=this.response.data[0].coverageEnds;
      this.containers=this.response.data[0].container;
      this.containerDetails.companyName=this.response.data[0].user.name;
      this.containerDetails.emailAddress=this.response.data[0].user.email;
      this.containerDetails.mobileNumber=this.response.data[0].user.mobileNo;
      this.containerDetails.buildingAddress=this.response.data[0].user.address;
      this.containerDetails.gstinUin=this.response.data[0].user.gstinUin;
      this.containerDetails.pan=this.response.data[0].user.panNo;
      this.shippingLine=this.response.data[0].shippingLine;
      this.markUp=this.response.data[0].markup;
      this.bookingNo=this.response.data[0].bookingNo;
      if(this.response.data[0].transportationType=='1'){
        this.containerMovement="1";
        this.marineRate="0.04%"
      }else{
        this.containerMovement="2";
        this.marineRate="0.08%"
      }
      console.log(this.riskCategory);
      
      // this.getContData();
      this.addContData();
      for(let i=0;i<this.containers.length;i++){
        console.log(this.sumInsured, "SI");
        
        if(this.response.data[0].transportationType=='2'){
          this.sumInsured+=parseInt(this.containers[i].value)*2;
          }else{
            this.sumInsured+=parseInt(this.containers[i].value);
          }
        console.log(parseInt(this.containers[i].value));
        
        
      }
      if(this.riskCategory=='COM10'){
        this.containerSizeArray=[
          '20 Feet',
          '40 Feet',
          '45 Feet',
        ]
      }else if(this.riskCategory=='COM24'){
        this.containerSizeArray=[
          '20 Feet',
        ]
      }else if(this.riskCategory=='COM11'){
        this.containerSizeArray=[
          '20 Feet',
          '40 Feet'
        ]
      }
      this.userId=this.response.data[0]._id;
      this.onContainer();
      console.log(this.sumInsured,"SI");

      
    }

      
    },err=>{
      alert(err.error.error);
    })
  }
  getClauses(){
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.getCommidityClause(this.transactionId,headers).subscribe(async (data:any)=>{
      this.clauseRes=await data;
      this.excessAmount=this.clauseRes?.data.commodity.excessAmt;
      this.excessPercentage=this.clauseRes?.data.commodity.excessPerc;
      console.log(data);
      
      
    })
  }

  ngOnInit(){
    
    
    this.route.params.subscribe(params => {
      this.transactionId=params.txnid;
      this.userId=params.userid;
  });

    this.token=window.localStorage.getItem("token");

    
   
    
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.getCommodity(headers).subscribe(data=>{
      this.commodity=data;
    },err=>{
      console.log(err);
    })

    

    this.basicDetails= new FormGroup({
      policyType:new FormControl(this.policyType,Validators.required),
      productCode:new FormControl(null,Validators.required)
    })

    this.instituitionDetails= new FormGroup({
      instituitionName:new FormControl(null,Validators.required),
      emailAddress:new FormControl(null),
      areaPostOffice:new FormControl(null,Validators.required),
      buildingAddress:new FormControl(null,Validators.required),
      customerName:new FormControl(null,Validators.required),
      mobileNumber:new FormControl(null,Validators.required),
      gstinUin:new FormControl(null),
      pan:new FormControl(null),
      cityState:new FormControl(null,Validators.required),
      pincode:new FormControl(null,Validators.required)
    })

    this.personDetails= new FormGroup({
      firstName:new FormControl(null,Validators.required),
      lastName:new FormControl(null,Validators.required),
      gender:new FormControl(null,Validators.required),
      dob:new FormControl(null,Validators.required),
      emailAddress:new FormControl(null),
      mobileNumber:new FormControl(null,Validators.required),
      gstinUin:new FormControl(null),
      pan:new FormControl(null),
      cityState:new FormControl(null,Validators.required),
      buildingAddress:new FormControl(null,Validators.required),
      areaPostOffice:new FormControl(null,Validators.required),
      pincode:new FormControl(null,Validators.required)
    })

    this.containerCustomerDetails=new FormGroup({
      companyName:new FormControl(null,Validators.required),
      buildingAddress:new FormControl(null,Validators.required),
      gstinUin:new FormControl(null),
      emailAddress:new FormControl(null),
      contactPersonName:new FormControl(null,Validators.required),
      pan:new FormControl(null),
      mobileNumber:new FormControl(null,Validators.required),
    })

    
    this.containerRiskDetails=new FormGroup({
      riskCategory:new FormControl(null,Validators.required),
      containerNo:new FormArray([
        
      ]),
      containerSize:new FormArray([
        
      ]),
      containerValue:new FormArray([
        
      ])
    });

    this.containerTransitDetails=new FormGroup({
      typeOfTransit:new FormControl(null,Validators.required),
      modeOftransit:new FormControl('Road',Validators.required),
      coverage:new FormControl(null,Validators.required),
      coverageStartDate:new FormControl(null,Validators.required),
      country:new FormControl(null,Validators.required),
      transitFrom:new FormControl(null,Validators.required),
      transitTo:new FormControl(null,Validators.required),
      shippingLine:new FormControl(null,Validators.required),
      markUp:new FormControl(null,Validators.required),
      bookingNo:new FormControl(null),
      containerMovement:new FormControl(null,Validators.required)
    })

    this.containerDmvDetails=new FormGroup({
      basisOfValuation:new FormControl(null,Validators.required),
      marineRate:new FormControl(null,Validators.required),
      sumInsured:new FormControl(null,Validators.required),
    })


    this.riskDetails=new FormGroup({
      riskCategory:new FormControl(null,Validators.required),
      packingType:new FormControl(null,Validators.required),
      cargoDescription:new FormControl(null,Validators.required),
    })

    this.transitDetails=new FormGroup({
      typeOfTransit:new FormControl(null,Validators.required),
      modeOftransit:new FormControl('Road',Validators.required),
      coverageOfWar:new FormControl(null,Validators.required),
      coverage:new FormControl(null,Validators.required),
      coverageStartDate:new FormControl(null,Validators.required),
      country:new FormControl(null,Validators.required),
      transitFrom:new FormControl(null,Validators.required),
      transitTo:new FormControl(null,Validators.required),
      lrNumber:new FormControl(null),
      lrDate:new FormControl(null),
      transporterName:new FormControl(null,Validators.required),
      transporterDetails:new FormControl(null,Validators.required)
    })

    this.invoiceDetails=new FormGroup({
      invoiceNumber:new FormControl(null,Validators.required),
      invoiceDate:new FormControl(null,Validators.required),
      invoiceCurrency:new FormControl(null,Validators.required),
      invoiceAmount:new FormControl(null,Validators.required),
      freight:new FormControl(0),
      rateOfExchange:new FormControl(1),
      basisOfValuation:new FormControl(null,Validators.required),
      agent:new FormControl(null),
      sumInsured:new FormControl(null),
      extra:new FormControl(null,Validators.required),
      duty:new FormControl(0,Validators.required),
      insuranceCharges:new FormControl(0),
      invoiceGst:new FormControl(null),
      excessAmount:new FormControl(null),
      excessPercentage:new FormControl(null),
    })
  
    
    this.getDetails();
    this.getClauses();
  }

  ifInstituition(){
    this.userType=2;    
  }
  ifPerson(){
    this.userType=1;
  }
  onContainerRiskCategory(e){
    
    if(e.target.value=='COM10'){
      this.containerSizeArray=[
        '20 Feet',
        '40 Feet',
        '45 Feet',
      ]
    }else if(e.target.value=='COM24'){
      this.containerSizeArray=[
        '20 Feet',
      ]
    }else if(e.target.value=='COM11'){
      this.containerSizeArray=[
        '20 Feet',
        '40 Feet'
      ]
    }
    
  }
  onBasicSubmit(){    
    if(this.basicDetails.status=="VALID"){
      this.basicCompleted=true;
      this.accordion.toggle('static-2');
    }else{
      alert("Enter all the details");
    }
  }

  onUserSubmit(){    
    if(this.userType==2){
            if(this.instituitionDetails.status=="VALID"){
        this.userLoading=true;
        const formData={};
        formData["id"] = this.userId;
      formData["userType"] = this.userType;
      formData["name"] = this.instituitionDetails.controls.instituitionName.value;
      formData["representativeName"] = this.instituitionDetails.controls.customerName.value;
      formData["mobileNo"] = this.instituitionDetails.controls.mobileNumber.value;
      formData["areaPostOffice"] = this.instituitionDetails.controls.areaPostOffice.value;
      formData["pincode"] = this.instituitionDetails.controls.pincode.value;
      formData["state"] = this.instituitionDetails.controls.cityState.value;
      formData["address"] = this.instituitionDetails.controls.buildingAddress.value;
      if(this.instituition.gstinUin!=null){
        formData["gstinUin"] = this.instituitionDetails.controls.gstinUin.value;
      }
      if(this.instituition.pan!=null){
        formData["panNo"] = this.instituitionDetails.controls.pan.value;
      }
      if(this.instituition.emailAddress!=null){
        formData["email"] = this.instituitionDetails.controls.emailAddress.value;
      }
      
      const headers = { 'Authorization':'Bearer '+this.token}
      this.service.sendUserDetails(formData,headers).subscribe(data=>{
        this.userRes=data;
        this._id=this.userRes.data._id;
        this.customerCompleted=true;
        this.accordion.toggle('static-3');
        this.userLoading=false;
      },err=>{
        alert(err.error);
        this.userLoading=false;
      })
      }else{
        alert("Enter all the required details")
        console.log(this.instituitionDetails);
        
        console.log(this.instituitionDetails.errors);
        
      }
    }else{
      console.log("entered user 1");
      console.log(this.personDetails);
      
      
      if(this.personDetails.status=="VALID"){
        this.userLoading=true;
        const formData={};
        formData["id"] = this.userId;
      formData["userType"] = this.userType;
      formData["areaPostOffice"] = this.personDetails.controls.areaPostOffice.value;
      formData["dob"] = this.personDetails.controls.dob.value;
      formData["gender"] = this.personDetails.controls.gender.value;
      formData["mobileNo"] = this.personDetails.controls.mobileNumber.value;
      formData["pincode"] = this.personDetails.controls.pincode.value;
      formData["state"] = this.personDetails.controls.cityState.value;
      formData["address"] = this.personDetails.controls.buildingAddress.value;
      formData["lastName"] = this.personDetails.controls.lastName.value;
      formData["name"] = this.personDetails.controls.firstName.value;
      if(this.person.gstinUin!=null){
        formData["gstinUin"] = this.personDetails.controls.gstinUin.value;
      }
      if(this.person.pan!=null){
        formData["panNo"] = this.personDetails.controls.pan.value;
      }
      if(this.person.emailAddress!=null){
        formData["email"] = this.personDetails.controls.emailAddress.value;
      }
      
      const headers = { 'Authorization':'Bearer '+this.token}
      this.service.sendUserDetails(formData,headers).subscribe(data=>{
        this.userRes=data;
        this._id=this.userRes.data._id;
        this.customerCompleted=true;
        this.accordion.toggle('static-3');
        this.userLoading=false;
      },err=>{
        alert(err.error);
        this.userLoading=false;
      })
      }else{
        alert("Enter all the required details")
      }
    }
  }
  onContainerCustomerSubmit(){
        if(this.containerCustomerDetails.status=="VALID"){
          this.userLoading=true;
          const formData={};
        formData["userType"] = 2;
        formData["id"] = this.userId;
        formData["name"] = this.containerCustomerDetails.controls.companyName.value;
        formData["mobileNo"] = this.containerCustomerDetails.controls.mobileNumber.value;
        formData["address"] = this.containerCustomerDetails.controls.buildingAddress.value;
        formData["representativeName"] = this.containerCustomerDetails.controls.contactPersonName.value;
        if(this.containerDetails.emailAddress!=null){
          formData["email"] = this.containerCustomerDetails.controls.emailAddress.value;
        }
        if(this.containerDetails.gstinUin!=null){
          formData["gstinUin"] = this.containerCustomerDetails.controls.gstinUin.value;
        }
        // formData["panNo"] = this.containerCustomerDetails.controls.pan.value;
        // formData["areaPostOffice"] = this.instituitionDetails.controls.areaPostOffice.value;
        // formData["pincode"] = this.instituitionDetails.controls.pincode.value;
        // formData["state"] = this.instituitionDetails.controls.cityState.value;
        
        const headers = { 'Authorization':'Bearer '+this.token}
        this.service.sendUserDetails(formData,headers).subscribe(data=>{
          this.userRes=data;
          this._id=this.userRes.data._id;
          this.containerCustomerCompleted=true;
          this.accordion.toggle('static-3');
          this.userLoading=false;
        },err=>{
          alert(err.error);
          this.userLoading=false;
        })
        }else{
          alert("Enter all the required details")
          console.log(this.instituitionDetails);
          
          console.log(this.instituitionDetails.errors);
          
        }
  }

  onRiskSubmit(){    
    if(this.riskDetails.status=="VALID"){
      this.riskCompleted=true;
      this.accordion.toggle('static-4');
    }else{
      alert("Enter all the details");
    }
  }

  onContainerRiskSubmit(){
    var cnosame=false;
    if(this.containerRiskDetails.status=="VALID"){
      this.sumInsured=0;
      for(let i=0;i<( < FormArray > this.containerRiskDetails.get('containerNo')).length;i++ ){
        if(( < FormArray > this.containerRiskDetails.get('containerNo')).value[i]!="" && ( < FormArray > this.containerRiskDetails.get('containerNo')).value[i]!=null){
          console.log(( < FormArray > this.containerRiskDetails.get('containerNo')).value[i]);
          
          this.containerArray[i]["number"]=( < FormArray > this.containerRiskDetails.get('containerNo')).value[i]
        }
        this.containerArray[i].size=( < FormArray > this.containerRiskDetails.get('containerSize')).value[i]
        this.containerArray[i].value=( < FormArray > this.containerRiskDetails.get('containerValue')).value[i]

        this.sumInsured+=parseInt(( < FormArray > this.containerRiskDetails.get('containerValue')).value[i]);
        
      }
      var abort=false;
      for(let i=0;i<this.containerArray.length && abort==false;i++){
        for(let k=0;k<this.containerArray.length;k++){
            if(this.containerArray[i]["number"]==this.containerArray[k]["number"] && i!=k){
              alert("Container Number cannot be same.");
              abort=true;
              break;
            }
        }
      }

      if(cnosame==false){
        this.containerRiskCompleted=true;
        this.accordion.toggle('static-4');
      }
    }else{
      console.log(this.containerRiskDetails);
      
      alert("Enter all the required details")
    }
  }

  onTransitSubmit(){
    this.transitLoading=true;  
    const formData={
      transportationDetails:{}
    };
    formData["userId"] = this._id;
    formData["transactionId"] = this.transactionId;
    formData["productCode"] = "1002 inland transit";
    formData["policyType"] = this.basicDetails.controls.policyType.value;
    formData["riskCategory"] = this.riskDetails.controls.riskCategory.value;
    formData["packing"] = this.riskDetails.controls.packingType.value;
    formData["cargoDescription"] = this.riskDetails.controls.cargoDescription.value;
    formData["typeOfTransit"] = "INLAND";
    formData["modeOfTransit"] = "ROAD";
    formData["coverageOfWarorStrikes"] = this.transitDetails.controls.coverageOfWar.value;
    formData["coverage"] = this.transitDetails.controls.coverage.value;
    formData["coverageStartDate"] = this.transitDetails.controls.coverageStartDate.value;
    formData["fromCountry"] = "India";
    formData["toCountry"] = "India";
    formData["coverageFrom"] = this.transitDetails.controls.transitFrom.value;
    formData["coverageEnds"] = this.transitDetails.controls.transitTo.value;
    formData["bov"] = "INVOICE";
    if(this.lrNumber!=null){
      formData.transportationDetails["lrNo"] = this.transitDetails.controls.lrNumber.value;
    }
    if(this.lrDate!=null){
      formData.transportationDetails["lrDate"] = this.transitDetails.controls.lrDate.value;
    }
    formData.transportationDetails["transporterDetails"] = this.transitDetails.controls.transporterDetails.value;
    formData.transportationDetails["transporterName"] = this.transitDetails.controls.transporterName.value;
    
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.sendTransitDetails(formData,headers).subscribe(data=>{
      this.transitRes=data;
      this.transactionId=this.transitRes.data.transactionId;
      this.policyType=this.transitRes.data.policyType;
      this.transitCompleted=true;
      this.accordion.toggle('static-5');
      this.transitLoading=false;
      console.log(data);
    },err=>{
      alert(err.error);
      this.transitLoading=false;
    })
  }
  
  onContainerTransitSubmit(){
    console.log(this.riskCategory);
    console.log(this.userId);
    console.log("value   "+  this.containerRiskDetails.controls.riskCategory.value);
    
    
    
    this.transitLoading=true;  
    const formData={
    };
    formData["userId"] = this._id;
    formData["transactionId"] = this.transactionId;
    formData["productCode"] = "1002 Inland Transit";
    formData["bov"] = "DMV";
    formData["packing"] ='PCK01'
    formData["policyType"] = this.basicDetails.controls.policyType.value;
    formData["riskCategory"] = this.riskCategory;
    formData["typeOfTransit"] = "INLAND";
    formData["modeOfTransit"] = "ROAD";
    formData["coverage"] = this.containerTransitDetails.controls.coverage.value;
    formData["coverageFrom"] = this.containerTransitDetails.controls.transitFrom.value;
    formData["coverageEnds"] = this.containerTransitDetails.controls.transitTo.value;
    formData["container"] = this.containerArray;
    formData["coverageStartDate"] = this.containerTransitDetails.controls.coverageStartDate.value;
    formData["shippingLine"] = this.containerTransitDetails.controls.shippingLine.value;
    formData["transportationType"] = this.containerTransitDetails.controls.containerMovement.value;
    if(this.bookingNo!=null){
      formData["bookingNo"] = this.containerTransitDetails.controls.bookingNo.value;
    }
    formData["markup"] = this.containerTransitDetails.controls.markUp.value;
    // formData["packing"] = this.riskDetails.controls.packingType.value;
    // formData["cargoDescription"] = this.riskDetails.controls.cargoDescription.value;
    // formData["coverageOfWarorStrikes"] = this.transitDetails.controls.coverageOfWar.value;
    // formData["coverageEndDate"] = this.transitDetails.controls.quoteValidityDate.value;
    // formData["fromCountry"] = "India";
    // formData["toCountry"] = "India";
    // formData.transportationDetails["lrNo"] = this.transitDetails.controls.lrNumber.value;
    // formData.transportationDetails["lrDate"] = this.transitDetails.controls.lrDate.value;
    // formData.transportationDetails["transporterDetails"] = this.transitDetails.controls.transporterDetails.value;
    // formData.transportationDetails["transporterName"] = this.transitDetails.controls.transporterName.value;
    
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.sendTransitDetails(formData,headers).subscribe(data=>{
      this.transitRes=data;
      this.transactionId=this.transitRes.data.transactionId;
      this.policyType=this.transitRes.data.policyType;
      this.containerTransitCompleted=true;
      this.accordion.toggle('static-5');
      this.transitLoading=false;
      console.log(data);
    },err=>{
      alert(err.error);
      this.transitLoading=false;
    })
  }

  onInvoiceSubmit(){
    if(this.invoiceDetails.status=="VALID"){
      this.invoiceLoading=true;
      const formData={};
    formData["transactionId"] = this.transactionId;
    formData["amount"] = this.invoiceDetails.controls.invoiceAmount.value;
    formData["currency"] = "INR";
    formData["rateOfExchange"] = this.invoiceDetails.controls.rateOfExchange.value;
    formData["invoiceNo"] = this.invoiceDetails.controls.invoiceNumber.value;
    formData["extraPerc"] = this.invoiceDetails.controls.extra.value;
    formData["date"] = this.invoiceDetails.controls.invoiceDate.value;
    formData["freight"] = this.invoiceDetails.controls.freight.value;
    formData["duty"] = this.invoiceDetails.controls.duty.value;
    formData["insuranceCharges"] = this.invoiceDetails.controls.insuranceCharges.value;
    formData["invoiceUpload"] = this.invoiceUrl;
    formData["invoiceGst"]=this.invoiceGst;



    console.log(this.token);
    
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.sendInvoiceDetails(formData,headers).subscribe(data=>{
      console.log(data);
      this.router.navigate(["/preview",this.policyType,this.transactionId]);
      this.invoiceLoading=false;
    },err=>{
      alert(err.error);
      this.invoiceLoading=false;
    })
    }else{
      alert("Enter all the required details")
    }
  }
  onDmvSubmit(){
    if(this.containerDmvDetails.status=="VALID"){
      
      this.router.navigate(["/preview",this.policyType,this.transactionId]);
    }else{
      alert("Enter all the required details")
    }
  }
  onInvoiceUpload(event){
    const formData=new FormData;
    formData.append('invoice',event.target.files[0]);
    const headers = { 'Authorization':'Bearer '+this.token}
    this.service.uploadInvoice(formData,headers).subscribe(data=>{
      console.log(data);
      this.uploadRes=data;
      this.invoiceUrl=this.uploadRes.data.Location;      
    },err=>{
      alert(err.error);
    })
    
  }

  calcSumInsured(){
    if(this.policyType=='Inland'){
      if(this.invoiceAmount!=null){
        this.sumInsured=this.invoiceAmount+(this.invoiceAmount*this.extra/100)+this.freight+this.insuranceCharges+this.duty;
      }
      
      console.log(this.sumInsured);
    }
    
  }
  onlogout(){
    if(this.token){
      const headers = { 'Authorization':'Bearer '+ this.token}
      
      this.service.logout(headers).subscribe(data=>{
        console.log(data);
        window.localStorage.removeItem("token");
        
        this.router.navigate(['login'])
        
      },err=>{
        alert(err.error.error);
      })

    }else{
      alert("Something went wrong...")
    }
    
  }
}
