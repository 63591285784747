<div style="background: linear-gradient(90deg, #277ADF 0%, #0C47C5 129.03%, #0C47C5 129.03%);height:30vh;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
  <div style="text-align: right;padding-top: 10px;padding-right: 10px;">
    <button (click)="onlogout()" style="color: white;" class="btn">Logout <img  src="assets/icon/logout.svg" /></button>
  </div>
  <br><br><br><h1 style="color: white;text-align: center;">POLICY ISSUANCE PORTAL</h1>
  <div class="container" style="text-align: right;">
    <img style="width: 4%;cursor: pointer;" data-toggle="modal" data-target="#exampleModal" src="assets/icon/error.png">
    <!-- <button data-toggle="modal" data-target="#exampleModal" style="width: 150px;border-color: white;background-color: white;color: #277ADF;" class='btn'>Cancel</button> -->
  </div>
</div>
<div style="position: relative;bottom: 35px;" class="container">
  <ngb-accordion #acc="ngbAccordion" animation='true'  [closeOthers]="true" [activeIds]="currentStep">
      <ngb-panel  id="static-1">
          <!-- <ng-template  ngbPanelTitle>
              <h5>Basic Details</h5>
            </ng-template> -->
            <ng-template  ngbPanelHeader let-opened="opened">
              <div style="cursor: pointer;" (click)="showStep(1)" class="d-flex align-items-center justify-content-between">
                <h5  style="padding-top: 5px;padding-bottom: 5px;color: #1A62D3;" class="m-0">Basic Details</h5>
                <div>Step 01/05 <img   src="assets/icon/ticksquare.svg" /></div>
              </div>
            </ng-template>
            <ng-template  ngbPanelContent>
          
          <form [formGroup]="basicDetails" >
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <label >Product Code</label><br>
                    <input readonly=true formControlName="productCode" [(ngModel)]="productCode" style="width: 65%;" value="1002-Marine Cargo-Inland"/>
                </div>
                <div class="col-xs-12 col-md-6">
                    <label>Policy Type</label><br>
                    <div class="radio">
                        <label (click)="onInvoice()" style="margin-right: 15px;"><input [(ngModel)]="policyType" formControlName="policyType" style="margin-right: 7px;width: fit-content;" type="radio" value="Inland"  checked>Inland</label>
                        <label (click)="onContainer()"><input [(ngModel)]="policyType" formControlName="policyType"  style="margin-right: 7px;width: fit-content;" value="Container" type="radio">Container</label>
                      </div>
                </div>
            </div>
            <div style="text-align: center;margin-top: 30px;">
              <button type="button" class="btn btn-primary" (click)="onBasicSubmit()">Proceed</button>
            </div>
          </form>
        </ng-template>
      </ngb-panel>


      <ngb-panel *ngIf="!container" id="static-2">
        <!-- <ng-template ngbPanelTitle>
          <h5>Customer Details</h5>
        </ng-template> -->
        <ng-template ngbPanelHeader let-opened="opened">
          <div style="cursor: pointer;" (click)="showStep(2)" class="d-flex align-items-center justify-content-between">
            <h5 style="padding-top: 5px;padding-bottom: 5px;color: #1A62D3;" class="m-0">Customer Details</h5>
            <div>Step 02/05 <img  src="assets/icon/ticksquare.svg" /></div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <nav ngbNav #nav="ngbNav" class="nav-tabs">
              <ng-container ngbNavItem>
                <a (click)="ifInstituition()" ngbNavLink>Institution</a>
                <ng-template ngbNavContent>
                  <form [formGroup]="instituitionDetails">
                  <div class="row">
                      <div class="col-xs-12 col-md-4">
                          <label for="">Instituition Name</label><br>
                          <input pattern="^[a-zA-Z ]*$" [(ngModel)]="instituition.instituitionName" formControlName="instituitionName" class="input"/>
                          <div style="text-align: left;width: 343px; margin-left: auto;margin-right: auto;">
                            <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="instituitionDetails.controls.instituitionName.errors && instituitionDetails.controls.instituitionName.dirty">Enter a valid Instituition Name.</div>
                          </div>

                          <label for="">Email Address</label><br>
                          <input pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" [(ngModel)]="instituition.emailAddress" formControlName="emailAddress" placeholder="sanjose@gmail.com" class="input"/><br>
                          <div style="text-align: left;width: 343px; margin-left: auto;margin-right: auto;">
                            <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="instituitionDetails.controls.emailAddress.errors && instituitionDetails.controls.emailAddress.dirty">Enter a valid Email address.</div>
                          </div>

                          <label for="">Pincode</label><br>
                          <input (input)="onPincodeChange($event)" pattern="^[1-9][0-9]{5}$" [(ngModel)]="instituition.pincode" formControlName="pincode" placeholder="600011" class="input"/>
                          <div style="text-align: left;margin-left: auto;margin-right: auto;">
                            <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="instituitionDetails.controls.pincode.errors && instituitionDetails.controls.pincode.dirty">Enter a valid Pincode.</div>
                          </div>

                          
                      </div>

                      <div class="col-xs-12 col-md-4">
                          <label for="">Customer Name</label><br>
                          <input pattern="^[a-zA-Z ]*$" [(ngModel)]="instituition.customerName" formControlName="customerName" placeholder="San Jose" class="input"/><br>
                          <div style="text-align: left;margin-left: auto;margin-right: auto;">
                            <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="instituitionDetails.controls.customerName.errors && instituitionDetails.controls.customerName.dirty">Enter a valid Customer Name.</div>
                          </div>

                          <label for="">Mobile Number</label><br>
                          <input pattern="[0-9]{10}" [(ngModel)]="instituition.mobileNumber" formControlName="mobileNumber" placeholder="10 digit number starting with +91" type="number" class="input"/><br>
                          <div style="text-align: left;margin-left: auto;margin-right: auto;">
                            <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="instituitionDetails.controls.mobileNumber.errors && instituitionDetails.controls.mobileNumber.dirty">Enter a valid Mobile number.</div>
                          </div>

                          <label for="">City and State</label><br>
                          <input readonly=true [(ngModel)]="instituition.cityState" formControlName="cityState" placeholder="Chennai, Tamil Nadu" class="input"/>
                      </div>

                      <div class="col-xs-12 col-md-4">
                          <label for="">GSTIN/UIN</label><br>
                          <input pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$" [(ngModel)]="instituition.gstinUin" formControlName="gstinUin" placeholder="07AAACC4538L1ZK" class="input"/><br>
                          <div style="text-align: left;margin-left: auto;margin-right: auto;">
                            <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="instituitionDetails.controls.gstinUin.errors && instituitionDetails.controls.gstinUin.dirty">Enter a valid GSTIN.</div>
                          </div>

                          <label for="">PAN</label><br>
                          <input pattern="[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}" [(ngModel)]="instituition.pan" formControlName="pan" placeholder="EAWKP1010L" class="input"/>
                          <div style="text-align: left;margin-left: auto;margin-right: auto;">
                            <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="instituitionDetails.controls.pan.errors && instituitionDetails.controls.pan.dirty">Enter a valid PAN no.</div>
                          </div>

                          <label for="">Area Post Office</label>
                          <br>
                          <select [(ngModel)]="instituition.areaPostOffice" formControlName="areaPostOffice" style="padding: 5px;border-radius: 5px;border: 1px solid rgb(184, 184, 184);width: 90%;margin-bottom: 10px;" id="rc">
                            <ng-container *ngIf="commodity">
                              <option  style="color: black;width: 100;overflow-x: scroll;" [value]="option"  *ngFor="let option of areaArray">{{ option }}</option>
                            </ng-container>
                          </select>

                          <label for="">Building Address</label><br>
                          <input [(ngModel)]="instituition.buildingAddress" formControlName="buildingAddress" placeholder="EAWKP1010L" class="input"/>
                      </div>
                  </div>
                  <div style="text-align: center;margin-top: 30px;">
                      <button type="button" class="btn btn-primary" (click)="onUserSubmit()"><span *ngIf="!userLoading">Proceed</span>
                        <div style="height: 25px;width: 25px;" *ngIf="userLoading" class="spinner-border text-light" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                  </div>
                </form>
                </ng-template>
              </ng-container>
              <ng-container ngbNavItem>
                <a (click)="ifPerson()" ngbNavLink>Person</a>
                <ng-template ngbNavContent>
                  <form [formGroup]="personDetails">
                  <div class="row">
                    <div class="col-xs-12 col-md-4">
                        <label for="">First Name</label><br>
                        <input pattern="^[a-zA-Z ]*$" [(ngModel)]="person.firstName" formControlName="firstName" class="input"/><br>
                        <div style="text-align: left;margin-left: auto;margin-right: auto;">
                          <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="personDetails.controls.firstName.errors && personDetails.controls.firstName.dirty">Enter a valid First Name.</div>
                        </div>

                        <label for="">Date of birth</label><br>
                        <input [(ngModel)]="person.dob" formControlName="dob" type="date" class="input"/><br>

                        <label for="">Building address</label><br>
                        <input [(ngModel)]="person.buildingAddress" formControlName="buildingAddress" class="input"/><br>

                        <label for="">PAN</label><br>
                        <input pattern="[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}" [(ngModel)]="person.pan" formControlName="pan" placeholder="EAWKP1010L" class="input"/>
                        <div style="text-align: left;margin-left: auto;margin-right: auto;">
                          <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="personDetails.controls.pan.errors && personDetails.controls.pan.dirty">Enter a valid PAN no.</div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-4">
                        <label for="">Last Name</label><br>
                        <input pattern="^[a-zA-Z ]*$" [(ngModel)]="person.lastName" formControlName="lastName" placeholder="San Jose" class="input"/><br>
                        <div style="text-align: left;margin-left: auto;margin-right: auto;">
                          <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="personDetails.controls.lastName.errors && personDetails.controls.lastName.dirty">Enter a valid Last Name.</div>
                        </div>

                        <label for="">Mobile Number</label><br>
                        <input pattern="[0-9]{10}" [(ngModel)]="person.mobileNumber" type="number" formControlName="mobileNumber" placeholder="10 digit number starting with +91" class="input"/><br>
                        <div style="text-align: left;margin-left: auto;margin-right: auto;">
                          <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="personDetails.controls.mobileNumber.errors && personDetails.controls.mobileNumber.dirty">Enter a valid Mobile number.</div>
                        </div>

                        <label for="">Area Post Office</label><br>
                            <br>
                            <select [(ngModel)]="instituition.areaPostOffice" formControlName="areaPostOffice" style="padding: 5px;border-radius: 5px;border: 1px solid rgb(184, 184, 184);width: 90%;margin-bottom: 10px;" id="rc">
                              <ng-container *ngIf="commodity">
                                <option  style="color: black;width: 100;overflow-x: scroll;" [value]="option"  *ngFor="let option of areaArray">{{ option }}</option>
                              </ng-container>
                            </select>
                        
                        <label for="">GSTIN/UIN</label><br>
                        <input pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$" [(ngModel)]="person.gstinUin" formControlName="gstinUin" placeholder="07AAACC4538L1ZK" class="input"/>
                        <div style="text-align: left;margin-left: auto;margin-right: auto;">
                          <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="personDetails.controls.gstinUin.errors && personDetails.controls.gstinUin.dirty">Enter a valid GSTIN.</div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-4">
                      <label>Gender</label><br>
                      <div class="radio">
                          <label style="margin-right: 15px;"><input [(ngModel)]="person.gender"  formControlName="gender" style="margin-right: 7px;width: fit-content;" type="radio" value="male" checked>Male</label>
                          <label><input  [(ngModel)]="person.gender" formControlName="gender" style="margin-right: 7px;width: fit-content;" value="female" type="radio" >Female</label>
                        </div>

                        <label for="">Email Address</label><br>
                        <input pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" [(ngModel)]="person.emailAddress" formControlName="emailAddress"  placeholder="sanjose@gmail.com" class="input"/><br>

                        <label for="">City and State</label><br>
                        <input readonly=true [(ngModel)]="person.cityState" formControlName="cityState" placeholder="Chennai, Tamil Nadu" class="input"/>
                        <div style="text-align: left;width: 343px; margin-left: auto;margin-right: auto;">
                          <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="personDetails.controls.emailAddress.errors && personDetails.controls.emailAddress.dirty">Enter a valid Email address.</div>
                        </div>

                        <label for="">Pincode</label><br>
                        <input pattern="^[1-9][0-9]{5}$" [(ngModel)]="person.pincode" formControlName="pincode" placeholder="600011" class="input"/>
                        <div style="text-align: left;margin-left: auto;margin-right: auto;">
                          <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="personDetails.controls.pincode.errors && personDetails.controls.pincode.dirty">Enter a valid Pincode.</div>
                        </div>
                    </div>
                </div>
                <div style="text-align: center;margin-top: 30px;">
                  <button type="button" class="btn btn-primary" (click)="onUserSubmit()"><span *ngIf="!userLoading">Proceed</span>
                    <div style="height: 25px;width: 25px;" *ngIf="userLoading" class="spinner-border text-light" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </form>
                </ng-template>
              </ng-container>
            </nav>
            
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="!container" id="static-3">
          <!-- <ng-template ngbPanelTitle>
            <h5>Risk Details</h5>
          </ng-template> -->
          <ng-template ngbPanelHeader let-opened="opened">
            <div style="cursor: pointer;" (click)="showStep(3)" class="d-flex align-items-center justify-content-between">
              <h5 style="padding-top: 5px;padding-bottom: 5px;color: #1A62D3;" class="m-0">Risk Details</h5>
              <div>Step 03/05 <img  src="assets/icon/ticksquare.svg" /></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <form [formGroup]="riskDetails">
            <div class="row">
                <div class="col-xs-12 col-md-4">
                    <label for="rc">Risk Category</label>
                    <br>
                    <select [(ngModel)]="riskCategory" formControlName="riskCategory" style="padding: 5px;border-radius: 5px;border: 1px solid rgb(184, 184, 184);width: 100%;" id="rc">
                      <ng-container *ngIf="commodity">
                        <option  style="color: black;width: 100;overflow-x: scroll;" [value]="option.commodityCode"  *ngFor="let option of commodity.data" selected>{{option.commodityDesc }}</option>
                      </ng-container>
                      
                    </select>

                </div>
                <div class="col-xs-12 col-md-4">
                  <label>Packing Type</label><br>
                  <div class="radio">
                    <label style="margin-right: 10px;"><input [(ngModel)]="packingType" formControlName="packingType" style="width: fit-content;margin-right: 5px;" type="radio" value="PCK01" checked>Standard and Customary</label>
                    <label style="color: gray;"><input disabled=true [(ngModel)]="packingType" formControlName="packingType" style="width: fit-content;margin-right: 5px;" value="PCK02" type="radio" >Box Container</label>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4">
                  <label>Cargo Description</label><br>
                  <input [(ngModel)]="cargoDescription" value="Standard and Customary" formControlName="cargoDescription" class="input" />
                </div>
              </div>
              <div style="text-align: center;margin-top: 30px;">
                <button type="button" class="btn btn-primary" (click)="onRiskSubmit()">Proceed</button>
              </div>
            </form>
          </ng-template>
        </ngb-panel>
        <ngb-panel *ngIf="!container" id="static-4">
          <!-- <ng-template ngbPanelTitle>
            <h5>Transit Details</h5>
          </ng-template> -->
          <ng-template ngbPanelHeader let-opened="opened">
            <div style="cursor: pointer;" (click)="showStep(4)" class="d-flex align-items-center justify-content-between">
              <h5 style="padding-top: 5px;padding-bottom: 5px;color: #1A62D3;" class="m-0">Transit Details</h5>
              <div>Step 04/05 <img  src="assets/icon/ticksquare.svg" /></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <form [formGroup]="transitDetails">
            <div class="row">
              <div class="col-xs-12 col-md-4">
                <div>
                  <label>Type of Transit</label><br>
                  <input readonly=true [(ngModel)]="typeOfTransit" formControlName="typeOfTransit" value="Inland" class="input"/>
                </div>
                <div>
                  <label>Coverage</label><br>
                  <select [(ngModel)]="coverage" formControlName="coverage" style="padding: 7px;border-radius: 5px;border: 1px solid rgb(184, 184, 184);margin-bottom: 20px;width: 90%;">
                    <option value="volvo"  selected>--Select coverage--</option>
                    <option value="ALL_RISK">All Risk</option>
                    <option value="BASIC_RISK">Basic Risk</option>
                    <option value="RESTRICTED_RISK">Restricted Risk</option>
                  </select>
                </div>
                <div>
                  <label>Country</label><br>
                  <input readonly=true [(ngModel)]="country" value="India" formControlName="country" class="input"/>
                </div>
                <div>
                  <label>LR Number</label><br>
                  <input type="number" [(ngModel)]="lrNumber" value="E.g: 1234444" formControlName="lrNumber" class="input"/>
                </div>
                <div>
                  <label>Transporter Details</label><br>
                  <input [(ngModel)]="transporterDetails" value="Transportation Details" formControlName="transporterDetails" class="input"/>
                </div>
              </div>

              <div class="col-xs-12 col-md-4">
                <label>Mode of Transit</label>
                <div class="row" style="margin-bottom: 28px;text-align: center;">
                  <div class="col">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#75ABD6" fill-opacity="0.34"/>
                      <path d="M31.75 13.875H23.875V12.7917C23.875 11.5951 22.8677 10.625 21.625 10.625H9.25002C8.00738 10.625 7 11.5951 7 12.7917V26.875C7 27.7725 7.75553 28.5 8.6875 28.5H10.4256C10.7334 30.2678 12.4712 31.4606 14.3071 31.1642C15.725 30.9353 16.8361 29.8654 17.0738 28.5H22.1577C22.5378 28.4929 22.9046 28.3639 23.1999 28.1333C23.4586 28.3683 23.8006 28.4994 24.1562 28.5H25.0506C25.3584 30.2678 27.0962 31.4607 28.932 31.1642C30.3499 30.9353 31.461 29.8654 31.6987 28.5H32.3125C33.1799 28.5 34 27.7103 34 26.875V16.0417C34 14.8451 32.9927 13.875 31.75 13.875ZM13.75 30.125C12.5074 30.125 11.5 29.1549 11.5 27.9583C11.5 26.7617 12.5074 25.7916 13.75 25.7916C14.9926 25.7916 16 26.7617 16 27.9583C16 29.1549 14.9926 30.125 13.75 30.125ZM22.75 26.9016C22.75 27.1502 22.4024 27.4167 22.1583 27.4167H17.0744C16.7675 25.6487 15.0303 24.455 13.1943 24.7506C11.775 24.979 10.6629 26.05 10.4256 27.4167H8.6875C8.37684 27.4167 8.12498 27.1742 8.12498 26.875V12.7917C8.12498 12.1934 8.62865 11.7084 9.24996 11.7084H21.625C22.2463 11.7084 22.75 12.1934 22.75 12.7917V26.9016H22.75ZM28.375 30.125C27.1324 30.125 26.125 29.1549 26.125 27.9583C26.125 26.7617 27.1323 25.7916 28.375 25.7916C29.6176 25.7916 30.625 26.7617 30.625 27.9583C30.625 29.1549 29.6177 30.125 28.375 30.125ZM32.875 22H27.25C27.025 22 26.6875 21.7146 26.6875 21.4583V18.2083C26.6875 17.8941 27.025 17.6666 27.25 17.6666H32.875V22ZM32.875 16.5834H27.25C26.4518 16.5834 25.5625 17.2507 25.5625 18.2084V21.4584C25.5625 22.2936 26.3826 23.0834 27.25 23.0834H32.875V26.8751C32.875 27.1129 32.5595 27.4167 32.3125 27.4167H31.6994C31.3915 25.6489 29.6537 24.456 27.8179 24.7525C26.4 24.9815 25.2889 26.0513 25.0512 27.4167H24.1562C24.0009 27.4167 23.875 27.2955 23.875 27.1459C23.8716 27.1159 23.8654 27.0861 23.8564 27.0571C23.8658 27.0057 23.872 26.9538 23.875 26.9017V14.9584H31.75C32.3713 14.9584 32.875 15.4434 32.875 16.0417V16.5834H32.875Z" fill="#1A62D3"/>
                      </svg>                        
                    Road
                  </div>
                  <div class="col">
                    <svg width="35" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                      <path d="M27.6667 24.7745V7.99994C27.6667 4.68465 25.6354 4.11866 19.9761 4.02128L20.9094 1.68199C22.7 1.9793 23.6 2.39998 23.6667 2.66667C23.6667 3.03485 23.9652 3.33335 24.3333 3.33335C24.7015 3.33335 25 3.03485 25 2.66667C25 0.346685 19.3421 0 15.9668 0C13.2721 0 7.00016 0.259998 7.00016 2.66667C7.00016 3.03485 7.29866 3.33335 7.66684 3.33335C8.03503 3.33335 8.33353 3.03485 8.33353 2.66667C8.40021 2.39998 9.29352 1.97467 11.0868 1.67667L12.0241 4.01934C6.36686 4.11865 4.3335 4.68465 4.3335 7.99994V24.7745C4.3335 26.7745 5.83348 28.7438 7.55278 29.2218L5.28611 30.7844C4.98312 30.9938 4.90712 31.4091 5.11643 31.7121C5.32574 32.0151 5.74105 32.0911 6.04411 31.8818L9.74008 29.3331H22.26L25.9546 31.8818C26.2576 32.0911 26.673 32.0152 26.8823 31.7121C27.0916 31.4091 27.0157 30.9938 26.7126 30.7844L24.386 29.1798C26.7727 28.6305 27.6667 26.6505 27.6667 24.7745ZM15.9668 1.3333C17.1618 1.32861 18.3562 1.38493 19.5454 1.50199L18.5454 4.00528C17.7548 3.99997 16.9161 3.99997 16.0001 3.99997C15.0841 3.99997 14.2454 3.99997 13.4541 4.00528L12.4514 1.49861C13.6197 1.38405 14.7929 1.32886 15.9668 1.3333ZM26.3333 24.7745C26.3333 25.9792 25.9 27.9998 23 27.9998H8.33347C7.16947 27.9998 5.6668 26.4358 5.6668 24.7745V7.99994C5.6668 5.58264 6.6321 5.33327 16 5.33327C25.368 5.33327 26.3333 5.58264 26.3333 7.99994V24.7745Z" fill="#CCCCCC"/>
                      <path d="M9.17891 19.3332H22.7668C23.9141 19.3332 24.9981 18.2899 24.9981 17.1859V9.77193C25.0001 8.60862 23.8774 7.99994 22.7668 7.99994H9.17891C7.91624 7.99994 7.00024 8.74525 7.00024 9.77193V17.1859C7.00024 18.2899 8.05892 19.3332 9.17891 19.3332ZM8.33355 9.77193C8.33355 9.45262 8.85154 9.33324 9.17885 9.33324H22.7667C23.1427 9.33324 23.6647 9.48593 23.6647 9.77193L23.6667 17.1859C23.6667 17.5432 23.1667 17.9999 22.7667 17.9999H9.17891C8.79223 17.9999 8.33361 17.5586 8.33361 17.1859V9.77193H8.33355Z" fill="#CCCCCC"/>
                      <path d="M22.3334 21.3331C20.8607 21.3331 19.6667 22.527 19.6667 23.9998C19.6667 25.4725 20.8607 26.6665 22.3334 26.6665C23.8062 26.6665 25.0001 25.4726 25.0001 23.9998C25.0001 22.5271 23.8062 21.3331 22.3334 21.3331ZM22.3334 25.3331C21.597 25.3331 21.0001 24.7362 21.0001 23.9998C21.0001 23.2634 21.597 22.6665 22.3334 22.6665C23.0698 22.6665 23.6667 23.2634 23.6667 23.9998C23.6668 24.7362 23.0698 25.3331 22.3334 25.3331Z" fill="#CCCCCC"/>
                      <path d="M9.66691 21.3331C8.19417 21.3331 7.00024 22.527 7.00024 23.9998C7.00024 25.4725 8.19417 26.6665 9.66691 26.6665C11.1396 26.6665 12.3336 25.4726 12.3336 23.9998C12.3335 22.5271 11.1396 21.3331 9.66691 21.3331ZM9.66691 25.3331C8.93054 25.3331 8.33361 24.7362 8.33361 23.9998C8.33361 23.2634 8.93054 22.6665 9.66691 22.6665C10.4033 22.6665 11.0002 23.2634 11.0002 23.9998C11.0002 24.7362 10.4033 25.3331 9.66691 25.3331Z" fill="#CCCCCC"/>
                      </g>
                      <defs>
                      <clipPath id="clip0">
                      <rect width="32" height="32" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>                        
                    Rail
                  </div>
                  <div class="col">
                    <svg width="35" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                      <path d="M31.7553 21.9446C31.3413 20.9446 30.418 20.1106 29.1554 19.5933L22.7335 16.9654L18.2835 6.35021C18.2153 6.18722 18.085 6.05797 17.9215 5.99091L12.359 3.71556C12.018 3.57657 11.629 3.74031 11.49 4.08125C11.4234 4.24456 11.4242 4.42755 11.4923 4.59024L15.4316 13.9801L8.83164 11.2815L6.98435 6.87683C6.9161 6.71383 6.78586 6.58459 6.62236 6.51752L1.61843 4.47024C1.27774 4.33062 0.888375 4.49368 0.748752 4.83436C0.714377 4.91823 0.697503 5.0083 0.699065 5.09892L0.906375 15.4788C0.911562 15.7446 1.07425 15.982 1.32037 16.0828L11.4915 20.242L0.415131 24.7753C0.0741985 24.9143 -0.0894867 25.3033 0.0495114 25.6443C0.11726 25.8104 0.249071 25.9422 0.415131 26.0099L5.97637 28.2845C6.05662 28.3172 6.14243 28.3339 6.22905 28.3338C6.29693 28.3339 6.36436 28.3236 6.42905 28.3032L20.4542 23.9099L26.3488 26.3206C28.7127 27.2886 30.864 26.5872 31.7054 24.5813C32.08 23.6833 32.098 22.772 31.7553 21.9446ZM13.3443 5.55885L17.1615 7.12014L20.9922 16.2533L17.1762 14.6927L13.3443 5.55885ZM30.4773 24.064C29.8167 25.6399 28.1714 25.6253 26.8547 25.0853L20.7335 22.5826C20.5896 22.5239 20.4297 22.5173 20.2815 22.5639L6.25836 26.9572L2.42842 25.3905L13.505 20.8573C13.8459 20.7183 14.0096 20.3292 13.8706 19.9883C13.8028 19.8222 13.671 19.6903 13.505 19.6227L2.23042 15.0154L2.05242 6.08822L5.86306 7.64751L7.71034 12.0521C7.77859 12.2151 7.90884 12.3444 8.07234 12.4114L28.6507 20.8273C29.584 21.208 30.2467 21.786 30.5234 22.4546C30.7294 22.9533 30.7134 23.4946 30.4747 24.0639H30.4773V24.064Z" fill="#CCCCCC"/>
                      </g>
                      <defs>
                      <clipPath id="clip0">
                      <rect width="32" height="32" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>                        
                    Air
                  </div>
                  <div class="col">
                    <svg width="35" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                      <path d="M25.6 5.33333H19.7333V4.26667H24C24.5891 4.26667 25.0667 3.7891 25.0667 3.2V1.06667C25.0667 0.477563 24.5891 0 24 0H19.7333C19.1442 0 18.6667 0.477563 18.6667 1.06667V5.33333H10.6667C7.53874 5.31806 4.86414 7.57969 4.35947 10.6667H1.6C0.716344 10.6667 0 11.383 0 12.2667V19.7333C0 20.617 0.716344 21.3333 1.6 21.3333H16V30.9333C16.035 31.554 16.5641 32.0303 17.1851 32H21.7483C22.3692 32.0303 22.8983 31.554 22.9333 30.9333V21.3333H30.9333C31.5224 21.3333 32 20.8558 32 20.2667V11.7333C31.9962 8.2003 29.133 5.33715 25.6 5.33333ZM19.7333 1.06667H24V3.2H19.7333V1.06667ZM10.6667 6.4C13.6108 6.40323 15.9968 8.78916 16 11.7333V20.2667H14.8352C14.8984 20.0959 14.9316 19.9154 14.9333 19.7333V12.2667C14.9333 11.383 14.217 10.6667 13.3333 10.6667H5.44C5.93479 8.17578 8.12715 6.38609 10.6667 6.4ZM5.18453 16.1659L1.06667 19.6512V12.6811L5.18453 16.1659ZM6.01013 16.8645L6.43307 17.2219C7.03051 17.7233 7.90176 17.7233 8.4992 17.2219L8.92587 16.8635L12.9435 20.2667H1.98987L6.01013 16.8645ZM13.8667 12.6811V19.6512L9.7488 16.1659L13.8667 12.6811ZM13.3333 11.7333L7.80907 16.4101C7.61076 16.5766 7.32151 16.5766 7.1232 16.4101L1.6 11.7333H13.3333ZM21.8779 30.8917C21.8416 30.9217 21.7952 30.9365 21.7483 30.9333H17.1851C17.1492 30.9309 17.1136 30.9259 17.0784 30.9184C17.072 30.9184 17.0683 30.9184 17.0672 30.9253V21.3333H18.6672V29.3333C18.6672 29.6279 18.906 29.8667 19.2005 29.8667C19.4951 29.8667 19.7339 29.6279 19.7339 29.3333V21.3333H21.8672L21.8779 30.8917ZM30.9333 20.2667H17.0667V11.7333C17.065 9.58677 15.9873 7.58401 14.1968 6.4H25.6C28.5442 6.40323 30.9301 8.78916 30.9333 11.7333V20.2667Z" fill="#CCCCCC"/>
                      <path d="M24.5332 14.9333C23.9441 14.9333 23.4666 15.4109 23.4666 16V18.1333C23.4666 18.7224 23.9441 19.2 24.5332 19.2H28.7999C29.389 19.2 29.8665 18.7224 29.8665 18.1333V16C29.8665 15.4109 29.389 14.9333 28.7999 14.9333H24.5332ZM24.5332 18.1333V16H28.7999V18.1333H24.5332Z" fill="#CCCCCC"/>
                      <path d="M5.86683 13.8667H9.06684C9.36139 13.8667 9.60017 13.6279 9.60017 13.3334C9.60017 13.0388 9.36139 12.8 9.06684 12.8H5.86683C5.57228 12.8 5.3335 13.0388 5.3335 13.3334C5.3335 13.6279 5.57228 13.8667 5.86683 13.8667Z" fill="#CCCCCC"/>
                      </g>
                      <defs>
                      <clipPath id="clip0">
                      <rect width="32" height="32" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>                        
                    Mail
                  </div>
                  <div class="col">
                    <svg width="35" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                      <path d="M15.9893 32C15.884 32 15.7773 31.9747 15.6813 31.924L0.358667 23.924C0.138667 23.8093 0 23.5813 0 23.3333V8.33335C0 8.10268 0.12 7.88801 0.316 7.76668C0.512 7.64535 0.758667 7.63468 0.964 7.73735L16.2867 15.404C16.512 15.5173 16.6547 15.748 16.6547 16V31.3333C16.6547 31.5667 16.5333 31.7827 16.3333 31.904C16.2293 31.968 16.1093 32 15.9893 32ZM1.33333 22.9293L15.3227 30.2333V16.412L1.33333 9.41201V22.9293Z" fill="#CCCCCC"/>
                      <path d="M16.0109 32C15.8909 32 15.7709 31.968 15.6656 31.904C15.4669 31.7827 15.3442 31.5667 15.3442 31.3333V16C15.3442 15.748 15.4869 15.5173 15.7122 15.404L31.0349 7.73735C31.2389 7.63335 31.4869 7.64401 31.6829 7.76668C31.8802 7.88801 32.0002 8.10268 32.0002 8.33335V23.3333C32.0002 23.5813 31.8616 23.8093 31.6416 23.924L16.3189 31.924C16.2229 31.9747 16.1162 32 16.0109 32ZM16.6776 16.412V30.2333L30.6669 22.9293V9.41201L16.6776 16.412ZM31.3336 23.3333H31.3469H31.3336Z" fill="#CCCCCC"/>
                      <path d="M0.666588 9C0.421254 9 0.186588 8.86534 0.0692542 8.632C-0.0960791 8.30267 0.0385876 7.90267 0.367921 7.73734L15.7119 0.0706714C15.8999 -0.0226619 16.1213 -0.0226619 16.3079 0.0706714L31.6306 7.73734C31.9599 7.90267 32.0933 8.30267 31.9293 8.632C31.7639 8.96134 31.3653 9.09334 31.0346 8.93067L16.0106 1.412L0.965254 8.92934C0.869254 8.97734 0.767921 9 0.666588 9Z" fill="#CCCCCC"/>
                      <path d="M23.9999 20C23.6319 20 23.3332 19.7013 23.3332 19.3333V12.412L8.37858 4.92933C8.04925 4.76533 7.91591 4.36399 8.08125 4.03466C8.24658 3.70533 8.64658 3.57333 8.97591 3.73599L24.2986 11.4027C24.5239 11.5173 24.6666 11.748 24.6666 12V19.3333C24.6666 19.7013 24.3679 20 23.9999 20Z" fill="#CCCCCC"/>
                      </g>
                      <defs>
                      <clipPath id="clip0">
                      <rect width="32" height="32" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>                        
                    Courier
                  </div>
                </div>
                <label>Coverage Start Date</label><br>
                <input [min]="currentDate" [(ngModel)]="coverageStartDate" formControlName="coverageStartDate" type="date" /><br>

                <label>Transit From</label><br>
                <input [(ngModel)]="transitFrom" formControlName="transitFrom" class="input"/><br>

                <label>LR Date</label><br>
                <input [(ngModel)]="lrDate" formControlName="lrDate" type="date" />
              </div>

              <div class="col-xs-12 col-md-4">
                <label>Coverage of war or strikes risk</label><br>
                <input readonly=true [(ngModel)]="coverageOfWar" formControlName="coverageOfWar" /><br>

                <label>Transit To</label><br>
                <input [(ngModel)]="transitTo" formControlName="transitTo" /><br>

                <label>Transporter Name</label><br>
                <input [(ngModel)]="transporterName" formControlName="transporterName" />
              </div>

            </div>
            <div style="text-align: center;margin-top: 30px;">
              <button type="button" class="btn btn-primary" (click)="onTransitSubmit()"><span *ngIf="!transitLoading">Proceed</span>
                <div style="height: 25px;width: 25px;" *ngIf="transitLoading" class="spinner-border text-light" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </form>
          </ng-template>
        </ngb-panel>
        <ngb-panel *ngIf="!container" id="static-5">
          <!-- <ng-template ngbPanelTitle>
            <h5>Invoice Details</h5>
          </ng-template> -->
          <ng-template ngbPanelHeader let-opened="opened">
            <div style="cursor: pointer;" (click)="showStep(5)" class="d-flex align-items-center justify-content-between">
              <h5 style="padding-top: 5px;padding-bottom: 5px;color: #1A62D3;" class="m-0">Invoice Details</h5>
              <div>Step 05/05 <img  src="assets/icon/ticksquare.svg" /></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <form [formGroup]="invoiceDetails">
            <div class="row">
              <div class="col-xs-12 col-md-4">
                <label style="margin-right: 10px;">Invoice Photo</label><br>
                <div *ngIf="invoiceUrl">
                  <img  [src]="invoiceUrl" style="height: 70px;width: 50px;"/><br>
                </div>
                <div *ngIf="!invoiceUrl">
                  <label style="margin-bottom: 23px;" for="invoice">
                    <svg width="50" height="50" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="35" cy="35" r="35" fill="#1E68D6"/>
                      <path d="M35.0001 39.2667C37.3565 39.2667 39.2667 37.3564 39.2667 35C39.2667 32.6436 37.3565 30.7333 35.0001 30.7333C32.6436 30.7333 30.7334 32.6436 30.7334 35C30.7334 37.3564 32.6436 39.2667 35.0001 39.2667Z" fill="white"/>
                      <path d="M30.9998 21.6667L28.5598 24.3333H24.3332C22.8665 24.3333 21.6665 25.5333 21.6665 27V43C21.6665 44.4667 22.8665 45.6667 24.3332 45.6667H45.6665C47.1332 45.6667 48.3332 44.4667 48.3332 43V27C48.3332 25.5333 47.1332 24.3333 45.6665 24.3333H41.4398L38.9998 21.6667H30.9998ZM34.9998 41.6667C31.3198 41.6667 28.3332 38.68 28.3332 35C28.3332 31.32 31.3198 28.3333 34.9998 28.3333C38.6798 28.3333 41.6665 31.32 41.6665 35C41.6665 38.68 38.6798 41.6667 34.9998 41.6667Z" fill="white"/>
                      </svg>                        
                  </label>
                  <input type="file" (change)="onInvoiceUpload($event)" id="invoice" style="display: none;"/>
                </div>

                <label style="margin-top: 12px;">Invoice Currency</label><br>
                <input readonly=true [(ngModel)]="invoiceCurrency" formControlName="invoiceCurrency" value="INR" /><br>

                <!-- <label>Rate of Exchange</label><br>
                <input [(ngModel)]="rateOfExchange" formControlName="rateOfExchange"/><br> -->

                <label>Additional Duty</label><br>
                <input type="number" (input)="calcSumInsured()" [(ngModel)]="duty" formControlName="duty"/>

                <label>Excess Percentage</label><br>
                  <input  readonly=true [(ngModel)]="excessPercentage" formControlName="excessPercentage"/>
              </div>

              <div class="col-xs-12 col-md-4">
                <label>Invoice Number</label><br>
                <input type="text" [(ngModel)]="invoiceNumber" formControlName="invoiceNumber" placeholder="Eg: 1212121" /><br>

                <label>% Extra</label><br>
                <input type="number" min="0" max="10" (input)="onExtraChange($event)" (input)="calcSumInsured()" [(ngModel)]="extra" formControlName="extra" type="number"/><br>
                <div style="text-align: left;margin-left: auto;margin-right: auto;">
                  <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="extratrue">Value can not exceed 10.</div>
                </div>

                <label>Basis of Valuation</label><br>
                <input [(ngModel)]="basisOfValuation" readonly=true formControlName="basisOfValuation" value="Invoice" /><br>

                <label>Insurance Charges</label><br>
                <input type="number" (input)="calcSumInsured()" [(ngModel)]="insuranceCharges"  formControlName="insuranceCharges" />
              </div>

              <div class="col-xs-12 col-md-4">
                <label>Invoice Date</label><br>
                <input [(ngModel)]="invoiceDate" formControlName="invoiceDate" type="date"/><br>

                <label>Invoice Amount</label><br>
                <input type="number" (input)="calcSumInsured()" [(ngModel)]="invoiceAmount" formControlName="invoiceAmount" /><br>

                <label>Freight</label><br>
                <input type="number" (input)="calcSumInsured()" [(ngModel)]="freight" formControlName="freight" />

                <label>Excess Amount</label><br>
                  <input readonly=true [(ngModel)]="excessAmount" formControlName="excessAmount"/>

                  

                <!-- <label>Invoice GST</label><br>
                <input type="text"  [(ngModel)]="invoiceGst" formControlName="invoiceGst" /> -->
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-md-6">
                <label>Agent</label><br>
                <input [(ngModel)]="agent" formControlName="agent" value="Bajaj Allianz General Insurance Company"  style="width: 93.5%;" /> 
              </div>
              <div class="col-xs-12 col-md-6">
                <label>Sum Insured</label><br>
                <input readonly=true type="number" [(ngModel)]="sumInsured" formControlName="sumInsured" style="width: 93.5%;" /> 
              </div>
            </div>
            <div style="text-align: center;margin-top: 30px;">
              <button type="button" class="btn btn-primary" (click)="onInvoiceSubmit()"><span *ngIf="!invoiceLoading">Save & Preview</span>
                <div style="height: 25px;width: 25px;" *ngIf="invoiceLoading" class="spinner-border text-light" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </form>
          </ng-template>
        </ngb-panel>

        <!-- -----------------------------CONTAINER----------------------------- -->
        <ngb-panel *ngIf="container" id="static-2">
          <!-- <ng-template ngbPanelTitle>
            <h5>Risk Details</h5>
          </ng-template> -->
          <ng-template ngbPanelHeader let-opened="opened">
            <div style="cursor: pointer;"  (click)="showStep(2)" class="d-flex align-items-center justify-content-between">
              <h5 style="padding-top: 5px;padding-bottom: 5px;color: #1A62D3;" class="m-0">Customer Details</h5>
              <div>Step 02/05 <img  src="assets/icon/ticksquare.svg" /></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <form [formGroup]="containerCustomerDetails">
              <div class="row">
                <div class="col-xs-12 col-md-4">
                  <label for="">Company Name</label><br>
                  <input pattern="^[a-zA-Z ]*$" [(ngModel)]="containerDetails.companyName" formControlName="companyName" class="input"/>
                  <div style="text-align: left;width: 343px; margin-left: auto;margin-right: auto;">
                   <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="containerCustomerDetails.controls.companyName.errors && containerCustomerDetails.controls.companyName.dirty">Enter a valid Company Name.</div>
                  </div>

                  <label for="">Email Address</label><br>
                  <input pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" [(ngModel)]="containerDetails.emailAddress" formControlName="emailAddress" placeholder="sanjose@gmail.com" class="input"/><br>
                  <div style="text-align: left;width: 343px; margin-left: auto;margin-right: auto;">
                   <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="containerCustomerDetails.controls.emailAddress.errors && containerCustomerDetails.controls.emailAddress.dirty">Enter a valid Email address.</div>
                  </div>

                  
                </div>
                <div class="col-xs-12 col-md-4">
                  <label for="">Building Address</label><br>
                  <input [(ngModel)]="containerDetails.buildingAddress" formControlName="buildingAddress"  class="input"/>

                  <label>Contact Person Name</label><br>
                  <input [(ngModel)]="containerDetails.contactPersonName" pattern="^[a-zA-Z ]*$" formControlName="contactPersonName" />
                  <div style="text-align: left;width: 343px; margin-left: auto;margin-right: auto;">
                    <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="containerCustomerDetails.controls.contactPersonName.errors && containerCustomerDetails.controls.contactPersonName.dirty">Enter a valid Name.</div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4">
                  <label for="">GSTIN/UIN</label><br>
                  <input pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$" [(ngModel)]="containerDetails.gstinUin" formControlName="gstinUin" placeholder="07AAACC4538L1ZK" class="input"/><br>
                  <div style="text-align: left;margin-left: auto;margin-right: auto;">
                    <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="containerCustomerDetails.controls.gstinUin.errors && containerCustomerDetails.controls.gstinUin.dirty">Enter a valid GSTIN.</div>
                  </div>

                  <label for="">Mobile Number</label><br>
                  <input pattern="[0-9]{10}" [(ngModel)]="containerDetails.mobileNumber" formControlName="mobileNumber" placeholder="10 digit number starting with +91" type="number" class="input"/><br>
                  <div style="text-align: left;margin-left: auto;margin-right: auto;">
                    <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="containerCustomerDetails.controls.mobileNumber.errors && containerCustomerDetails.controls.mobileNumber.dirty">Enter a valid Mobile number.</div>
                  </div> 
<!-- 
                  <label for="">PAN</label><br>
                  <input pattern="[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}" [(ngModel)]="containerDetails.pan" formControlName="pan" placeholder="EAWKPG101012" class="input"/>
                  <div style="text-align: left;margin-left: auto;margin-right: auto;">
                    <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="containerCustomerDetails.controls.pan.errors && containerCustomerDetails.controls.pan.dirty">Enter a valid PAN no.</div>
                  </div> -->
                </div>
              </div>
              <div style="text-align: center;margin-top: 30px;">
                <button type="button" class="btn btn-primary" (click)="onContainerCustomerSubmit()"><span *ngIf="!userLoading">Proceed</span>
                  <div style="height: 25px;width: 25px;" *ngIf="userLoading" class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </form>
          </ng-template>
        </ngb-panel>
        <ngb-panel *ngIf="container" id="static-3">
          <!-- <ng-template ngbPanelTitle>
            <h5>Risk Details</h5>
          </ng-template> -->
          <ng-template ngbPanelHeader let-opened="opened">
            <div style="cursor: pointer;" (click)="showStep(3)" class="d-flex align-items-center justify-content-between">
              <h5 style="padding-top: 5px;padding-bottom: 5px;color: #1A62D3;" class="m-0">Risk Details</h5>
              <div>Step 03/05 <img  src="assets/icon/ticksquare.svg" /></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <form [formGroup]="containerRiskDetails">
              <label for="rc">Risk Category</label>
                    <br>
                    <select (change)="onContainerRiskCategory($event)" [(ngModel)]="riskCategory" formControlName="riskCategory"  style="padding: 5px;border-radius: 5px;border: 1px solid rgb(184, 184, 184);width: 50%;margin-bottom: 20px;" id="rc">
                      <ng-container>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="COM10">All types of Non-Refrigerated Container</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="COM24">ISO Tanks</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="COM11">Refrigerated Container</option>
                      </ng-container>
                    </select>
                    <div class="table-responsive">
                      <table style="border: none;" class="table">
                        <thead class="thead" style="background: #1F6BDB;color: white;border: none;">
                          <tr style="border: none;">
                            <th style="border: none;"  scope="col">Sr.No</th>
                            <th style="border: none;" scope="col">Container No</th>
                            <th style="border: none;" scope="col">Container Size</th>
                            <th style="border: none;" scope="col">Value of Container(in INR)</th>
                            <th style="border: none;" scope="col"></th>
                          </tr>
                        </thead>
                        <tbody style="border: none;">
                          <tr *ngFor="let res of getContainerArr(); let i=index" style="border: none;">
                            <th style="border: none;" scope="row">{{ i+1 }}</th>
                            <td style="border: none;" formArrayName="containerNo">
                              <input pattern="^[a-zA-Z]{3}[uUjJzZ]{1}[0-9]{7}$" placeholder="HJCU1234567" formControlName="{{ i }}" />
                              <div style="text-align: left;margin-left: auto;margin-right: auto;">
                                <div style="color: red;font-size: 12px;margin-top: -12px;margin-bottom: 10px;" *ngIf="containerNo?.controls[i]?.errors && containerNo?.controls[i]?.dirty">Enter a valid Container no.</div>
                              </div>
                            </td>
                            <td style="border: none;" formArrayName="containerSize">
                              <select  formControlName="{{ i }}" style="padding: 7px;border-radius: 5px;border: 1px solid rgb(184, 184, 184);margin-bottom: 20px;width: 90%;">
                                <option *ngFor="let value of containerSizeArray" [value]="value">{{ value }}</option>
                              </select>
                            </td>
                            <td style="border: none;" formArrayName="containerValue">
                              <input type="number" formControlName="{{ i }}"/>
                            </td>
                            <td style="border: none;width: 10%;padding-top: 15px;">
                              <a (click)="deleterow(i)"><img style="width: 35%;" src="assets/icon/trash.svg" /></a>
                            </td>
                          </tr>
                        </tbody>
                        <div style="text-align: center;">
                          <button type="button" class="btn btn-primary" (click)="addNewRow()">Add</button>
                        </div>
                      </table>
                    </div>
                   

                    <div style="text-align: center;margin-top: 30px;">
                      <button type="button" class="btn btn-primary" (click)="onContainerRiskSubmit()"><span *ngIf="!transitLoading">Proceed</span>
                        <div style="height: 25px;width: 25px;" *ngIf="transitLoading" class="spinner-border text-light" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
            </form>
          </ng-template>
        </ngb-panel>
        <ngb-panel *ngIf="container" id="static-4">
          <!-- <ng-template ngbPanelTitle>
            <h5>Risk Details</h5>
          </ng-template> -->
          <ng-template ngbPanelHeader let-opened="opened">
            <div style="cursor: pointer;" (click)="showStep(4)" class="d-flex align-items-center justify-content-between">
              <h5 style="padding-top: 5px;padding-bottom: 5px;color: #1A62D3;" class="m-0">Transit Details</h5>
              <div>Step 04/05 <img  src="assets/icon/ticksquare.svg" /></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <form [formGroup]="containerTransitDetails">
              <div class="row">
                <div class="col-xs-12 col-md-4">
                  <div>
                    <label>Type of Transit</label><br>
                    <input readonly=true [(ngModel)]="typeOfTransit" formControlName="typeOfTransit" value="Inland" class="input"/>
                  </div>
                  <div>
                    <label>Coverage</label><br>
                    <select [(ngModel)]="coverage" formControlName="coverage" style="padding: 7px;border-radius: 5px;border: 1px solid rgb(184, 184, 184);margin-bottom: 20px;width: 90%;">
                      <option  selected>--Select coverage--</option>
                      <option value="BASIC_RISK">Basic Risk</option>
                    </select>
                  </div>
                  <div>
                    <label>Country</label><br>
                    <input readonly=true [(ngModel)]="country" value="India" formControlName="country" class="input"/><br>
                  </div>
                  <div>
                    <label for="">Shipping Line</label><br>
                    <select [(ngModel)]="shippingLine" formControlName="shippingLine" style="padding: 5px;border-radius: 5px;border: 1px solid rgb(184, 184, 184);width: 90%;margin-bottom: 20px;" id="rc">
                      <ng-container>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Korea Marine Transport Co., Ltd. ">Korea Marine Transport Co., Ltd. </option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="The Shipping Corporation of India Ltd">The Shipping Corporation of India Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="CMA CGM Agencies India Pvt Ltd">CMA CGM Agencies India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Cosco Shipping Lines India Pvt Ltd">Cosco Shipping Lines India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Evergreen Shipping Agency India Pvt Ltd">Evergreen Shipping Agency India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Hapag lloyd India Pvt Ltd">Hapag lloyd India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="K Line India Pvt Ltd">K Line India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Maersk Line India Pvt Ltd">Maersk Line India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="MOL Maritime India Pvt Ltd">MOL Maritime India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="MSC Agency India Pvt Ltd">MSC Agency India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="NYK Ship Management Pte Ltd">NYK Ship Management Pte Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="NYK LINE (INDIA) PRIVATE LIMITED">NYK LINE (INDIA) PRIVATE LIMITED</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="ONE (Ocean Network Express) Line India Pvt Ltd">ONE (Ocean Network Express) Line India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="OOCL India Pvt Ltd">OOCL India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="ZIM Integrated Shipping Services India Pvt Ltd">ZIM Integrated Shipping Services India Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="ARMITA INDIA SHIPPING PRIVATE LIMITED">ARMITA INDIA SHIPPING PRIVATE LIMITED</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="INDUS CONTAINER LINES PVT. LTD.">INDUS CONTAINER LINES PVT. LTD.</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Parekh Marine Services Pvt Ltd">Parekh Marine Services Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="SEAHORSE SHIP AGENCIES PVT.LTD">SEAHORSE SHIP AGENCIES PVT.LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="GIL Shipping Services Private Limited">GIL Shipping Services Private Limited</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Hamburg Sud India Pvt. Ltd.">Hamburg Sud India Pvt. Ltd.</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="CASTO SHIPPING LINE LLP">CASTO SHIPPING LINE LLP</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="MSA SHIPPING PVT LTD.">MSA SHIPPING PVT LTD.</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="SARJAK CONTAINER LINES PVT. LTD">SARJAK CONTAINER LINES PVT. LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Regional Container Lines">Regional Container Lines</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Pacific International Lines ( PIL )">Pacific International Lines ( PIL )</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Oman Container Lines">Oman Container Lines</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="HUB & LINKS LOGISTICS (I) PVT LTD">HUB & LINKS LOGISTICS (I) PVT LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="STAR SHIPPING SERVICES (I) PVT.LTD">STAR SHIPPING SERVICES (I) PVT.LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="FREIGHT CONNECTION INDIA PVT. LTD">FREIGHT CONNECTION INDIA PVT. LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="ACCORD SHIPPING AGENCIES (I) PVT LTD">ACCORD SHIPPING AGENCIES (I) PVT LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="TRANS ASIAN SHIPPING SERVICES PVT LTD">TRANS ASIAN SHIPPING SERVICES PVT LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="WAN HAI LINES (INDIA) PVT. LTD">WAN HAI LINES (INDIA) PVT. LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="GOODRICH MARITIME PRIVATE LIMITED">GOODRICH MARITIME PRIVATE LIMITED</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="WINWIN MARITIME LTD">WINWIN MARITIME LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="EXPRESSWAY CARGO MOVERS PVT LTD">EXPRESSWAY CARGO MOVERS PVT LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="MONTER SHIPPING & LOGISTICS (INDIA) PRIVATE LIMITED">MONTER SHIPPING & LOGISTICS (INDIA) PRIVATE LIMITED</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Hyundai Merchant Marine India Private Limited">Hyundai Merchant Marine India Private Limited</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="MAXICON CONTAINER LINE">MAXICON CONTAINER LINE</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Cosco Shipping Lines (India) Pvt. Ltd.">Cosco Shipping Lines (India) Pvt. Ltd.</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="J M Baxi And Co">J M Baxi And Co</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Navio Shipping Pvt Ltd">Navio Shipping Pvt Ltd</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="ANL INDIA">ANL INDIA</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="PAN ASIA LINE">PAN ASIA LINE</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="NEPTUNE CONTAINER LINE & LOGISTICS PVT. LTD.">NEPTUNE CONTAINER LINE & LOGISTICS PVT. LTD.</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="GOOD VOYAGE SHIPPING SERVICES PVT LTD.">GOOD VOYAGE SHIPPING SERVICES PVT LTD.</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="DEMCO CONTAINER LINE PVT. LTD">DEMCO CONTAINER LINE PVT. LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="TW Ship Management">TW Ship Management</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="Transworld Group">Transworld Group</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="INTERASIA SHIPPING LINES INDIA PVT LTD">INTERASIA SHIPPING LINES INDIA PVT LTD</option>
                        <option  style="color: black;width: 100;overflow-x: scroll;" value="EVERGREEN LINE">EVERGREEN LINE</option>
                      </ng-container>
                    </select>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4">
                  <label>Mode of Transit</label>
                  <div class="row" style="margin-bottom: 28px;text-align: center;">
                    <div class="col">
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" fill="#75ABD6" fill-opacity="0.34"/>
                        <path d="M31.75 13.875H23.875V12.7917C23.875 11.5951 22.8677 10.625 21.625 10.625H9.25002C8.00738 10.625 7 11.5951 7 12.7917V26.875C7 27.7725 7.75553 28.5 8.6875 28.5H10.4256C10.7334 30.2678 12.4712 31.4606 14.3071 31.1642C15.725 30.9353 16.8361 29.8654 17.0738 28.5H22.1577C22.5378 28.4929 22.9046 28.3639 23.1999 28.1333C23.4586 28.3683 23.8006 28.4994 24.1562 28.5H25.0506C25.3584 30.2678 27.0962 31.4607 28.932 31.1642C30.3499 30.9353 31.461 29.8654 31.6987 28.5H32.3125C33.1799 28.5 34 27.7103 34 26.875V16.0417C34 14.8451 32.9927 13.875 31.75 13.875ZM13.75 30.125C12.5074 30.125 11.5 29.1549 11.5 27.9583C11.5 26.7617 12.5074 25.7916 13.75 25.7916C14.9926 25.7916 16 26.7617 16 27.9583C16 29.1549 14.9926 30.125 13.75 30.125ZM22.75 26.9016C22.75 27.1502 22.4024 27.4167 22.1583 27.4167H17.0744C16.7675 25.6487 15.0303 24.455 13.1943 24.7506C11.775 24.979 10.6629 26.05 10.4256 27.4167H8.6875C8.37684 27.4167 8.12498 27.1742 8.12498 26.875V12.7917C8.12498 12.1934 8.62865 11.7084 9.24996 11.7084H21.625C22.2463 11.7084 22.75 12.1934 22.75 12.7917V26.9016H22.75ZM28.375 30.125C27.1324 30.125 26.125 29.1549 26.125 27.9583C26.125 26.7617 27.1323 25.7916 28.375 25.7916C29.6176 25.7916 30.625 26.7617 30.625 27.9583C30.625 29.1549 29.6177 30.125 28.375 30.125ZM32.875 22H27.25C27.025 22 26.6875 21.7146 26.6875 21.4583V18.2083C26.6875 17.8941 27.025 17.6666 27.25 17.6666H32.875V22ZM32.875 16.5834H27.25C26.4518 16.5834 25.5625 17.2507 25.5625 18.2084V21.4584C25.5625 22.2936 26.3826 23.0834 27.25 23.0834H32.875V26.8751C32.875 27.1129 32.5595 27.4167 32.3125 27.4167H31.6994C31.3915 25.6489 29.6537 24.456 27.8179 24.7525C26.4 24.9815 25.2889 26.0513 25.0512 27.4167H24.1562C24.0009 27.4167 23.875 27.2955 23.875 27.1459C23.8716 27.1159 23.8654 27.0861 23.8564 27.0571C23.8658 27.0057 23.872 26.9538 23.875 26.9017V14.9584H31.75C32.3713 14.9584 32.875 15.4434 32.875 16.0417V16.5834H32.875Z" fill="#1A62D3"/>
                        </svg>                        
                      Road
                    </div>
                    <div class="col">
                      <svg width="35" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path d="M27.6667 24.7745V7.99994C27.6667 4.68465 25.6354 4.11866 19.9761 4.02128L20.9094 1.68199C22.7 1.9793 23.6 2.39998 23.6667 2.66667C23.6667 3.03485 23.9652 3.33335 24.3333 3.33335C24.7015 3.33335 25 3.03485 25 2.66667C25 0.346685 19.3421 0 15.9668 0C13.2721 0 7.00016 0.259998 7.00016 2.66667C7.00016 3.03485 7.29866 3.33335 7.66684 3.33335C8.03503 3.33335 8.33353 3.03485 8.33353 2.66667C8.40021 2.39998 9.29352 1.97467 11.0868 1.67667L12.0241 4.01934C6.36686 4.11865 4.3335 4.68465 4.3335 7.99994V24.7745C4.3335 26.7745 5.83348 28.7438 7.55278 29.2218L5.28611 30.7844C4.98312 30.9938 4.90712 31.4091 5.11643 31.7121C5.32574 32.0151 5.74105 32.0911 6.04411 31.8818L9.74008 29.3331H22.26L25.9546 31.8818C26.2576 32.0911 26.673 32.0152 26.8823 31.7121C27.0916 31.4091 27.0157 30.9938 26.7126 30.7844L24.386 29.1798C26.7727 28.6305 27.6667 26.6505 27.6667 24.7745ZM15.9668 1.3333C17.1618 1.32861 18.3562 1.38493 19.5454 1.50199L18.5454 4.00528C17.7548 3.99997 16.9161 3.99997 16.0001 3.99997C15.0841 3.99997 14.2454 3.99997 13.4541 4.00528L12.4514 1.49861C13.6197 1.38405 14.7929 1.32886 15.9668 1.3333ZM26.3333 24.7745C26.3333 25.9792 25.9 27.9998 23 27.9998H8.33347C7.16947 27.9998 5.6668 26.4358 5.6668 24.7745V7.99994C5.6668 5.58264 6.6321 5.33327 16 5.33327C25.368 5.33327 26.3333 5.58264 26.3333 7.99994V24.7745Z" fill="#CCCCCC"/>
                        <path d="M9.17891 19.3332H22.7668C23.9141 19.3332 24.9981 18.2899 24.9981 17.1859V9.77193C25.0001 8.60862 23.8774 7.99994 22.7668 7.99994H9.17891C7.91624 7.99994 7.00024 8.74525 7.00024 9.77193V17.1859C7.00024 18.2899 8.05892 19.3332 9.17891 19.3332ZM8.33355 9.77193C8.33355 9.45262 8.85154 9.33324 9.17885 9.33324H22.7667C23.1427 9.33324 23.6647 9.48593 23.6647 9.77193L23.6667 17.1859C23.6667 17.5432 23.1667 17.9999 22.7667 17.9999H9.17891C8.79223 17.9999 8.33361 17.5586 8.33361 17.1859V9.77193H8.33355Z" fill="#CCCCCC"/>
                        <path d="M22.3334 21.3331C20.8607 21.3331 19.6667 22.527 19.6667 23.9998C19.6667 25.4725 20.8607 26.6665 22.3334 26.6665C23.8062 26.6665 25.0001 25.4726 25.0001 23.9998C25.0001 22.5271 23.8062 21.3331 22.3334 21.3331ZM22.3334 25.3331C21.597 25.3331 21.0001 24.7362 21.0001 23.9998C21.0001 23.2634 21.597 22.6665 22.3334 22.6665C23.0698 22.6665 23.6667 23.2634 23.6667 23.9998C23.6668 24.7362 23.0698 25.3331 22.3334 25.3331Z" fill="#CCCCCC"/>
                        <path d="M9.66691 21.3331C8.19417 21.3331 7.00024 22.527 7.00024 23.9998C7.00024 25.4725 8.19417 26.6665 9.66691 26.6665C11.1396 26.6665 12.3336 25.4726 12.3336 23.9998C12.3335 22.5271 11.1396 21.3331 9.66691 21.3331ZM9.66691 25.3331C8.93054 25.3331 8.33361 24.7362 8.33361 23.9998C8.33361 23.2634 8.93054 22.6665 9.66691 22.6665C10.4033 22.6665 11.0002 23.2634 11.0002 23.9998C11.0002 24.7362 10.4033 25.3331 9.66691 25.3331Z" fill="#CCCCCC"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="32" height="32" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>                        
                      Rail
                    </div>
                    <div class="col">
                      <svg width="35" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path d="M31.7553 21.9446C31.3413 20.9446 30.418 20.1106 29.1554 19.5933L22.7335 16.9654L18.2835 6.35021C18.2153 6.18722 18.085 6.05797 17.9215 5.99091L12.359 3.71556C12.018 3.57657 11.629 3.74031 11.49 4.08125C11.4234 4.24456 11.4242 4.42755 11.4923 4.59024L15.4316 13.9801L8.83164 11.2815L6.98435 6.87683C6.9161 6.71383 6.78586 6.58459 6.62236 6.51752L1.61843 4.47024C1.27774 4.33062 0.888375 4.49368 0.748752 4.83436C0.714377 4.91823 0.697503 5.0083 0.699065 5.09892L0.906375 15.4788C0.911562 15.7446 1.07425 15.982 1.32037 16.0828L11.4915 20.242L0.415131 24.7753C0.0741985 24.9143 -0.0894867 25.3033 0.0495114 25.6443C0.11726 25.8104 0.249071 25.9422 0.415131 26.0099L5.97637 28.2845C6.05662 28.3172 6.14243 28.3339 6.22905 28.3338C6.29693 28.3339 6.36436 28.3236 6.42905 28.3032L20.4542 23.9099L26.3488 26.3206C28.7127 27.2886 30.864 26.5872 31.7054 24.5813C32.08 23.6833 32.098 22.772 31.7553 21.9446ZM13.3443 5.55885L17.1615 7.12014L20.9922 16.2533L17.1762 14.6927L13.3443 5.55885ZM30.4773 24.064C29.8167 25.6399 28.1714 25.6253 26.8547 25.0853L20.7335 22.5826C20.5896 22.5239 20.4297 22.5173 20.2815 22.5639L6.25836 26.9572L2.42842 25.3905L13.505 20.8573C13.8459 20.7183 14.0096 20.3292 13.8706 19.9883C13.8028 19.8222 13.671 19.6903 13.505 19.6227L2.23042 15.0154L2.05242 6.08822L5.86306 7.64751L7.71034 12.0521C7.77859 12.2151 7.90884 12.3444 8.07234 12.4114L28.6507 20.8273C29.584 21.208 30.2467 21.786 30.5234 22.4546C30.7294 22.9533 30.7134 23.4946 30.4747 24.0639H30.4773V24.064Z" fill="#CCCCCC"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="32" height="32" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>                        
                      Air
                    </div>
                    <div class="col">
                      <svg width="35" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path d="M25.6 5.33333H19.7333V4.26667H24C24.5891 4.26667 25.0667 3.7891 25.0667 3.2V1.06667C25.0667 0.477563 24.5891 0 24 0H19.7333C19.1442 0 18.6667 0.477563 18.6667 1.06667V5.33333H10.6667C7.53874 5.31806 4.86414 7.57969 4.35947 10.6667H1.6C0.716344 10.6667 0 11.383 0 12.2667V19.7333C0 20.617 0.716344 21.3333 1.6 21.3333H16V30.9333C16.035 31.554 16.5641 32.0303 17.1851 32H21.7483C22.3692 32.0303 22.8983 31.554 22.9333 30.9333V21.3333H30.9333C31.5224 21.3333 32 20.8558 32 20.2667V11.7333C31.9962 8.2003 29.133 5.33715 25.6 5.33333ZM19.7333 1.06667H24V3.2H19.7333V1.06667ZM10.6667 6.4C13.6108 6.40323 15.9968 8.78916 16 11.7333V20.2667H14.8352C14.8984 20.0959 14.9316 19.9154 14.9333 19.7333V12.2667C14.9333 11.383 14.217 10.6667 13.3333 10.6667H5.44C5.93479 8.17578 8.12715 6.38609 10.6667 6.4ZM5.18453 16.1659L1.06667 19.6512V12.6811L5.18453 16.1659ZM6.01013 16.8645L6.43307 17.2219C7.03051 17.7233 7.90176 17.7233 8.4992 17.2219L8.92587 16.8635L12.9435 20.2667H1.98987L6.01013 16.8645ZM13.8667 12.6811V19.6512L9.7488 16.1659L13.8667 12.6811ZM13.3333 11.7333L7.80907 16.4101C7.61076 16.5766 7.32151 16.5766 7.1232 16.4101L1.6 11.7333H13.3333ZM21.8779 30.8917C21.8416 30.9217 21.7952 30.9365 21.7483 30.9333H17.1851C17.1492 30.9309 17.1136 30.9259 17.0784 30.9184C17.072 30.9184 17.0683 30.9184 17.0672 30.9253V21.3333H18.6672V29.3333C18.6672 29.6279 18.906 29.8667 19.2005 29.8667C19.4951 29.8667 19.7339 29.6279 19.7339 29.3333V21.3333H21.8672L21.8779 30.8917ZM30.9333 20.2667H17.0667V11.7333C17.065 9.58677 15.9873 7.58401 14.1968 6.4H25.6C28.5442 6.40323 30.9301 8.78916 30.9333 11.7333V20.2667Z" fill="#CCCCCC"/>
                        <path d="M24.5332 14.9333C23.9441 14.9333 23.4666 15.4109 23.4666 16V18.1333C23.4666 18.7224 23.9441 19.2 24.5332 19.2H28.7999C29.389 19.2 29.8665 18.7224 29.8665 18.1333V16C29.8665 15.4109 29.389 14.9333 28.7999 14.9333H24.5332ZM24.5332 18.1333V16H28.7999V18.1333H24.5332Z" fill="#CCCCCC"/>
                        <path d="M5.86683 13.8667H9.06684C9.36139 13.8667 9.60017 13.6279 9.60017 13.3334C9.60017 13.0388 9.36139 12.8 9.06684 12.8H5.86683C5.57228 12.8 5.3335 13.0388 5.3335 13.3334C5.3335 13.6279 5.57228 13.8667 5.86683 13.8667Z" fill="#CCCCCC"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="32" height="32" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>                        
                      Mail
                    </div>
                    <div class="col">
                      <svg width="35" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path d="M15.9893 32C15.884 32 15.7773 31.9747 15.6813 31.924L0.358667 23.924C0.138667 23.8093 0 23.5813 0 23.3333V8.33335C0 8.10268 0.12 7.88801 0.316 7.76668C0.512 7.64535 0.758667 7.63468 0.964 7.73735L16.2867 15.404C16.512 15.5173 16.6547 15.748 16.6547 16V31.3333C16.6547 31.5667 16.5333 31.7827 16.3333 31.904C16.2293 31.968 16.1093 32 15.9893 32ZM1.33333 22.9293L15.3227 30.2333V16.412L1.33333 9.41201V22.9293Z" fill="#CCCCCC"/>
                        <path d="M16.0109 32C15.8909 32 15.7709 31.968 15.6656 31.904C15.4669 31.7827 15.3442 31.5667 15.3442 31.3333V16C15.3442 15.748 15.4869 15.5173 15.7122 15.404L31.0349 7.73735C31.2389 7.63335 31.4869 7.64401 31.6829 7.76668C31.8802 7.88801 32.0002 8.10268 32.0002 8.33335V23.3333C32.0002 23.5813 31.8616 23.8093 31.6416 23.924L16.3189 31.924C16.2229 31.9747 16.1162 32 16.0109 32ZM16.6776 16.412V30.2333L30.6669 22.9293V9.41201L16.6776 16.412ZM31.3336 23.3333H31.3469H31.3336Z" fill="#CCCCCC"/>
                        <path d="M0.666588 9C0.421254 9 0.186588 8.86534 0.0692542 8.632C-0.0960791 8.30267 0.0385876 7.90267 0.367921 7.73734L15.7119 0.0706714C15.8999 -0.0226619 16.1213 -0.0226619 16.3079 0.0706714L31.6306 7.73734C31.9599 7.90267 32.0933 8.30267 31.9293 8.632C31.7639 8.96134 31.3653 9.09334 31.0346 8.93067L16.0106 1.412L0.965254 8.92934C0.869254 8.97734 0.767921 9 0.666588 9Z" fill="#CCCCCC"/>
                        <path d="M23.9999 20C23.6319 20 23.3332 19.7013 23.3332 19.3333V12.412L8.37858 4.92933C8.04925 4.76533 7.91591 4.36399 8.08125 4.03466C8.24658 3.70533 8.64658 3.57333 8.97591 3.73599L24.2986 11.4027C24.5239 11.5173 24.6666 11.748 24.6666 12V19.3333C24.6666 19.7013 24.3679 20 23.9999 20Z" fill="#CCCCCC"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="32" height="32" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>                        
                      Courier
                    </div>
                  </div>
                  <label>Coverage Start Date</label><br>
                  <input [min]="currentDate" [(ngModel)]="coverageStartDate" formControlName="coverageStartDate" type="date" /><br>
                  
                  <label for="">Markup</label><br>
                    <input type="number" [(ngModel)]="markUp" formControlName="markUp"/><br>
                    
                    
                </div>

                <div class="col-xs-12 col-md-4">
                  <label>Transit From</label><br>
                  <input [(ngModel)]="transitFrom" formControlName="transitFrom" class="input"/><br>

                  <label>Transit To</label><br>
                  <input [(ngModel)]="transitTo" formControlName="transitTo" /><br>

                  <label for="">Booking no</label><br>
                  <input [(ngModel)]="bookingNo" formControlName="bookingNo"/><br>

                  <div>
                    <label for="">Container Movement</label><br>
                    <select (change)="onMovementChange($event)" [(ngModel)]="containerMovement" formControlName="containerMovement" style="padding: 5px;border-radius: 5px;border: 1px solid rgb(184, 184, 184);width: 90%;margin-bottom: 20px;">
                      <ng-container>
                        <option   style="color: black;width: 100;overflow-x: scroll;" value="1">Single</option>
                          <option selected style="color: black;width: 100;overflow-x: scroll;" value="2">To/From</option>
                      </ng-container>
                    </select>
                  </div>
                </div>

              </div>
              <div style="text-align: center;margin-top: 30px;">
                <button type="button" class="btn btn-primary" (click)="onContainerTransitSubmit()"><span *ngIf="!transitLoading">Proceed</span>
                  <div style="height: 25px;width: 25px;" *ngIf="transitLoading" class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </form>
          </ng-template>
        </ngb-panel>
        <ngb-panel *ngIf="container" id="static-5">
          <!-- <ng-template ngbPanelTitle>
            <h5>Risk Details</h5>
          </ng-template> -->
          <ng-template ngbPanelHeader let-opened="opened">
            <div style="cursor: pointer;" (click)="showStep(5)" class="d-flex align-items-center justify-content-between">
              <h5 style="padding-top: 5px;padding-bottom: 5px;color: #1A62D3;" class="m-0">DMV Details</h5>
              <div>Step 05/05 <img  src="assets/icon/ticksquare.svg" /></div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <form [formGroup]="containerDmvDetails">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <label>Basis of Valuation</label><br>
                  <input [(ngModel)]="containerBov" readonly=true formControlName="basisOfValuation"/><br>
                  
                  <label>Sum Insured</label><br>
                  <input type="number" readonly=true [(ngModel)]="sumInsured" formControlName="sumInsured"/>
                </div>

                <div class="col-xs-12 col-md-6">
                  <label>Marine Rate</label><br>
                  <input readonly=true [(ngModel)]="marineRate" formControlName="marineRate" placeholder="Eg: 1212121" /><br>
                </div>
              </div>

              <div style="text-align: center;margin-top: 30px;">
                <button type="button" class="btn btn-primary" (click)="onDmvSubmit()"><span *ngIf="!invoiceLoading">Save & Preview</span>
                  <div style="height: 25px;width: 25px;" *ngIf="invoiceLoading" class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </form>
          </ng-template>
        </ngb-panel>  
    </ngb-accordion>
</div>


<!-- MODAL -->

<!-- Cancel modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        Changes not saved,still proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" routerLink="/show-policies" data-dismiss="modal" class="btn btn-primary">Proceed</button>
      </div>
    </div>
  </div>
</div>